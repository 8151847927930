import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'
import { unZipResponse } from '../utils/formatters'

export const isQueryDisabled = (filterParams: DTO.TeamStatsFilterParams): boolean =>
    (filterParams.timeFrame === 'OPPONENT' && !filterParams.opponentTeamId) ||
    (filterParams.timeFrame === 'GAME' && !filterParams.gameIds?.length) ||
    (filterParams.timeFrame === 'DATE_RANGE' && !filterParams.startDate && !filterParams.endDate)

export const getLineupBoxscores = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.LineupBoxscores[]> =>
    get<DTO.LineupBoxscores[]>(`/team-stats/${teamId}/lineups`, filters).then((resp) => resp.data)

export const useGetLineupBoxscores = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<UseQueryOptions<DTO.LineupBoxscores[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.LineupBoxscores[]> =>
    useQuery({
        queryKey: ['team-stats', 'lineups', teamId?.toLowerCase(), filters],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getLineupBoxscores(teamId!, filters),
        ...options,
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

export const getPlayerBoxscores = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.PlayerBoxscores[]> =>
    get<DTO.PlayerBoxscores[]>(`/team-stats/${teamId}/player-boxscores`, filters).then((resp) => resp.data)

export const usePlayerBoxscores = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<UseQueryOptions<DTO.PlayerBoxscores[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.PlayerBoxscores[]> =>
    useQuery({
        queryKey: ['team-stats', 'player-box-scores', teamId?.toLowerCase(), filters],
        queryFn: () => getPlayerBoxscores(teamId!, filters), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

export const getQuarterlySummary = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.QuarterlySummary[]> =>
    get<DTO.QuarterlySummary[]>(`/team-stats/${teamId}/quarterly-summary`, filters).then((resp) => resp.data)

export const useQuarterlySummary = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<UseQueryOptions<DTO.QuarterlySummary[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.QuarterlySummary[]> =>
    useQuery({
        queryKey: ['team-stats', 'quarterly-summary', teamId?.toLowerCase(), filters],
        queryFn: () => getQuarterlySummary(teamId!, filters), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const getPossStartSummary = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams,
    type?: DTO.TeamStatsType
): Promise<DTO.PossStartSummary[]> =>
    get<DTO.PossStartSummary[]>(`/team-stats/${teamId}/poss-start-summary`, { ...filters, type }).then(
        (resp) => resp.data
    )

export const usePossStartSummary = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    type?: DTO.TeamStatsType,
    options?: Omit<UseQueryOptions<DTO.PossStartSummary[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.PossStartSummary[]> =>
    useQuery({
        queryKey: ['team-stats', 'poss-start-summary', teamId?.toLowerCase(), filters, type?.toLowerCase()],
        queryFn: () => getPossStartSummary(teamId!, filters, type), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const getShotClockSummary = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams,
    type?: DTO.TeamStatsType
): Promise<DTO.ShotClockSummary[]> =>
    get<DTO.ShotClockSummary[]>(`/team-stats/${teamId}/shot-clock-summary`, { ...filters, type }).then(
        (resp) => resp.data
    )

export const useShotClockSummary = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    type?: DTO.TeamStatsType,
    options?: Omit<UseQueryOptions<DTO.ShotClockSummary[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.ShotClockSummary[]> =>
    useQuery({
        queryKey: ['team-stats', 'shot-clock-summary', teamId?.toLowerCase(), filters, type?.toLowerCase()],
        queryFn: () => getShotClockSummary(teamId!, filters, type), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const queryBasicStats = async (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.TeamBasicStats> =>
    (await get<DTO.TeamBasicStats>(`/team-stats/${teamId}/basic-stats`, filters)).data

export const useQueryBasicStats = (
    teamId: string | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<UseQueryOptions<DTO.TeamBasicStats>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.TeamBasicStats> =>
    useQuery({
        queryKey: ['team-stats', 'basic-stats', teamId, filters],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => queryBasicStats(teamId!, filters),
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const queryTeamBoxscores = async (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.TeamBoxScore[]> =>
    (await get<DTO.TeamBoxScore[]>(`/team-stats/${teamId}/team-boxscores`, filters)).data

export const useQueryTeamBoxscores = (
    teamId: string | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<UseQueryOptions<DTO.TeamBoxScore[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.TeamBoxScore[]> =>
    useQuery({
        queryKey: ['team-stats', 'team-boxscores', teamId, filters],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => queryTeamBoxscores(teamId!, filters),
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

export const getTeamGames = (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.TeamGames[]> =>
    get<DTO.TeamGames[]>(`/team-stats/${teamId}/team-games`, filters).then((resp) => resp.data)

export const useTeamGames = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<UseQueryOptions<DTO.TeamGames[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.TeamGames[]> =>
    useQuery({
        queryKey: ['team-stats', 'team-games', teamId?.toLowerCase(), filters],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getTeamGames(teamId!, filters),
        ...options,
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const queryDetailStats = async (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.TeamDetailStats[]> =>
    (await get<DTO.TeamDetailStats[]>(`/team-stats/${teamId}/detail-stats`, filters)).data

export const useQueryDetailStats = (
    teamId: string | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<UseQueryOptions<DTO.TeamDetailStats[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.TeamDetailStats[]> =>
    useQuery({
        queryKey: ['team-stats', 'detail-stats', teamId, filters],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => queryDetailStats(teamId!, filters),
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const queryTeamShootingStats = async (
    teamId: string,
    type: DTO.TeamStatsType,
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.TeamShootingStats[]> =>
    (await get<DTO.TeamShootingStats[]>(`/team-stats/${teamId}/shooting-summary/${type}`, filters)).data

export const useQueryTeamShootingStats = (
    teamId: string | undefined,
    type: DTO.TeamStatsType,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<UseQueryOptions<DTO.TeamShootingStats[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.TeamShootingStats[]> =>
    useQuery({
        queryKey: ['team-stats', 'team-shooting-stats', teamId, type, filters],
        queryFn: () => queryTeamShootingStats(teamId!, type, filters), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const queryGameStatus = async (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.GameDataStatusLog> =>
    (await get<DTO.GameDataStatusLog>(`/team-stats/${teamId}/game-status`, filters)).data

export const useQueryGameStatus = (
    teamId: string | null,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<UseQueryOptions<DTO.GameDataStatusLog>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.GameDataStatusLog> => {
    const queryKey = ['team-stats', 'game-status', teamId, filters]
    return useQuery({
        queryKey,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => queryGameStatus(teamId!, filters),
        ...options,
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })
}

const queryTeamShots = async (
    teamId: string | undefined,
    type: DTO.TeamStatsType,
    params: DTO.ShotFilters
): Promise<DTO.GameShot[]> => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const shotString = (await get<string>(`/team-stats/${teamId}/shots/${type}`, params)).data
    const shotObj = unZipResponse<DTO.GameShot[]>(shotString)
    return shotObj
}

export const useTeamShots = (
    teamId: string | undefined,
    type: DTO.TeamStatsType,
    filters: DTO.ShotFilters,
    options?: Omit<UseQueryOptions<DTO.GameShot[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.GameShot[]> =>
    useQuery({
        queryKey: ['team-stats', 'team-shots', teamId, type, filters],
        queryFn: () => queryTeamShots(teamId, type, filters),
        ...options,
        enabled: !!teamId && options?.enabled !== false,
    })

const queryTeamShotAvgs = async (league: Enum.League | undefined, params: DTO.ShotFilters): Promise<DTO.ShotAvgs[]> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.ShotAvgs[]>(`/team-stats/${league}/shot-avgs`, params)).data

export const useTeamShotAvgs = (
    league: Enum.League | undefined,
    filters: DTO.ShotFilters,
    options?: Omit<UseQueryOptions<DTO.ShotAvgs[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.ShotAvgs[]> =>
    useQuery({
        queryKey: ['team-stats', 'team-shot-avgs', league, filters],
        queryFn: () => queryTeamShotAvgs(league, filters),
        ...options,
        enabled: !!league && options?.enabled !== false,
    })

const queryTeamShotAvgsAndRanks = async (
    teamId: string | undefined,
    type: DTO.TeamStatsType,
    params: DTO.ShotFilters
): Promise<DTO.TeamShotRanks> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.TeamShotRanks>(`/team-stats/${teamId}/shot-ranks/${type}`, params)).data

export const useTeamShotAvgsAndRanks = (
    teamId: string | undefined,
    type: DTO.TeamStatsType,
    params: DTO.ShotFilters,
    options?: Omit<UseQueryOptions<DTO.TeamShotRanks>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.TeamShotRanks> =>
    useQuery({
        queryKey: ['team-stats', 'team-shot-ranks', teamId, type, params],
        queryFn: () => queryTeamShotAvgsAndRanks(teamId, type, params),
        ...options,
        enabled: !!teamId && options?.enabled !== false,
    })
