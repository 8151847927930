import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const getPlayerWorkoutTracker = async (playerId: string, season: number): Promise<DTO.PlayerWorkoutTracker[]> =>
    (await get<DTO.PlayerWorkoutTracker[]>(`/player-workout-tracker/${playerId}/${season}`)).data

export const useGetPlayerWorkoutTracker = (
    playerId: string | undefined,
    season: number,
    options?: Omit<
        UseQueryOptions<DTO.PlayerWorkoutTracker[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerWorkoutTracker[]> => {
    const { data: session } = useSession()
    return useQuery({
        queryKey: ['playerWorkoutTracker', playerId, season],
        queryFn: () => getPlayerWorkoutTracker(playerId!, season), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled:
            !!playerId &&
            !!season &&
            !!session?.roles.contentPermissions['player-draft-info'] &&
            options?.enabled !== false,
    })
}
