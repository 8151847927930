import type { BoardColumns, BoardLevels, BoardEntityType, BoardColumnModules } from '../definitions/enum/Boards'
import type { ContentPermissionType } from '../definitions/enum/Permissions'
import constants from './constants'

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const salaryYear = Number(constants.find((c) => c.key === 'salaryYear')!.value)
const statsYear = Number(constants.find((c) => c.key === 'statsYear')!.value)
/* eslint-enable @typescript-eslint/no-non-null-assertion */

const formatSeason = (season: number): string => `${season.toString().slice(-2)}-${(season + 1).toString().slice(-2)}`

const boardColumnTypes: {
    columnType: BoardColumns
    title: string
    sortOrder: number
    level: BoardLevels | null
    contentPermission: ContentPermissionType | null
    entityType: BoardEntityType
    shortTitle: string
    module: BoardColumnModules
}[] = [
    // BIO
    {
        columnType: 'height',
        title: 'Height',
        sortOrder: 0,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'HT',
        module: 'BIO',
    },
    {
        columnType: 'weight',
        title: 'Weight',
        sortOrder: 10,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'WT',
        module: 'BIO',
    },
    {
        columnType: 'currentTeam',
        title: 'Current Team',
        sortOrder: 20,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'TM',
        module: 'BIO',
    },
    {
        columnType: 'mostRecentTeam',
        title: 'Most Recent Team',
        sortOrder: 30,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'REC TM',
        module: 'BIO',
    },
    {
        columnType: 'age',
        title: 'Age',
        sortOrder: 40,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'AGE',
        module: 'BIO',
    },
    {
        columnType: 'ageOn',
        title: 'Age On 7/1',
        sortOrder: 50,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'AGE 7/1',
        module: 'BIO',
    },
    {
        columnType: 'yearsOfService',
        title: 'Years of Service',
        sortOrder: 60,
        level: 'Pro',
        contentPermission: 'player-salary-info',
        entityType: 'PLAYER',
        shortTitle: 'YOS',
        module: 'BIO',
    },
    {
        columnType: 'position',
        title: 'Position',
        sortOrder: 70,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'POS',
        module: 'BIO',
    },
    {
        columnType: 'conference',
        title: 'Conference',
        sortOrder: 80,
        level: null,
        contentPermission: null,
        entityType: 'TEAM',
        shortTitle: 'CONF',
        module: 'BIO',
    },
    {
        columnType: 'division',
        title: 'Division',
        sortOrder: 90,
        level: null,
        contentPermission: null,
        entityType: 'TEAM',
        shortTitle: 'DIV',
        module: 'BIO',
    },
    // REPRESENTATION
    {
        columnType: 'agent',
        title: 'Agent',
        sortOrder: 100,
        level: null,
        contentPermission: 'player-representation-info',
        entityType: 'PLAYER',
        shortTitle: 'AGENT',
        module: 'REPRESENTATION',
    },
    {
        columnType: 'agency',
        title: 'Agency',
        sortOrder: 110,
        level: null,
        contentPermission: 'player-representation-info',
        entityType: 'PLAYER',
        shortTitle: 'AGENCY',
        module: 'REPRESENTATION',
    },
    // CONTRACT
    {
        columnType: 'contractStatus',
        title: 'Contract Status',
        sortOrder: 200,
        level: 'Pro',
        contentPermission: 'player-salary-info',
        entityType: 'PLAYER',
        shortTitle: 'CT',
        module: 'CONTRACT',
    },
    {
        columnType: 'currentSalary',
        title: `Salary (${formatSeason(salaryYear)})`,
        sortOrder: 210,
        level: 'Pro',
        contentPermission: 'player-salary-info',
        entityType: 'PLAYER',
        shortTitle: `SAL ${formatSeason(salaryYear)}`,
        module: 'CONTRACT',
    },
    {
        columnType: 'futureSalary1',
        title: `Salary (${formatSeason(salaryYear + 1)})`,
        sortOrder: 211,
        level: 'Pro',
        contentPermission: 'player-salary-info',
        entityType: 'PLAYER',
        shortTitle: `SAL ${formatSeason(salaryYear + 1)}`,
        module: 'CONTRACT',
    },
    {
        columnType: 'futureSalary2',
        title: `Salary (${formatSeason(salaryYear + 2)})`,
        sortOrder: 212,
        level: 'Pro',
        contentPermission: 'player-salary-info',
        entityType: 'PLAYER',
        shortTitle: `SAL ${formatSeason(salaryYear + 2)}`,
        module: 'CONTRACT',
    },
    {
        columnType: 'futureSalary3',
        title: `Salary (${formatSeason(salaryYear + 3)})`,
        sortOrder: 213,
        level: 'Pro',
        contentPermission: 'player-salary-info',
        entityType: 'PLAYER',
        shortTitle: `SAL ${formatSeason(salaryYear + 3)}`,
        module: 'CONTRACT',
    },
    {
        columnType: 'futureSalary4',
        title: `Salary (${formatSeason(salaryYear + 4)})`,
        sortOrder: 214,
        level: 'Pro',
        contentPermission: 'player-salary-info',
        entityType: 'PLAYER',
        shortTitle: `SAL ${formatSeason(salaryYear + 4)}`,
        module: 'CONTRACT',
    },
    {
        columnType: 'futureSalary5',
        title: `Salary (${formatSeason(salaryYear + 5)})`,
        sortOrder: 215,
        level: 'Pro',
        contentPermission: 'player-salary-info',
        entityType: 'PLAYER',
        shortTitle: `SAL ${formatSeason(salaryYear + 5)}`,
        module: 'CONTRACT',
    },
    {
        columnType: 'nextFourSalary',
        title: 'Salary (Next 4)',
        sortOrder: 220,
        level: 'Pro',
        contentPermission: 'player-salary-info',
        entityType: 'PLAYER',
        shortTitle: 'SAL N4',
        module: 'CONTRACT',
    },
    // DRAFT
    {
        columnType: 'scoutRegion',
        title: 'Region/Scout',
        sortOrder: 300,
        level: 'Amateur',
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'R/S',
        module: 'DRAFT',
    },
    {
        columnType: 'mockRange',
        title: 'Mock Range',
        sortOrder: 310,
        level: 'Amateur',
        contentPermission: 'mock-draft-model',
        entityType: 'PLAYER',
        shortTitle: 'MR',
        module: 'DRAFT',
    },
    {
        columnType: 'mockDraftPoints',
        title: 'Mock Draft Points',
        sortOrder: 320,
        level: 'Amateur',
        contentPermission: 'mock-draft-model',
        entityType: 'PLAYER',
        shortTitle: 'MDP',
        module: 'DRAFT',
    },
    // SCOUTING / INTEL
    {
        columnType: 'scoutReportsScout',
        title: '# Scouting (Me/Season)',
        sortOrder: 400,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: '#SCT ME/SEA',
        module: 'SCOUTING',
    },
    {
        columnType: 'intelReportsScout',
        title: '# Intel (Me/Season)',
        sortOrder: 401,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: '#INT ME/SEA',
        module: 'INTEL',
    },
    {
        columnType: 'scoutReports',
        title: '# Scouting (All/Season)',
        sortOrder: 410,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: '#SCT ALL/SEA',
        module: 'SCOUTING',
    },
    {
        columnType: 'intelReports',
        title: '# Intel (All/Season)',
        sortOrder: 411,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: '#INT ALL/SEA',
        module: 'INTEL',
    },
    {
        columnType: 'scoutReportsScoutAll',
        title: '# Scouting (Me/All-Time)',
        sortOrder: 420,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: '#SCT ME/AT',
        module: 'SCOUTING',
    },
    {
        columnType: 'intelReportsScoutAll',
        title: '# Intel (Me/All-Time)',
        sortOrder: 421,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: '#INT ME/AT',
        module: 'INTEL',
    },
    {
        columnType: 'scoutReportsAll',
        title: '# Scouting (All/All-Time)',
        sortOrder: 430,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: '#SCT All/AT',
        module: 'SCOUTING',
    },
    {
        columnType: 'intelReportsAll',
        title: '# Intel (All/All-Time)',
        sortOrder: 431,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: '#INT All/AT',
        module: 'INTEL',
    },
    // LOCS
    {
        columnType: 'locNowScout',
        title: 'LOC - Now (Me)',
        sortOrder: 500,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'LOC N/ME',
        module: 'LOCS',
    },
    {
        columnType: 'locLowScout',
        title: 'LOC - Low (Me)',
        sortOrder: 510,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'LOC L/ME',
        module: 'LOCS',
    },
    {
        columnType: 'locBullScout',
        title: 'LOC - Bull (Me)',
        sortOrder: 520,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'LOC B/ME',
        module: 'LOCS',
    },
    {
        columnType: 'locHighScout',
        title: 'LOC - High (Me)',
        sortOrder: 530,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'LOC H/ME',
        module: 'LOCS',
    },
    {
        columnType: 'locNow',
        title: 'LOC - Now (All)',
        sortOrder: 540,
        level: null,
        contentPermission: 'locs',
        entityType: 'PLAYER',
        shortTitle: 'LOC N/ALL',
        module: 'LOCS',
    },
    {
        columnType: 'locLow',
        title: 'LOC - Low (All)',
        sortOrder: 550,
        level: null,
        contentPermission: 'locs',
        entityType: 'PLAYER',
        shortTitle: 'LOC L/ALL',
        module: 'LOCS',
    },
    {
        columnType: 'locBull',
        title: 'LOC - Bull (All)',
        sortOrder: 560,
        level: null,
        contentPermission: 'locs',
        entityType: 'PLAYER',
        shortTitle: 'LOC B/ALL',
        module: 'LOCS',
    },
    {
        columnType: 'locHigh',
        title: 'LOC - High (All)',
        sortOrder: 570,
        level: null,
        contentPermission: 'locs',
        entityType: 'PLAYER',
        shortTitle: 'LOC H/ALL',
        module: 'LOCS',
    },
    {
        columnType: 'scoutMostRecentLocs',
        title: 'LOC - Latest Date (ME)',
        sortOrder: 580,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'LOC LD/ME',
        module: 'LOCS',
    },
    {
        columnType: 'locInsights',
        title: 'Insights LOC',
        sortOrder: 590,
        level: null,
        contentPermission: 'locs',
        entityType: 'PLAYER',
        shortTitle: 'INSIGHTS',
        module: 'LOCS',
    },
    // INTENSITY_GRADES
    {
        columnType: 'intensityOn',
        title: 'On-Court Intensity',
        sortOrder: 600,
        level: 'Pro',
        contentPermission: 'intensity-grades',
        entityType: 'PLAYER',
        shortTitle: 'INT OC',
        module: 'INTENSITY_GRADES',
    },
    {
        columnType: 'intensityOffPersonal',
        title: 'Off-Court Personal Intensity',
        sortOrder: 610,
        level: 'Pro',
        contentPermission: 'intensity-grades',
        entityType: 'PLAYER',
        shortTitle: 'INT PERS',
        module: 'INTENSITY_GRADES',
    },
    {
        columnType: 'intensityOffRelationships',
        title: 'Off-Court Relationships Intensity',
        sortOrder: 620,
        level: 'Pro',
        contentPermission: 'intensity-grades',
        entityType: 'PLAYER',
        shortTitle: 'INT REL',
        module: 'INTENSITY_GRADES',
    },
    {
        columnType: 'intensityMed',
        title: 'Medical Intensity',
        sortOrder: 630,
        level: 'Pro',
        contentPermission: 'intensity-grades',
        entityType: 'PLAYER',
        shortTitle: 'INT MED',
        module: 'INTENSITY_GRADES',
    },
    // ROSTER_LIKELIHOOD
    {
        columnType: 'rosterLikelihood',
        title: 'Likelihood on Roster',
        sortOrder: 700,
        level: 'Pro',
        contentPermission: 'roster-likelihood',
        entityType: 'PLAYER',
        shortTitle: 'ROS LK',
        module: 'ROSTER_LIKELIHOOD',
    },
    {
        columnType: 'leagueLikelihood',
        title: 'Likelihood in League',
        sortOrder: 710,
        level: 'Pro',
        contentPermission: 'roster-likelihood',
        entityType: 'PLAYER',
        shortTitle: 'LG LK',
        module: 'ROSTER_LIKELIHOOD',
    },
    // TNDC
    {
        columnType: 'NBAteamPTNDC',
        title: 'NBA PTNDC Team',
        sortOrder: 800,
        level: 'Pro',
        contentPermission: 'nba-depth-charts',
        entityType: 'PLAYER',
        shortTitle: 'PTNDC TM',
        module: 'TNDC',
    },
    {
        columnType: 'NBAlocationPTNDC',
        title: 'NBA PTNDC Location',
        sortOrder: 810,
        level: 'Pro',
        contentPermission: 'nba-depth-charts',
        entityType: 'PLAYER',
        shortTitle: 'PTNDC LOC',
        module: 'TNDC',
    },
    {
        columnType: 'NBAteamTNDC',
        title: 'NBA TNDC Team',
        sortOrder: 820,
        level: 'Pro',
        contentPermission: 'nba-depth-charts',
        entityType: 'PLAYER',
        shortTitle: 'TNDC TM',
        module: 'TNDC',
    },
    {
        columnType: 'NBAlocationTNDC',
        title: 'NBA TNDC Location',
        sortOrder: 830,
        level: 'Pro',
        contentPermission: 'nba-depth-charts',
        entityType: 'PLAYER',
        shortTitle: 'TNDC LOC',
        module: 'TNDC',
    },
    {
        columnType: 'GLGteamPTNDC',
        title: 'GLG PTNDC Team',
        sortOrder: 840,
        level: 'G-League',
        contentPermission: 'glg-depth-charts',
        entityType: 'PLAYER',
        shortTitle: 'GLG PTNDC TM',
        module: 'TNDC',
    },
    {
        columnType: 'GLGlocationPTNDC',
        title: 'GLG PTNDC Location',
        sortOrder: 850,
        level: 'G-League',
        contentPermission: 'glg-depth-charts',
        entityType: 'PLAYER',
        shortTitle: 'GLG PTNDC LOC',
        module: 'TNDC',
    },
    {
        columnType: 'GLGteamTNDC',
        title: 'GLG TNDC Team',
        sortOrder: 860,
        level: 'G-League',
        contentPermission: 'glg-depth-charts',
        entityType: 'PLAYER',
        shortTitle: 'GLG TNDC TM',
        module: 'TNDC',
    },
    {
        columnType: 'GLGlocationTNDC',
        title: 'GLG TNDC Location',
        sortOrder: 870,
        level: 'G-League',
        contentPermission: 'glg-depth-charts',
        entityType: 'PLAYER',
        shortTitle: 'GLG TNDC LOC',
        module: 'TNDC',
    },
    // SKILLS BLEND
    {
        columnType: 'skillAthleticismBlend',
        title: 'Blended Skill - Athleticism',
        sortOrder: 901,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'ATH B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillBbiqBlend',
        title: 'Blended Skill - BBIQ',
        sortOrder: 902,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'BBIQ B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillRebounderBlend',
        title: 'Blended Skill - Rebounder',
        sortOrder: 904,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'REB B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillShooterBlend',
        title: 'Blended Skill - Shooter',
        sortOrder: 906,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'SHT B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillShotCreatorBlend',
        title: 'Blended Skill - Shot Creator',
        sortOrder: 908,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'SC B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillQuickDecisionsBlend',
        title: 'Blended Skill - Quick Decisions',
        sortOrder: 911,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'QD B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillScorerBlend',
        title: 'Blended Skill - Scorer',
        sortOrder: 912,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'SCR B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillFinisherBlend',
        title: 'Blended Skill - Finisher',
        sortOrder: 914,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'FIN B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillPlaymakerBlend',
        title: 'Blended Skill - Playmaker',
        sortOrder: 916,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'PM B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillRimProtectorBlend',
        title: 'Blended Skill - Rim Protector',
        sortOrder: 918,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'RP B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillTeamDefenderBlend',
        title: 'Blended Skill - Team Defender',
        sortOrder: 921,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'TD B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillIndividualDefenseBlend',
        title: 'Blended Skill - Individual Defense',
        sortOrder: 922,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'ID B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillPnrDefenderBlend',
        title: 'Blended Skill - PnR Defender',
        sortOrder: 924,
        level: 'Pro',
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'PnR D B',
        module: 'SKILLS_BLEND',
    },
    {
        columnType: 'skillCompetitivenessBlend',
        title: 'Blended Skill - Competitiveness',
        sortOrder: 926,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'COMP B',
        module: 'SKILLS_BLEND',
    },
    // SKILLS SCOUTING
    {
        columnType: 'skillAthleticismScouting',
        title: 'Scouting Skill - Athleticism',
        sortOrder: 928,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'ATH S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillBbiqScouting',
        title: 'Scouting Skill - BBIQ',
        sortOrder: 931,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'BBIQ S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillRebounderScouting',
        title: 'Scouting Skill - Rebounder',
        sortOrder: 932,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'REB S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillShooterScouting',
        title: 'Scouting Skill - Shooter',
        sortOrder: 934,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'SHT S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillShotCreatorScouting',
        title: 'Scouting Skill - Shot Creator',
        sortOrder: 936,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'SC S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillQuickDecisionsScouting',
        title: 'Scouting Skill - Quick Decisions',
        sortOrder: 938,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'QD S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillScorerScouting',
        title: 'Scouting Skill - Scorer',
        sortOrder: 941,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'SCR S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillFinisherScouting',
        title: 'Scouting Skill - Finisher',
        sortOrder: 942,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'FIN S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillPlaymakerScouting',
        title: 'Scouting Skill - Playmaker',
        sortOrder: 944,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'PM S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillRimProtectorScouting',
        title: 'Scouting Skill - Rim Protector',
        sortOrder: 946,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'RP S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillTeamDefenderScouting',
        title: 'Scouting Skill - Team Defender',
        sortOrder: 948,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'TD',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillIndividualDefenseScouting',
        title: 'Scouting Skill - Individual Defense',
        sortOrder: 951,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'ID S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillPnrDefenderScouting',
        title: 'Scouting Skill - PnR Defender',
        sortOrder: 952,
        level: 'Pro',
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'PnR D S',
        module: 'SKILLS_SCOUTING',
    },
    {
        columnType: 'skillCompetitivenessScouting',
        title: 'Scouting Skill - Competitiveness',
        sortOrder: 954,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'COMP S',
        module: 'SKILLS_SCOUTING',
    },
    // SKILLS INSIGHTS
    {
        columnType: 'skillAthleticismInsights',
        title: 'Insights Skill - Athleticism',
        sortOrder: 956,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'ATH I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillBbiqInsights',
        title: 'Insights Skill - BBIQ',
        sortOrder: 958,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'BBIQ I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillRebounderInsights',
        title: 'Insights Skill - Rebounder',
        sortOrder: 961,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'REB I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillShooterInsights',
        title: 'Insights Skill - Shooter',
        sortOrder: 962,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'SHT I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillShotCreatorInsights',
        title: 'Insights Skill - Shot Creator',
        sortOrder: 964,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'SC I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillQuickDecisionsInsights',
        title: 'Insights Skill - Quick Decisions',
        sortOrder: 966,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'QD I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillScorerInsights',
        title: 'Insights Skill - Scorer',
        sortOrder: 968,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'SCR I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillFinisherInsights',
        title: 'Insights Skill - Finisher',
        sortOrder: 971,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'FIN I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillPlaymakerInsights',
        title: 'Insights Skill - Playmaker',
        sortOrder: 972,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'PM I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillRimProtectorInsights',
        title: 'Insights Skill - Rim Protector',
        sortOrder: 974,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'RP I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillTeamDefenderInsights',
        title: 'Insights Skill - Team Defender',
        sortOrder: 976,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'TD I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillIndividualDefenseInsights',
        title: 'Insights Skill - Individual Defense',
        sortOrder: 978,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'ID I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillPnrDefenderInsights',
        title: 'Insights Skill - PnR Defender',
        sortOrder: 981,
        level: 'Pro',
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'PnR D I',
        module: 'SKILLS_INSIGHTS',
    },
    {
        columnType: 'skillCompetitivenessInsights',
        title: 'Insights Skill - Competitiveness',
        sortOrder: 982,
        level: null,
        contentPermission: 'skills',
        entityType: 'PLAYER',
        shortTitle: 'COMP I',
        module: 'SKILLS_INSIGHTS',
    },
    // INJURY
    {
        columnType: 'injuryStatus',
        title: 'Injury Status',
        sortOrder: 1000,
        level: null,
        contentPermission: 'injury-history',
        entityType: 'PLAYER',
        shortTitle: 'INJ',
        module: 'INJURY',
    },
    {
        columnType: 'currentInjury',
        title: 'Current Injury',
        sortOrder: 1010,
        level: null,
        contentPermission: 'injury-history',
        entityType: 'PLAYER',
        shortTitle: 'CUR INJ',
        module: 'INJURY',
    },
    {
        columnType: 'gamesMissedCurrent',
        title: 'Games Missed (Current)',
        sortOrder: 1020,
        level: null,
        contentPermission: 'injury-history',
        entityType: 'PLAYER',
        shortTitle: 'DNP INJ',
        module: 'INJURY',
    },
    {
        columnType: 'gamesMissedSeason',
        title: 'Games Missed (Season)',
        sortOrder: 1030,
        level: null,
        contentPermission: 'injury-history',
        entityType: 'PLAYER',
        shortTitle: `DNP ${formatSeason(statsYear)}`,
        module: 'INJURY',
    },
    // STATS
    {
        columnType: 'statsTeam',
        title: 'Stats Team(s)',
        sortOrder: 1100,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'TM',
        module: 'STATS',
    },
    {
        columnType: 'statsSeason',
        title: 'Season',
        sortOrder: 1110,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'SEASON',
        module: 'STATS',
    },
    {
        columnType: 'statsLeague',
        title: 'League',
        sortOrder: 1120,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'LG',
        module: 'STATS',
    },
    {
        columnType: 'gp',
        title: 'GP',
        sortOrder: 1130,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'GP',
        module: 'STATS',
    },
    {
        columnType: 'mpg',
        title: 'MPG',
        sortOrder: 1135,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'MPG',
        module: 'STATS',
    },
    {
        columnType: 'fgPerc',
        title: 'FG%',
        sortOrder: 1140,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'FG%',
        module: 'STATS',
    },
    {
        columnType: 'ftPerc',
        title: 'FT%',
        sortOrder: 1145,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'FT%',
        module: 'STATS',
    },
    {
        columnType: 'threePerc',
        title: '3P%',
        sortOrder: 1150,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: '3P%',
        module: 'STATS',
    },
    {
        columnType: 'efg',
        title: 'eFG%',
        sortOrder: 1155,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'eFG%',
        module: 'STATS',
    },
    {
        columnType: 'ppg',
        title: 'PPG',
        sortOrder: 1160,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'PPG',
        module: 'STATS',
    },
    {
        columnType: 'trb',
        title: 'RB',
        sortOrder: 1165,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'RB',
        module: 'STATS',
    },
    {
        columnType: 'ast',
        title: 'AST',
        sortOrder: 1170,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'AST',
        module: 'STATS',
    },
    {
        columnType: 'blk',
        title: 'BLK',
        sortOrder: 1175,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'BLK',
        module: 'STATS',
    },
    {
        columnType: 'stl',
        title: 'STL',
        sortOrder: 1180,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'STL',
        module: 'STATS',
    },
    // STANDINGS
    {
        columnType: 'wins',
        title: 'Wins',
        sortOrder: 1200,
        level: 'Pro',
        contentPermission: null,
        entityType: 'TEAM',
        shortTitle: 'W',
        module: 'STANDINGS',
    },
    {
        columnType: 'projectedWins',
        title: 'Projected Wins',
        sortOrder: 1210,
        level: 'Pro',
        contentPermission: 'predictive-standings',
        entityType: 'TEAM',
        shortTitle: 'PW',
        module: 'STANDINGS',
    },
    // NOTES
    {
        columnType: 'playerNotes',
        title: 'Player Notes',
        sortOrder: 1300,
        level: null,
        contentPermission: null,
        entityType: 'PLAYER',
        shortTitle: 'PL NT',
        module: 'NOTES',
    },
    {
        columnType: 'teamNotes',
        title: 'Team Notes',
        sortOrder: 1310,
        level: null,
        contentPermission: null,
        entityType: 'TEAM',
        shortTitle: 'TM NT',
        module: 'NOTES',
    },
]

export default boardColumnTypes
