import type { UseQueryOptions, UseQueryResult, QueryKey, UseSuspenseQueryResult } from '@tanstack/react-query'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { getLeagueOrgTeamSlug, knicksSlug, westchesterSlug } from '../../shared/utils/leagues'
import { get, serverGet } from '../api'

export const queryKey = (teamId: string): QueryKey => ['teams', teamId]

const apiPath = (teamSlug: string): string => `/teams/${teamSlug}`

export const getTeam = async (teamSlug: string): Promise<DTO.TeamWithAffiliates> =>
    (await get<DTO.TeamWithAffiliates>(apiPath(teamSlug))).data

export const useGetTeamByTeamSlug = (teamSlug: string | null | undefined): UseQueryResult<DTO.Team> =>
    useQuery({
        queryKey: queryKey(teamSlug!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getTeam(teamSlug!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!teamSlug,
        gcTime: 1000 * 60 * 60 * 24,
    })

export const useGetKnicks = (): UseQueryResult<DTO.Team> => useGetTeamByTeamSlug(knicksSlug)
export const useGetWestchester = (): UseQueryResult<DTO.Team> => useGetTeamByTeamSlug(westchesterSlug)
export const useGetKnicksOrgTeam = (league: Enum.League | undefined): UseQueryResult<DTO.Team> =>
    useGetTeamByTeamSlug(getLeagueOrgTeamSlug(league))

// ONLY use in `getStaticPaths` or `getStaticProps` functions
export const staticGetTeam = async (teamSlug: string): Promise<DTO.TeamWithAffiliates> =>
    (
        await serverGet<DTO.TeamWithAffiliates>(apiPath(teamSlug), {
            apiCacheKey: '/teams/:teamSlug',
            cacheTag: teamSlug,
        })
    ).data

export const getTeamByTeamId = async (teamId: string): Promise<DTO.Team> =>
    (await get<DTO.Team>(`/teams/by-team-id/${teamId}`)).data

export const useGetTeamByTeamId = (teamId: string | null | undefined): UseQueryResult<DTO.Team> =>
    useQuery({
        queryKey: queryKey(teamId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getTeamByTeamId(teamId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!teamId,
    })

export const useSuspenseGetTeamByTeamId = (teamId: string): UseSuspenseQueryResult<DTO.Team> =>
    useSuspenseQuery({
        queryKey: queryKey(teamId),
        queryFn: () => getTeamByTeamId(teamId),
    })

export const getPrevAndNextGameInfo = async (teamId: string): Promise<DTO.PrevAndNextGameInfo> =>
    (await get<DTO.PrevAndNextGameInfo>(`/teams/${teamId}/prev-next-game-info`)).data

export const useGetPrevAndNextGameInfo = (
    teamId: string | null | undefined,
    options?: Omit<
        UseQueryOptions<DTO.PrevAndNextGameInfo>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PrevAndNextGameInfo> =>
    useQuery({
        queryKey: ['prev-next-game-info', teamId],
        queryFn: () => getPrevAndNextGameInfo(teamId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!teamId && options?.enabled !== false,
    })

const useTeam = (
    teamId: string | null | undefined,
    options?: Omit<UseQueryOptions<DTO.TeamWithAffiliates>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.TeamWithAffiliates> =>
    useQuery({
        queryKey: queryKey(teamId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getTeam(teamId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!teamId && options?.enabled !== false,
    })

export default useTeam
