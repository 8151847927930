import type { UseQueryResult, UseMutationResult, QueryKey, UseQueryOptions } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { post, get, put } from '../api'
import useToastContext from './useToastContext'

type BoardModalQueryKeyParams = { boardId?: string; status?: Enum.QueryBoardStatus }
export const getBoardModalQueryKey = (params?: BoardModalQueryKeyParams): QueryKey =>
    params ? ['boards', params.boardId, params.status] : ['boards']

export const useCreateBoard = (): UseMutationResult<JSONResponse, Error, DTO.CreateUpdateBoard> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (board: DTO.CreateUpdateBoard) => post<undefined, DTO.CreateUpdateBoard>('/boards', board),

        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: getBoardModalQueryKey() })
        },
    })
}

export const useCopyBoard = (): UseMutationResult<JSONResponse, Error, Pick<DTO.CreateUpdateBoard, 'boardId'>> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation({
        mutationFn: (board: Pick<DTO.CreateUpdateBoard, 'boardId'>) =>
            post<undefined, Pick<DTO.CreateUpdateBoard, 'boardId'>>(`/boards/${board.boardId}/copy`, board),

        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: getBoardModalQueryKey() })
        },

        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getBoardModalQueryKey() })
            toastContext?.addToast({
                severity: 'success',
                message: 'Board Successfully Copied',
            })
        },
    })
}

const getBoardList = async (status?: Enum.QueryBoardStatus): Promise<DTO.BoardModal[]> =>
    (await get<DTO.BoardModal[]>(`/boards`, { status })).data

export const useBoardList = (
    boardStatus: Enum.QueryBoardStatus | null = 'ACTIVE',
    options?: Omit<UseQueryOptions<DTO.BoardModal[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.BoardModal[]> => {
    // NULL allows us to override the default status in the function call, but we should make sure we're not sending a NULL value to the API
    const status = boardStatus || undefined
    return useQuery({
        queryKey: getBoardModalQueryKey({ status }),
        queryFn: () => getBoardList(status),
        enabled: options?.enabled !== false,
    })
}

export const useUpdateBoardStatus = (): UseMutationResult<
    JSONResponse<DTO.BoardStatusUpdate>,
    Error,
    DTO.BoardStatusUpdate
> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation({
        mutationFn: (updatedBoard: DTO.BoardStatusUpdate) =>
            put<DTO.BoardStatusUpdate, DTO.BoardStatusUpdate>(
                `/boards/${updatedBoard.boardId}/status`,
                updatedBoard,
                undefined,
                { 'If-Match': updatedBoard.version }
            ),

        onSettled: () => queryClient.invalidateQueries({ queryKey: getBoardModalQueryKey() }),

        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({ queryKey: getBoardModalQueryKey() })
            let message: string
            if (variables.status === 'DELETED') {
                message = 'Successfully Deleted'
            } else if (variables.status === 'ARCHIVED') {
                message = 'Successfully Archived'
            } else {
                message = 'Successfully Unarchived'
            }
            toastContext?.addToast({
                severity: 'success',
                message: `Board ${message || 'Status Updated'}`,
            })
        },
    })
}

export const useUpdateBoardCompleteStatus = (): UseMutationResult<
    JSONResponse<DTO.BoardCompletionStatus>,
    Error,
    DTO.BoardCompletionStatus
> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation({
        mutationFn: (completionStatus: DTO.BoardCompletionStatus) =>
            put<DTO.BoardCompletionStatus, DTO.BoardCompletionStatus>(
                `/boards/${completionStatus.boardId}/complete`,
                completionStatus,
                undefined,
                { 'If-Match': completionStatus.version }
            ),

        onSettled: () => queryClient.invalidateQueries(),

        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getBoardModalQueryKey() })
            toastContext?.addToast({
                severity: 'success',
                message: 'Board Completion Status Updated',
            })
        },
    })
}
