'use client'

import type { Except } from 'type-fest'
import type { Dispatch, SetStateAction } from 'react'
import type { DraftFilterParams } from '@/components/draft/DraftFilters'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { getDraftYearConstant, getDefaultLeagueProjectionType } from '../../shared/utils/leagues'
import { useConstantsContext } from '@/lib/contexts/ConstantsContext'
import { URLSearchParamsToObject } from '@/lib/routing/searchParams'

type UseDraftParamsDefaults = Except<DraftFilterParams, 'draft' | 'projectionType'>
type UseDraftParamsReturn = {
    defaultParams: DraftFilterParams
    filterParams: DraftFilterParams
    setFilterParams: Dispatch<SetStateAction<DraftFilterParams>>
    isPrintOnly: boolean
    isSingleReportPrint: boolean
    draftYear: number
}

const getInitFilterParams = (
    defaultParams: DraftFilterParams,
    queryParams: Partial<DraftFilterParams>
): DraftFilterParams => ({
    league: queryParams.league || defaultParams.league,
    projectionType: queryParams.projectionType || defaultParams.projectionType,
    draft: queryParams.draft || defaultParams.draft,
    teamId: queryParams.teamId || defaultParams.teamId,
    round: queryParams.round || defaultParams.round,
    date: queryParams.date || defaultParams.date,
})

export const useLeagueDraftYear = (league: Enum.League | undefined): number => {
    const constants = useConstantsContext()
    return getDraftYearConstant(league, constants)
}

const useDraftParams = ({ league, teamId, round, date }: UseDraftParamsDefaults): UseDraftParamsReturn => {
    const draftYear = useLeagueDraftYear(league)
    const searchParams = useSearchParams()
    const queryParams = URLSearchParamsToObject(searchParams) as Partial<DraftFilterParams>

    const defaultParams: DraftFilterParams = useMemo(
        () => ({
            league,
            projectionType: getDefaultLeagueProjectionType(league, 'draft'),
            draft: draftYear,
            teamId,
            round,
            date,
        }),
        [draftYear, league, teamId, round, date]
    )

    const [filterParams, setFilterParams] = useState<DraftFilterParams>(() =>
        getInitFilterParams(defaultParams, queryParams)
    )

    // reset the filter if the defaults change
    const [prevDefaultParams, setPrevDefaultParams] = useState(defaultParams)
    if (prevDefaultParams !== defaultParams) {
        setPrevDefaultParams(defaultParams)
        setFilterParams(getInitFilterParams(defaultParams, queryParams))
    }

    return {
        defaultParams,
        filterParams,
        setFilterParams,
        isPrintOnly: !!searchParams.get('printOnly'),
        isSingleReportPrint: searchParams.get('type') !== 'mockDraft' && searchParams.get('type') !== 'draftDigest',
        draftYear,
    }
}

export default useDraftParams
