import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, put } from '../api'

const getIntelCoverage = async ({
    season,
    boardId,
    scoutEntityId,
    level,
}: {
    season: number
    level: Enum.PlayerLevel
    scoutEntityId: string | undefined
    boardId: string | undefined
}): Promise<DTO.IntelCoverage[]> =>
    (await get<DTO.IntelCoverage[]>(`/coverage/intel/${season}/${level}`, { scoutEntityId, boardId })).data

export const useIntelCoverage = ({
    season,
    level,
    scoutEntityId,
    options,
    boardId,
}: {
    season: number
    level: Enum.PlayerLevel
    scoutEntityId: string | undefined
    boardId: string | undefined
    options?: Omit<
        UseQueryOptions<DTO.IntelCoverage[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.IntelCoverage[]> =>
    useQuery<DTO.IntelCoverage[]>({
        queryKey: ['coverage', 'intel', season, level, boardId, scoutEntityId],
        queryFn: () => getIntelCoverage({ season, boardId, level, scoutEntityId }),
        ...options,
        enabled: options?.enabled !== false,
    })

export const useSaveIntelCoverageNote = (): UseMutationResult<JSONResponse, Error, DTO.IntelCoverageNote> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (note: DTO.IntelCoverageNote) =>
            put<undefined, DTO.IntelCoverageNote>(`/coverage/intel/note`, note),
        onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: ['coverage', 'intel'] })
            void queryClient.invalidateQueries({ queryKey: ['board-players'] })
        },
    })
}
