import type { Except } from 'type-fest'
import type { JSONResponse } from '../api'
import type {
    UseQueryResult,
    QueryKey,
    UseMutationResult,
    UseQueryOptions,
    UseSuspenseQueryResult,
} from '@tanstack/react-query'
import type { PaginatedResp, InfiniteQueryPaginatedResp } from './usePosts'
import type { Session } from 'next-auth'
import capitalize from '@mui/utils/capitalize'
import { useQuery, useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { get, put, serverGet } from '../api'
import { useConstantsContext } from '../contexts/ConstantsContext'
import useToastContext from './useToastContext'

export const queryKey = (postId: string): QueryKey => ['post', postId]
const reportTypeToRoute: Record<Enum.ReportType, string> = {
    SCOUTING: 'scouting',
    INTEL: 'intel',
    PLAYER_DEVELOPMENT: 'player-development',
    COMMUNITY: 'community',
    GAME: 'game',
    WORKOUT: 'workout',
}

export const serverGetPost = async (postId: string, session: Session | null): Promise<DTO.Post> =>
    (await serverGet<DTO.Post>(`/posts/${postId}`, { session, featurePermissions: ['post-feed'] })).data

export const getPost = async (postId: string): Promise<DTO.Post> => (await get<DTO.Post>(`/posts/${postId}`)).data

const usePost = (
    postId: string,
    options?: Except<UseQueryOptions<DTO.Post>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Post> =>
    useQuery({
        queryKey: queryKey(postId),
        queryFn: () => getPost(postId),
        ...options,
    })
export const useSuspensePost = (postId: string): UseSuspenseQueryResult<DTO.Post> =>
    useSuspenseQuery({ queryKey: queryKey(postId), queryFn: () => getPost(postId) })

const queryIntelCounts = async (params: DTO.GetIntelCounts): Promise<DTO.IntelReportCounts[]> =>
    (await get<DTO.IntelReportCounts[]>(`/posts/intel/counts/${params.entityId}`, params)).data

export const useQueryIntelCounts = (params: DTO.GetIntelCounts): UseQueryResult<DTO.IntelReportCounts[]> =>
    useQuery({
        queryKey: ['intel', params],
        queryFn: () => queryIntelCounts(params),
        enabled: !!params.entityId,
    })

export type EditPostType = { postID: string; type: Enum.PostType; status: Enum.PostStatus }
const attachStatusToPost = <T extends EditPostType>(report: T, newStatus?: Enum.PostStatus) =>
    newStatus ? { ...report, status: newStatus } : report
export const useEditPost = <EditPost extends EditPostType>(
    newStatus?: Enum.PostStatus
): UseMutationResult<JSONResponse<DTO.Entity[] | undefined>, Error, EditPost> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    const { ncaaSeason } = useConstantsContext()
    return useMutation({
        mutationFn: (report: EditPost) =>
            put<DTO.Entity[] | undefined, EditPost>(
                `/posts/${reportTypeToRoute[report.type]}/${report.postID}`,
                attachStatusToPost(report, newStatus)
            ),

        onMutate: async (updatedPost) => {
            // get and set query data for ['post', postID]
            const postQueryKey = queryKey(updatedPost.postID)
            await queryClient.cancelQueries({ queryKey: postQueryKey })

            const oldPost = queryClient.getQueryData<DTO.Post>(postQueryKey)
            queryClient.setQueryData(postQueryKey, attachStatusToPost(updatedPost, newStatus))

            // get and set query data for ['posts']
            await queryClient.cancelQueries({
                queryKey: ['posts'],
            })
            const queriesData = queryClient.getQueriesData<InfiniteQueryPaginatedResp>({
                queryKey: ['posts'],
            })
            if (!queriesData.length) return { postQueryKey, oldPost }

            const [[qk, oldPaginatedPosts]] = queriesData
            if (!oldPaginatedPosts) return { postQueryKey, oldPost }

            const newPages = oldPaginatedPosts.pages.map((page: PaginatedResp) => ({
                ...page,
                results: page.results.filter((p) => p.postID !== updatedPost.postID),
            }))

            queryClient.setQueryData<InfiniteQueryPaginatedResp>(qk, {
                ...oldPaginatedPosts,
                pages: newPages,
            })

            // Return a context object with the snapshotted value
            return { postQueryKey, oldPost, postsQueryKey: qk, oldPaginatedPosts }
        },

        onError: (error, submittedPost, context) => {
            toastContext?.addToast({
                severity: 'error',
                message: error.message,
            })

            if (context) {
                const { postQueryKey, oldPost, postsQueryKey, oldPaginatedPosts } = context as {
                    postQueryKey?: QueryKey
                    postsQueryKey?: QueryKey
                    oldPost?: DTO.Post
                    oldPaginatedPosts?: InfiniteQueryPaginatedResp
                }
                if (postQueryKey && oldPost) queryClient.setQueryData(postQueryKey, oldPost)
                if (postsQueryKey && oldPaginatedPosts) queryClient.setQueryData(postsQueryKey, oldPaginatedPosts)
            }
        },

        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['posts'],
            })
            await queryClient.invalidateQueries({
                queryKey: ['scout-player-tiers', ncaaSeason + 1],
            })
            await queryClient.invalidateQueries({
                queryKey: ['board-data'],
            })
            await queryClient.invalidateQueries({
                queryKey: ['search'],
            })
        },

        onSuccess: async (_data, { status, type }) => {
            if (status === 'DELETED') {
                toastContext?.addToast({
                    severity: 'success',
                    message: `${capitalize(type.toLocaleLowerCase())} Report Deleted`,
                })
            }
            if (status !== 'DRAFT')
                await queryClient.invalidateQueries({
                    queryKey: ['post'],
                })
        },
    })
}

export default usePost
