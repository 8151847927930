import type { Except } from 'type-fest'
import type { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import type { FollowingModalFormType } from '../../components/feed/FollowingModalForm'
import type { Session } from 'next-auth'
import type { IncompleteReportsQueryParams } from './useScoutAssignments'
import dayjs from 'dayjs'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { numDaysOnIncompleteReportList } from '../../shared/constants/assignments'
import { formatDateString, pastDate } from '../../shared/utils/dates'
import { get, post } from '../api'

export const getIncompleteAssignmentsQueryParams = (session: Session | null): IncompleteReportsQueryParams => {
    const isAssignmentManager = !!session?.roles.featurePermissions['assignment-schedule-management']

    const params: IncompleteReportsQueryParams = {
        startDate: formatDateString(pastDate(numDaysOnIncompleteReportList, 'days'), 'YYYY-MM-DD', 'local'),
        endDate: formatDateString(pastDate(1, 'days'), 'YYYY-MM-DD', 'local'),
    }
    if (isAssignmentManager) {
        const level: Enum.BoardLevels[] = []
        if (session.roles.contentPermissions['amateur-assignments']) level.push('Amateur')
        if (session.roles.contentPermissions['pro-assignments']) level.push('Pro')
        params.level = level
    } else {
        params.scoutId = session?.entityId
    }
    return params
}

const getUserFollowing = async () => (await get<DTO.UserFollowing>('/user-following')).data

export const useUserFollowing = (
    options?: Except<UseQueryOptions<DTO.UserFollowing>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.UserFollowing> =>
    useQuery({
        queryKey: ['user-following'],
        queryFn: getUserFollowing,
        ...options,
        retry: false,
    })

export const useSaveUserFollowing = (
    options?: Except<UseMutationOptions<JSONResponse, Error, DTO.UserFollowing>, 'mutationFn' | 'onSettled'>
): UseMutationResult<JSONResponse, Error, DTO.UserFollowing> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (following: DTO.UserFollowing) => post<undefined, DTO.UserFollowing>('/user-following', following),
        ...options,

        onSettled: () =>
            queryClient.invalidateQueries({
                predicate: ({ queryKey }) =>
                    (queryKey[0] === 'posts' &&
                        (queryKey[1] as DTO.AdvancedPostSearch | undefined)?.type === 'FOLLOWING') ||
                    queryKey[0] === 'user-following',
            }),
    })
}

export const getInitialFollowingModalFormValues = (): FollowingModalFormType => ({
    subjects: [],
    authors: [],
    content: [],
    boards: [],
    playerLists: [],
    keywords: [],
    showActivePosts: false,
    minDate: null,
    maxDate: null,
    intelTextTypes: [],
    hasComments: false,
})

export const useSearchAsUserFollowing = (
    advancedSearchParams: DTO.AdvancedPostSearch,
    options?: Except<UseQueryOptions<FollowingModalFormType>, 'queryKey' | 'queryFn'>
): UseQueryResult<FollowingModalFormType> =>
    useQuery({
        queryKey: ['user-following-search', advancedSearchParams],

        queryFn: async () => {
            const { data: form } = await get<DTO.UserFollowing | ''>('/user-following/search', advancedSearchParams)
            const { minDate, maxDate, intelTextTypes, hasComments } = advancedSearchParams
            if (!form && !minDate && !maxDate && !intelTextTypes && !hasComments)
                return getInitialFollowingModalFormValues()
            return {
                ...(form || getInitialFollowingModalFormValues()),
                intelTextTypes: !intelTextTypes
                    ? []
                    : Array.isArray(intelTextTypes)
                    ? intelTextTypes
                    : [intelTextTypes],
                minDate: minDate ? dayjs(minDate) : null,
                maxDate: maxDate ? dayjs(maxDate) : null,
                hasComments: hasComments === 'true',
            }
        },

        initialData: () =>
            !Object.keys(advancedSearchParams).length ? getInitialFollowingModalFormValues() : undefined,

        ...options,
    })
