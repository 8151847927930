import type { UseQueryResult, UseQueryOptions, QueryKey } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const usePlayerScoutingReport = (
    playerId: string | null | undefined,
    season: number,
    level: Enum.PlayerLevel | null | undefined,
    options?: Omit<
        UseQueryOptions<DTO.ScoutingReport, Error, DTO.ScoutingReport, QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.ScoutingReport> =>
    useQuery({
        queryKey: ['scouting-report', playerId, season, level],
        queryFn: async () =>
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            (await get<DTO.ScoutingReport>(`/profile/${playerId}/${season}/${level}/scouting-report`)).data,
        ...options,
        enabled: !!playerId && !!season && options?.enabled !== false,
    })

export default usePlayerScoutingReport
