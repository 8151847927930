import type { QueryKey, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { ToastContextInterface } from '../contexts/ToastContext'
import type { JSONResponse } from '../api'
import { useMemo } from 'react'
import { useSession } from 'next-auth/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    moveItemAndRecalculatePositionsAndRanks,
    recalculatePositionsAndRanks,
    getSelectedModules,
} from '../../shared/utils/boards'
import { get, put, post, remove, serverGet } from '../api'
import { getBoardModalQueryKey } from './useBoards'

export const getBoardDataQueryKey = (boardId: string, selectedModules?: Enum.BoardColumnModules[]): QueryKey => {
    const queryKey: (string | Array<Enum.BoardColumnModules>)[] = ['board-data', boardId.toLowerCase()]
    if (selectedModules) {
        queryKey.push(selectedModules)
    }
    return queryKey
}

export const serverGetBoardInfo = async (boardId: string): Promise<DTO.BoardInfo> =>
    (
        await serverGet<DTO.BoardInfo>(`/boards/${boardId}/info`, {
            featurePermissions: ['custom-boards'],
            apiCacheKey: '/boards/:boardId/info',
            cacheTag: `board-info-${boardId}`,
        })
    ).data

const getBoardData = async (boardId: string, params: { modules?: Enum.BoardColumnModules[] }): Promise<DTO.BoardData> =>
    (await get<DTO.BoardData>(`/boards/${boardId}`, params)).data

export const useBoardData = (
    boardId: string | undefined,
    selectedColumns: Enum.BoardColumns[] | undefined,
    options?: Omit<UseQueryOptions<DTO.BoardData>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.BoardData> => {
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    return useQuery<DTO.BoardData>({
        queryKey: getBoardDataQueryKey(boardId!, modules), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getBoardData(boardId!, { modules }), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!boardId && options?.enabled !== false,
    })
}

export type UpdateBoardType = DTO.CreateUpdateBoard | DTO.BoardData
const isCreateUpdateBoardType = (board: UpdateBoardType): board is DTO.CreateUpdateBoard => 'headline' in board
const getBoardQueryKey = (board: UpdateBoardType, modules?: Enum.BoardColumnModules[]): QueryKey =>
    isCreateUpdateBoardType(board)
        ? getBoardModalQueryKey({ boardId: board.boardId })
        : getBoardDataQueryKey(board.boardId, modules)

export type UpdateBoardParams = Pick<DTO.BoardData, 'boardId'> & {
    selectedColumns?: Enum.BoardColumns[] | undefined
    version: DTO.BoardData['version'] | undefined
}
export const useUpdateBoard = ({
    boardId,
    selectedColumns,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<UpdateBoardType>, Error, UpdateBoardType> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    return useMutation({
        mutationFn: (updatedBoardData: UpdateBoardType) =>
            put<UpdateBoardType, UpdateBoardType>(`/boards/${boardId}`, updatedBoardData, undefined, {
                'If-Match': version,
            }),
        onMutate: async (updatedBoardData: UpdateBoardType) => {
            const queryKey = getBoardQueryKey(updatedBoardData, modules)
            await queryClient.cancelQueries({ queryKey })
            const previousBoardData = queryClient.getQueryData(queryKey)
            queryClient.setQueryData(queryKey, updatedBoardData)
            return { previousBoardData, updatedBoardData }
        },
        onError: (err, updatedBoardData, context) => {
            const queryKey = getBoardQueryKey(updatedBoardData, modules)
            queryClient.setQueryData(queryKey, context?.previousBoardData)
        },
        onSuccess: (data, updatedBoardData) => {
            const newVersion = data.headers.get('etag')
            if (typeof newVersion === 'string') {
                const queryKey = getBoardQueryKey(updatedBoardData, modules)
                queryClient.setQueryData(queryKey, { ...updatedBoardData, version: newVersion })
            }
        },
        onSettled: async (data, error, updatedBoardData) => {
            if (isCreateUpdateBoardType(updatedBoardData)) {
                await Promise.all([
                    queryClient.invalidateQueries({ queryKey: getBoardModalQueryKey() }),
                    // only invalidate board player data when updating the board configuration/properties
                    queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) }),
                ])
            }
        },
    })
}

type AddItemResp = { message?: string; warning?: string }
export const useAddPlayersToBoard = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<AddItemResp>, Error, DTO.AddPlayersInput> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (boardPlayers: DTO.AddPlayersInput) =>
            post<AddItemResp, DTO.AddPlayersInput>(`/boards/${boardId}/players`, boardPlayers, undefined, {
                'If-Match': version,
            }),

        onSettled: () => queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) }),
    })
}

export const useAddTeamsToBoard = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<AddItemResp>, Error, DTO.AddTeamsInput> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (boardTeams: DTO.AddTeamsInput) =>
            post<AddItemResp, DTO.AddTeamsInput>(`/boards/${boardId}/teams`, boardTeams, undefined, {
                'If-Match': version,
            }),

        onSettled: () => queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) }),
    })
}

export const useAddTierToBoard = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<DTO.AddTierInput>, Error, DTO.AddTierInput> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (boardTier: DTO.AddTierInput) =>
            post<DTO.AddTierInput, DTO.AddTierInput>(`/boards/${boardId}/tiers`, boardTier, undefined, {
                'If-Match': version,
            }),

        onSettled: () => queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) }),
    })
}

export const useQueryBoardTypes = (): UseQueryResult<DTO.BoardType[]> =>
    useQuery({
        queryKey: ['boardTypes'],
        queryFn: async () => (await get<DTO.BoardType[]>(`/boards/types`)).data,
    })

export type EditTierInput = DTO.EditTierInput & Pick<DTO.BoardTier, 'id'>
export const useEditTierName = (
    { boardId, selectedColumns, version }: UpdateBoardParams,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse<EditTierInput>, Error, EditTierInput> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    return useMutation({
        mutationFn: (updatedTier: EditTierInput) =>
            put<EditTierInput, EditTierInput>(`/boards/${boardId}/tiers/${updatedTier.id}`, updatedTier, undefined, {
                'If-Match': version,
            }),
        onMutate: (updatedBoardTier: EditTierInput) => {
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(
                getBoardDataQueryKey(boardId, modules)
            )
            if (previousBoardData) {
                const updatedBoardData: DTO.BoardData = {
                    ...previousBoardData,
                    items: previousBoardData.items.map((i) => {
                        let { name } = i
                        if (i.type === 'tier' && i.id === updatedBoardTier.id) {
                            name = updatedBoardTier.name
                        }
                        return { ...i, name }
                    }),
                }
                queryClient.setQueryData(getBoardDataQueryKey(boardId, modules), updatedBoardData)
            }
            return { previousBoardData }
        },
        onError: (err, updatedBoardTier, context) => {
            queryClient.setQueryData(getBoardDataQueryKey(boardId, modules), context?.previousBoardData)
            toastContext?.addToast({
                severity: 'error',
                message: err.message,
            })
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) })
            toastContext?.addToast({
                severity: 'success',
                message: 'Saved Changes',
                duration: 1000,
            })
        },
    })
}

export const useEditPlayerPosition = (
    boardId: string,
    selectedColumns: Enum.BoardColumns[] | undefined,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse, Error, DTO.InputPlayerPosition> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    const queryKey = getBoardDataQueryKey(boardId, modules)
    return useMutation({
        mutationFn: (inputPlayerPosition: DTO.InputPlayerPosition) =>
            put<unknown, DTO.InputPlayerPosition>(
                `/boards/${boardId}/players/${inputPlayerPosition.playerId}/position`,
                inputPlayerPosition
            ),
        onMutate: (updatedPosition: DTO.InputPlayerPosition) => {
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
            if (previousBoardData) {
                const updatedBoardData: DTO.BoardData = {
                    ...previousBoardData,
                    items: previousBoardData.items.map((i) => {
                        if (i.type === 'player' && i.entityId === updatedPosition.playerId) {
                            return { ...i, player: { ...i.player, playerPosition: updatedPosition.position } }
                        }
                        return i
                    }),
                }
                queryClient.setQueryData(queryKey, updatedBoardData)
            }
            return { previousBoardData }
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.previousBoardData)
            toastContext?.addToast({
                severity: 'error',
                message: err.message,
            })
        },
        onSuccess: () => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Saved Changes',
                duration: 1000,
            })
        },
    })
}

export const useCreateLOCsForBoardPlayer = (
    boardId: string,
    selectedColumns: Enum.BoardColumns[] | undefined
): UseMutationResult<JSONResponse<DTO.LOC>, Error, DTO.LOC> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    return useMutation({
        mutationFn: (values: DTO.LOC) => post<DTO.LOC, DTO.LOC>('/scouting-locs', values),
        onMutate: (updatedLocs: DTO.LOC) => {
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(
                getBoardDataQueryKey(boardId, modules)
            )
            if (previousBoardData) {
                const updatedBoardData: DTO.BoardData = {
                    ...previousBoardData,
                    items: previousBoardData.items.map((i) => {
                        if (i.type === 'player' && i.id === updatedLocs.playerId) {
                            return {
                                ...i,
                                player: {
                                    ...i.player,
                                    locBullScout: updatedLocs.locBullseye,
                                    locNowScout: updatedLocs.locNow,
                                    locLowScout: updatedLocs.locLow,
                                    locHighScout: updatedLocs.locHigh,
                                    scoutMostRecentLocs: new Date().toJSON(),
                                },
                            }
                        }
                        return i
                    }),
                }
                queryClient.setQueryData(getBoardDataQueryKey(boardId, modules), updatedBoardData)
            }
            return { previousBoardData }
        },

        onError: (err, _, context) => {
            const queryKey = getBoardDataQueryKey(boardId, modules)
            queryClient.setQueryData(queryKey, context?.previousBoardData)
        },

        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) })
            void queryClient.invalidateQueries({ queryKey: ['bulk-skill-loc', 'players'] })
            void queryClient.invalidateQueries({ queryKey: ['bulk-skill-loc', 'progress'] })
        },
    })
}

export type CreateSkills = DTO.ScoutingSkills & { playerId: string; scoutEntityId: string }

export const useCreateSkillsForBoardPlayer = (): UseMutationResult<JSONResponse<CreateSkills>, Error, CreateSkills> => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (values: CreateSkills) => post<CreateSkills, CreateSkills>('/scouting-skills', values),
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ['bulk-skill-loc', 'players'] })
            void queryClient.invalidateQueries({ queryKey: ['bulk-skill-loc', 'progress'] })
        },
    })
}

export const getDesignatedBoardId = async (
    designation: Enum.BoardDesignation,
    season: number
): Promise<{ boardId: string } | ''> =>
    (await get<{ boardId: string } | ''>(`/boards/designations/${designation}/${season}`)).data

export const useGetDesignatedBoardId = (
    designation: Enum.BoardDesignation,
    season: number,
    options?: Omit<
        UseQueryOptions<{ boardId: string } | '', Error, { boardId: string } | '', QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<{ boardId: string } | ''> => {
    const { data: session } = useSession()
    return useQuery({
        queryKey: ['designatedBoard', designation, season],
        queryFn: () => getDesignatedBoardId(designation, season),
        ...options,
        enabled: !!session?.roles.featurePermissions['custom-boards'] && options?.enabled !== false,
    })
}

export const getBoardPlayer = async (boardId: string, playerId: string): Promise<DTO.BoardPlayer | ''> =>
    (await get<DTO.BoardPlayer | ''>(`/boards/${boardId}/${playerId}/board-player`)).data

export const useGetBoardPlayer = (
    boardId: string | undefined,
    playerId: string | undefined,
    options?: Omit<
        UseQueryOptions<DTO.BoardPlayer | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.BoardPlayer | ''> => {
    const { data: session } = useSession()
    return useQuery({
        queryKey: ['boardPlayer', boardId, playerId],
        queryFn: () => getBoardPlayer(boardId!, playerId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled:
            !!boardId &&
            !!playerId &&
            !!session?.roles.featurePermissions['custom-boards'] &&
            options?.enabled !== false,
    })
}

export const getBoardTiers = async (boardId: string): Promise<DTO.BoardTier[]> =>
    (await get<DTO.BoardTier[]>(`/boards/${boardId}/tiers`)).data

export const useGetBoardTers = (
    boardId: string | undefined,
    options?: Omit<UseQueryOptions<DTO.BoardTier[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.BoardTier[]> => {
    const { data: session } = useSession()
    return useQuery({
        queryKey: ['boardTiers', boardId],
        queryFn: () => getBoardTiers(boardId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!boardId && !!session?.roles.featurePermissions['custom-boards'] && options?.enabled !== false,
    })
}

export const useUpdateBoardPlayerNotes = (
    boardId: string,
    selectedColumns: Enum.BoardColumns[] | undefined,
    boardPlayerId: string,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse<DTO.BoardPlayer>, Error, DTO.BoardPlayer> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    return useMutation({
        mutationFn: (updatedBoardPlayer: DTO.BoardPlayer) =>
            put<DTO.BoardPlayer, DTO.BoardPlayer>(
                `/boards/${boardId}/notes/player/${boardPlayerId}`,
                updatedBoardPlayer
            ),
        onMutate: (updatedBoardPlayer: DTO.BoardPlayer) => {
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(
                getBoardDataQueryKey(boardId, modules)
            )
            if (previousBoardData) {
                const updatedBoardData: DTO.BoardData = {
                    ...previousBoardData,
                    items: previousBoardData.items.map((i) => {
                        if (i.type === 'player' && i.id === updatedBoardPlayer.id) {
                            return { ...i, notes: updatedBoardPlayer.notes }
                        }
                        return i
                    }),
                }
                queryClient.setQueryData(getBoardDataQueryKey(boardId, modules), updatedBoardData)
            }
            return { previousBoardData }
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(getBoardDataQueryKey(boardId, modules), context?.previousBoardData)
        },
        onSuccess: () => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Saved Changes',
                duration: 1000,
            })
        },
    })
}

export const useUpdateBoardTeamNotes = (
    boardId: string,
    selectedColumns: Enum.BoardColumns[] | undefined,
    teamId: string,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse<DTO.BoardTeam>, Error, DTO.BoardTeam> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    return useMutation({
        mutationFn: (updatedBoardTeam: DTO.BoardTeam) =>
            put<DTO.BoardTeam, DTO.BoardTeam>(`/boards/${boardId}/notes/team/${teamId}`, updatedBoardTeam),
        onMutate: (updatedBoardTeam: DTO.BoardTeam) => {
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(
                getBoardDataQueryKey(boardId, modules)
            )
            if (previousBoardData) {
                const updatedBoardData: DTO.BoardData = {
                    ...previousBoardData,
                    items: previousBoardData.items.map((i) => {
                        if (i.type === 'team' && i.id === updatedBoardTeam.id) {
                            return { ...i, notes: updatedBoardTeam.notes }
                        }
                        return i
                    }),
                }
                queryClient.setQueryData(getBoardDataQueryKey(boardId, modules), updatedBoardData)
            }
            return { previousBoardData }
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(getBoardDataQueryKey(boardId, modules), context?.previousBoardData)
        },
        onSuccess: () => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Saved Changes',
                duration: 1000,
            })
        },
    })
}

export const useMovePlayer = ({
    boardId,
    selectedColumns,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse, Error, DTO.MovePlayerInput> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    const queryKey = getBoardDataQueryKey(boardId, modules)
    return useMutation({
        mutationFn: ({ position, id, entityId }: DTO.MovePlayerInput) =>
            put(`/boards/${boardId}/players`, { position, id, entityId }, undefined, {
                'If-Match': version,
            }),
        onMutate: (player: DTO.MovePlayerInput) => {
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
            let updatedBoardData: DTO.BoardData | undefined
            if (previousBoardData) {
                updatedBoardData = {
                    ...previousBoardData,
                    items: moveItemAndRecalculatePositionsAndRanks(
                        { ...player, type: 'player' },
                        previousBoardData.items,
                        previousBoardData.consensusType === 'tier'
                    ),
                }
            }
            return { previousBoardData, updatedBoardData }
        },
        onSuccess: ({ headers }, _, context) => {
            const newVersion = headers.get('etag')
            if (typeof newVersion === 'string' && context.updatedBoardData) {
                queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
            }
        },
        onError: async (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.previousBoardData)
            await queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) })
        },
    })
}
export const useMoveTeam = ({
    boardId,
    selectedColumns,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse, Error, DTO.MoveTeamInput> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    const queryKey = getBoardDataQueryKey(boardId, modules)
    return useMutation({
        mutationFn: ({ position, id, entityId }: DTO.MoveTeamInput) =>
            put(`/boards/${boardId}/teams`, { position, id, entityId }, undefined, {
                'If-Match': version,
            }),
        onMutate: (team: DTO.MoveTeamInput) => {
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
            let updatedBoardData: DTO.BoardData | undefined
            if (previousBoardData) {
                updatedBoardData = {
                    ...previousBoardData,
                    items: moveItemAndRecalculatePositionsAndRanks(
                        { ...team, type: 'team' },
                        previousBoardData.items,
                        previousBoardData.consensusType === 'tier'
                    ),
                }
            }
            return { previousBoardData, updatedBoardData }
        },
        onSuccess: ({ headers }, _, context) => {
            const newVersion = headers.get('etag')
            if (typeof newVersion === 'string' && context.updatedBoardData) {
                queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
            }
        },
        onError: async (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.previousBoardData)
            await queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) })
        },
    })
}
export const useMoveTier = ({
    boardId,
    selectedColumns,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse, Error, DTO.MoveTierInput> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    const queryKey = getBoardDataQueryKey(boardId, modules)
    return useMutation({
        mutationFn: ({ position, id }: DTO.MoveTierInput) =>
            put(`/boards/${boardId}/tiers`, { position, id }, undefined, {
                'If-Match': version,
            }),
        onMutate: (tier: DTO.MoveTierInput) => {
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
            let updatedBoardData: DTO.BoardData | undefined
            if (previousBoardData) {
                updatedBoardData = {
                    ...previousBoardData,
                    items: moveItemAndRecalculatePositionsAndRanks(
                        { ...tier, type: 'tier' },
                        previousBoardData.items,
                        previousBoardData.consensusType === 'tier'
                    ),
                }
            }
            return { previousBoardData, updatedBoardData }
        },
        onSuccess: ({ headers }, _, context) => {
            const newVersion = headers.get('etag')
            if (typeof newVersion === 'string' && context.updatedBoardData) {
                queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
            }
        },
        onError: async (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.previousBoardData)
            await queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) })
        },
    })
}

export const useMarkRanked = (
    boardType: Enum.BoardEntityType,
    { boardId, selectedColumns, version }: UpdateBoardParams,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    const queryKey = getBoardDataQueryKey(boardId, modules)
    const itemType: DTO.BoardData['items'][number]['type'] = boardType === 'TEAM' ? 'team' : 'player'
    return useMutation({
        mutationFn: (itemId: string) =>
            post(`/boards/${boardId}/${itemType}s/${itemId}/rank`, undefined, undefined, {
                'If-Match': version,
            }),

        onMutate: (itemId: string) => {
            let updatedBoardData: DTO.BoardData | undefined
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
            if (previousBoardData) {
                updatedBoardData = {
                    ...previousBoardData,
                    items: previousBoardData.items.map((i) => {
                        if (i.type === itemType && i.id === itemId) {
                            return { ...i, isRanked: true }
                        }
                        return i
                    }),
                }
                queryClient.setQueryData(queryKey, updatedBoardData)
            }
            return { previousBoardData, updatedBoardData }
        },

        onSuccess: ({ headers }, _, context) => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Saved Changes',
                duration: 1000,
            })

            const newVersion = headers.get('etag')
            if (typeof newVersion === 'string' && context.updatedBoardData) {
                queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
            }
        },

        onError: async (err, _, context) => {
            toastContext?.addToast({
                severity: 'error',
                message: err.message,
            })

            queryClient.setQueryData(queryKey, context?.previousBoardData)
            await queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) })
        },
    })
}

type DeleteItemVariables = { id: string; type: DTO.BoardData['items'][number]['type'] }
export type DeleteBoardItemFn = (variables: DeleteItemVariables) => Promise<JSONResponse>

export const useDeleteItem = (
    { boardId, selectedColumns, version }: UpdateBoardParams,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse, Error, DeleteItemVariables> => {
    const queryClient = useQueryClient()
    const modules = selectedColumns && getSelectedModules(selectedColumns)
    const queryKey = getBoardDataQueryKey(boardId, modules)
    const mutate: DeleteBoardItemFn = ({ id, type }: DeleteItemVariables) =>
        remove(`/boards/${boardId}/${type}s/${id}`, undefined, {
            'If-Match': version,
        })
    return useMutation({
        mutationFn: mutate,

        onMutate: ({ id }) => {
            let updatedBoardData: DTO.BoardData | undefined
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
            if (previousBoardData) {
                updatedBoardData = {
                    ...previousBoardData,
                    items: recalculatePositionsAndRanks(
                        previousBoardData.items.filter((i) => i.id !== id),
                        previousBoardData.consensusType === 'tier'
                    ),
                }
                queryClient.setQueryData(queryKey, updatedBoardData)
            }
            return { previousBoardData, updatedBoardData }
        },

        onSuccess: ({ headers }, _, context) => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Deleted Item',
                duration: 1000,
            })

            const newVersion = headers.get('etag')
            if (typeof newVersion === 'string' && context.updatedBoardData) {
                queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
            }
        },

        onError: async (err, _, context) => {
            toastContext?.addToast({
                severity: 'error',
                message: err.message,
            })

            queryClient.setQueryData(queryKey, context?.previousBoardData)
            await queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) })
        },
    })
}

export const useSQLQuery = ({
    boardId,
    selectedColumns,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<DTO.SQLQueryResp>, Error, DTO.SQLQuery> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (values: DTO.SQLQuery) =>
            post<DTO.SQLQueryResp, DTO.SQLQuery>(`/boards/${boardId}/script-query`, values, undefined, {
                'If-Match': version,
            }),
        onSuccess: (data) => {
            const newVersion = data.headers.get('etag')
            if (typeof newVersion === 'string') {
                const modules = selectedColumns && getSelectedModules(selectedColumns)
                const queryKey = getBoardDataQueryKey(boardId, modules)
                const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
                queryClient.setQueryData(queryKey, { ...previousBoardData, version: newVersion })
            }
        },
    })
}

export const useSQLImport = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse, Error, DTO.SQLImport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ doNotAdd, doNotDelete, sqlScript, serverPassword }: DTO.SQLImport) =>
            post<unknown, DTO.SQLImport>(
                `/boards/${boardId}/script-import`,
                { sqlScript, serverPassword, doNotAdd, doNotDelete },
                undefined,
                {
                    'If-Match': version,
                }
            ),

        onSettled: () => queryClient.invalidateQueries({ queryKey: getBoardDataQueryKey(boardId) }),
    })
}

export const boardModuleLabels: Readonly<Record<Enum.BoardColumnModules, string>> = {
    BIO: 'Bio',
    REPRESENTATION: 'Representation',
    CONTRACT: 'Contract',
    DRAFT: 'Draft',
    LOCS: 'LOCs',
    SKILLS_SCOUTING: 'Scouting Skills',
    SKILLS_INSIGHTS: 'Insights Skills',
    SKILLS_BLEND: 'Blended Skills',
    SCOUTING: 'Scouting',
    INTEL: 'Intel',
    INTENSITY_GRADES: 'Intensity Grades',
    ROSTER_LIKELIHOOD: 'Roster Likelihood',
    TNDC: 'TNDC',
    NOTES: 'Notes',
    STANDINGS: 'Standings',
    INJURY: 'Injury',
    STATS: 'Stats',
} as const

export type ModuleExpandedList<T extends string, T2 extends string> = {
    name: T2
    title: string
    columns: DTO.ModuleSelect<T, T2>[]
}

export function useModuleColumns<T extends string, T2 extends string>({
    filteredColumns,
    moduleLabels,
}: {
    filteredColumns: DTO.ModuleSelect<T, T2>[] | undefined
    moduleLabels: Readonly<Record<T2, string>>
}): [Record<T2, ModuleExpandedList<T, T2> & { order: number }>, [string, ModuleExpandedList<T, T2>][]] {
    return useMemo(() => {
        let order = 0
        const checkedGroupedColumns = (filteredColumns || []).reduce(
            (acc, x) => {
                const { module } = x
                if (!(module in acc)) {
                    acc[module] = {
                        order,
                        name: module,
                        title: moduleLabels[module],
                        columns: [] as DTO.ModuleSelect<T, T2>[],
                    }
                    order += 1
                }
                acc[module].columns.push(x)
                return acc
            },
            {} as Record<T2, ModuleExpandedList<T, T2> & { order: number }>
        )
        const entries: [string, ModuleExpandedList<T, T2> & { order: number }][] = Object.entries(checkedGroupedColumns)
        const sortedColumns = entries.sort((a, b) => a[1].order - b[1].order)
        return [checkedGroupedColumns, sortedColumns]
    }, [filteredColumns, moduleLabels])
}

export const useConsensusBoard = (
    boardId: string | undefined,
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult =>
    useQuery({
        queryKey: ['consensus-board', boardId],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: async () => (await post<undefined, undefined>(`/boards/${boardId!}/consensus`, undefined)).data,
        ...options,
        enabled: !!boardId && options?.enabled !== false,
    })
