import type { UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

type TeamLeagueQueryParam = DTO.Team['leagueId'] | DTO.Team['leagueId'][]

export const queryKey = (league: TeamLeagueQueryParam, conference: string | null | undefined): QueryKey => [
    'teams',
    league,
    conference,
]

const queryTeams = async (league?: TeamLeagueQueryParam, conference?: string): Promise<DTO.Team[]> =>
    (await get<DTO.Team[]>('/teams', { league, conference })).data

const useTeams = (league?: TeamLeagueQueryParam, conference?: string | null): UseQueryResult<DTO.Team[]> =>
    useQuery({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryKey: queryKey(league!, conference),
        queryFn: () => queryTeams(league, conference || undefined),
        enabled: !!league,
    })

export default useTeams
