export const cacheTime = (cache: number | boolean | 'hour' | 'day'): number => {
    let cacheTimeSeconds = 0
    if (typeof cache === 'number') cacheTimeSeconds = cache
    else if (cache === true || cache === 'hour') cacheTimeSeconds = 60 * 60
    else if (cache === 'day') cacheTimeSeconds = 60 * 60 * 24

    return cacheTimeSeconds
}

export const api = {
    '/constants': cacheTime('hour'),
    '/teams/:teamSlug': cacheTime('day'),
    '/players/:playerSlug/name': cacheTime('day'),
    '/users/by-id/:userId': cacheTime('hour'),
    '/agencies/:slug': cacheTime('day'),
    '/standings/:league': cacheTime(1800),
    '/injury/current-injuries': cacheTime(1800),
    '/depth-charts/teams/:league': cacheTime('day'),
    '/profile/loc-values': cacheTime('day'),
    '/profile/:playerId/:season/:level/loc-projections': cacheTime('hour'),
    '/boards/:boardId/info': cacheTime(300),
    '/depth-charts/:depthChartId/info': cacheTime(300),
    '/entities/:entityType/:urlSlug': cacheTime('day'),
} as const
