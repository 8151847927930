import type { UseQueryResult, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, remove, post } from '../api'

export const useFeedOverrides = (): UseQueryResult<DTO.FeedOverride[]> =>
    useQuery({
        queryKey: ['feed-overrides'],
        queryFn: async () => (await get<DTO.FeedOverride[]>('/feed-overrides')).data
    })

export const useCreateFeedOverrides = (): UseMutationResult<JSONResponse, Error, DTO.CreateFeedOverride> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: DTO.CreateFeedOverride) => post('/feed-overrides', data),

        onSuccess: () => queryClient.invalidateQueries({
            queryKey: ['feed-overrides']
        })
    });
}

export type ToggleFeedOverride = DTO.FeedOverride & { active: boolean }
export const useToggleFeedOverrides = (): UseMutationResult<JSONResponse, Error, ToggleFeedOverride> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({
            active,
            userGroup: { userGroupId },
            type,
            player: { playerId },
            contentPermission: { contentPermissionId },
        }: ToggleFeedOverride) => {
            const route = `/feed-overrides/${userGroupId}/${type}/${playerId}/${contentPermissionId}`
            return active ? post<undefined, undefined>(route, undefined) : remove(route)
        },

        onSuccess: () => queryClient.invalidateQueries({
            queryKey: ['feed-overrides']
        })
    });
}
