import type { Except } from 'type-fest'
import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

type ThreePointModelOrEmpty = DTO.ThreePointModel | ''
const use3ptModel = (
    playerId: string | undefined,
    season: number,
    options?: Except<UseQueryOptions<ThreePointModelOrEmpty>, 'queryKey' | 'queryFn'>
): UseQueryResult<ThreePointModelOrEmpty> =>
    useQuery({
        queryKey: ['3pt-model', playerId, season],

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        queryFn: async () => (await get<ThreePointModelOrEmpty>(`/profile/${playerId}/${season}/3pt-model`)).data,

        ...options,
        enabled: !!playerId && !!season && options?.enabled !== false,
    })

export default use3ptModel
