import type { UseQueryResult, UseQueryOptions, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse, RequestParams } from '../api'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { get, post, put, serverGet } from '../api'

type QueryKey = ['users'] | ['usersWithPermissions']

export const usersQueryKey: QueryKey = ['users']
export const usersWithPermissionsQueryKey: QueryKey = ['usersWithPermissions']

const apiPath = '/users'

export type UserQueryParams = RequestParams & {
    includePermissions?: string
    includeInactiveUsers?: boolean
}

export const queryUsers = async <T>(params: UserQueryParams = {}): Promise<T[]> =>
    (await get<T[]>(apiPath, params)).data

export const useQueryUsers = <T>({
    queryKey,
    params = {},
    options,
}: {
    queryKey: QueryKey
    params?: UserQueryParams
    options?: Omit<UseQueryOptions<T[]>, 'queryKey' | 'queryFn'>
}): UseQueryResult<T[]> =>
    useQuery({
        queryKey: [...queryKey, params.includeInactiveUsers, params.includePermissions],
        queryFn: () => queryUsers<T>(params),
        ...options,
    })

export const staticGetUserByEmail = async (email: string): Promise<DTO.UserWithPermissions> =>
    (await serverGet<DTO.UserWithPermissions>(`${apiPath}/by-email/${email}`, {})).data

export const staticGetUserById = async (userId: number): Promise<DTO.UserWithSqlUsername> =>
    (
        await serverGet<DTO.UserWithSqlUsername>(`${apiPath}/by-id/${userId}`, {
            apiCacheKey: '/users/by-id/:userId',
            cacheTag: `user-${userId}`,
        })
    ).data

export const useCreateUser = (queryKey: QueryKey): UseMutationResult<JSONResponse<DTO.User>, Error, Params.User> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (newUser: Params.User) => post<DTO.User, Params.User>(apiPath, newUser),
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    })
}

export const useUpdateUser = (queryKey: QueryKey): UseMutationResult<JSONResponse<DTO.User>, Error, Params.User> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (updatedUser: Params.User) =>
            put<DTO.User, Params.User>(`${apiPath}/${updatedUser.slug as string}`, updatedUser),

        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    })
}
