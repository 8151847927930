import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { get } from '../api'

export type PlayerContractHistoryQueryParams = {
    playerId?: string
    agentId?: string
    agencyId?: string
    activeOnly?: boolean
}

const queryPlayerContractHistory = async ({
    playerId,
    agentId,
    agencyId,
}: PlayerContractHistoryQueryParams): Promise<DTO.PlayerContractHistory[]> =>
    (await get<DTO.PlayerContractHistory[]>('/player-contracts/contract-history', { playerId, agentId, agencyId })).data

export const useQueryPlayerContractHistory = ({
    playerId,
    agentId,
    agencyId,
}: PlayerContractHistoryQueryParams): UseQueryResult<DTO.PlayerContractHistory[]> =>
    useQuery({
        queryKey: ['playerContractHistory', playerId, agentId, agencyId],

        queryFn: () =>
            queryPlayerContractHistory({ playerId, agentId, agencyId })
    })

const queryPlayerContractsDetails = async ({
    playerId,
    agentId,
    agencyId,
    activeOnly,
}: PlayerContractHistoryQueryParams): Promise<DTO.PlayerContractDetails[]> =>
    (
        await get<DTO.PlayerContractDetails[]>(`/player-contracts/contract-details`, {
            playerId,
            agentId,
            agencyId,
            activeOnly,
        })
    ).data

export const useQueryPlayerContractsDetails = ({
    playerId,
    agentId,
    agencyId,
    activeOnly,
    options,
}: PlayerContractHistoryQueryParams & {
    options?: Omit<UseQueryOptions<DTO.PlayerContractDetails[]>, 'queryKey' | 'queryFn'>
}): UseQueryResult<DTO.PlayerContractDetails[]> => {
    const { data: session } = useSession()
    return useQuery({
        queryKey: ['playerContractDetails', { playerId, agentId, agencyId, activeOnly }],
        queryFn: () => queryPlayerContractsDetails({ playerId, agentId, agencyId, activeOnly }),
        ...options,

        enabled:
            !!(playerId || agentId || agencyId) &&
            !!session?.roles.featurePermissions.contracts &&
            options?.enabled !== false
    });
}
