import type { JSONResponse } from '../api'
import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { Except } from 'type-fest'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, post } from '../api'
import { useConstantsContext } from '../contexts/ConstantsContext'
import { clearServerCache } from 'app/actions'

const apiPath = '/player-personal'

export type PlayerPersonalQueryParams = {
    playerId: string | undefined
    options?: Omit<
        UseQueryOptions<DTO.PlayerPersonal[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}

export type CreateOrUpdatePlayerPersonalValues = Except<
    DTO.CreatePlayerPersonal,
    'playerId' | 'isOverride' | 'updatedBy'
>

const queryPlayerPersonal = async (playerId: string): Promise<DTO.PlayerPersonal[]> =>
    (await get<DTO.PlayerPersonal[]>(`${apiPath}/${playerId}`)).data

export const useQueryPlayerPersonal = ({
    playerId,
    options,
}: PlayerPersonalQueryParams): UseQueryResult<DTO.PlayerPersonal[]> =>
    useQuery({
        queryKey: ['playerPersonal', playerId],
        queryFn: () => queryPlayerPersonal(playerId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!playerId && options?.enabled !== false,
    })

export const useCreateOrUpdatePlayerPersonal = (
    playerId: string,
    playerSlug: string
): UseMutationResult<JSONResponse<CreateOrUpdatePlayerPersonalValues>, Error, CreateOrUpdatePlayerPersonalValues> => {
    const queryClient = useQueryClient()
    const { salaryYear } = useConstantsContext()
    return useMutation({
        mutationFn: (values: CreateOrUpdatePlayerPersonalValues) =>
            post<CreateOrUpdatePlayerPersonalValues, CreateOrUpdatePlayerPersonalValues>(
                `${apiPath}/${playerId}`,
                values
            ),

        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['playerPersonal', playerId],
            })
            await queryClient.invalidateQueries({
                queryKey: ['players', playerSlug],
            })
            await queryClient.invalidateQueries({
                queryKey: ['PlayerBio', playerId, salaryYear],
            })
            await queryClient.invalidateQueries({
                queryKey: ['search'],
            })
            clearServerCache(`player-name-${playerSlug}`)
        },
    })
}
