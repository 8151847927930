import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const apiPath = '/player-development-tags'

export const queryPlayerDevelopmentTags = async (): Promise<DTO.PlayerDevelopmentTag[]> =>
    (await get<DTO.PlayerDevelopmentTag[]>(apiPath)).data

const usePlayerDevelopmentTags = (): UseQueryResult<DTO.PlayerDevelopmentTag[]> =>
    useQuery({
        queryKey: ['player-development-tags'],
        queryFn: () => queryPlayerDevelopmentTags()
    })

export default usePlayerDevelopmentTags
