import type { UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import type { Except } from 'type-fest'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { put, post } from '../api'

export type PlayerRelationshipCreateValues = Except<DTO.PlayerRelationship, 'id'>

export const useCreatePlayerRelationship = (
    playerId: string
): UseMutationResult<JSONResponse<PlayerRelationshipCreateValues>, Error, PlayerRelationshipCreateValues> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (values: PlayerRelationshipCreateValues) =>
            post<PlayerRelationshipCreateValues, PlayerRelationshipCreateValues>('/player-relationships', values),

        onSuccess: async () => queryClient.invalidateQueries({
            queryKey: ['playerPersonal', playerId]
        })
    });
}

export const useUpdatePlayerRelationship = (
    playerId: string
): UseMutationResult<JSONResponse<DTO.PlayerRelationship>, Error, DTO.PlayerRelationship> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (updatedPlayerRelationship: DTO.PlayerRelationship) =>
            put<DTO.PlayerRelationship, DTO.PlayerRelationship>(
                `/player-relationships/${updatedPlayerRelationship.id}`,
                updatedPlayerRelationship
            ),

        onSuccess: async () => queryClient.invalidateQueries({
            queryKey: ['playerPersonal', playerId]
        })
    });
}
