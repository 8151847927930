import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const apiPath = '/mock-sources'

const queryMockSources = async () => (await get<DTO.MockSource[]>(`${apiPath}`)).data
export const useQueryMockSources = (): UseQueryResult<DTO.MockSource[]> =>
    useQuery({
        queryKey: ['mock-sources'],
        queryFn: () => queryMockSources()
    })
