import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { get, post, put } from '../api'

type ContentPermissionParams = {
    displayName: string
    status: string
}

type ContentPermissionUpdateParams = ContentPermissionParams & { slug: string }

type QueryKey = ['contentPermissions']

export const contentPermissionsQueryKey: QueryKey = ['contentPermissions']

const apiPath = '/content-permissions'

export const queryContentPermissions = async (): Promise<DTO.ContentPermission> =>
    (await get<DTO.ContentPermission>(apiPath)).data

export const useQueryContentPermissions = ({
    queryKey,
}: {
    queryKey: QueryKey
}): UseQueryResult<DTO.ContentPermission[]> =>
    useQuery({
        queryKey,
        queryFn: () => queryContentPermissions(),
    })

export const useCreateContentPermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<DTO.ContentPermission>, Error, ContentPermissionParams> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (newContentPermission: ContentPermissionParams) =>
            post<DTO.ContentPermission, ContentPermissionParams>(apiPath, newContentPermission),

        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    })
}

export const useUpdateContentPermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<DTO.ContentPermission>, Error, ContentPermissionUpdateParams> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (updatedContentPermission: ContentPermissionUpdateParams) =>
            put<DTO.ContentPermission, ContentPermissionUpdateParams>(
                `${apiPath}/${updatedContentPermission.slug}`,
                updatedContentPermission
            ),

        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    })
}
