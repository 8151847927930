import type { UseQueryOptions, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const usePlayerBio = (
    playerId: string | null | undefined,
    season: number,
    options?: Omit<
        UseQueryOptions<DTO.PlayerBio, Error, DTO.PlayerBio, QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerBio> =>
    useQuery({
        queryKey: ['PlayerBio', playerId, season],

        queryFn: // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        async () => (await get<DTO.PlayerBio>(`/profile/${playerId}/${season}/bio`)).data,

        ...options,
        enabled: !!playerId && !!season && options?.enabled !== false
    })

export default usePlayerBio
