import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet } from '../api'

type EntityParams = {
    teamId?: string | null
    entityType?: DTO.SearchEntityType
}

export const entityCacheTag = (entityType: DTO.SearchEntityType, urlSlug: string): string =>
    `entities-${entityType}-${urlSlug}`
export const serverGetEntityByUrlSlug = async (
    entityType: DTO.SearchEntityType,
    urlSlug: string
): Promise<DTO.Entity> =>
    (
        await serverGet<DTO.Entity>(`/entities/${entityType}/${urlSlug}`, {
            apiCacheKey: '/entities/:entityType/:urlSlug',
            cacheTag: entityCacheTag(entityType, urlSlug),
        })
    ).data

export const getEntityByUrlSlug = async (entityType: DTO.SearchEntityType, urlSlug: string): Promise<DTO.Entity> =>
    (await get<DTO.Entity>(`/entities/${entityType}/${urlSlug}`)).data

export const useGetEntityByUrlSlug = (
    entityType: DTO.SearchEntityType,
    urlSlug: string | undefined | null,
    options?: Omit<UseQueryOptions<DTO.Entity>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Entity> =>
    useQuery({
        queryKey: ['entities', entityType, urlSlug],
        queryFn: () => getEntityByUrlSlug(entityType, urlSlug!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!urlSlug && options?.enabled !== false,
        ...options,
    })

const queryEntities = async (params: EntityParams) => (await get<DTO.Entity[]>('/entities', params)).data

export const useQueryEntities = (
    params: EntityParams,
    options?: Omit<UseQueryOptions<DTO.Entity[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Entity[]> =>
    useQuery({
        queryKey: ['entities', params],
        queryFn: () => queryEntities(params),
        ...options,
        enabled: options?.enabled !== false,
    })
