import type { ParsedUrlQueryInput } from 'querystring'
import type { ReadonlyURLSearchParams } from 'next/navigation'

export function objectToURLSearchParams(obj: ParsedUrlQueryInput, includeEmptyValues = true): string {
    const params = new URLSearchParams()

    Object.keys(obj).forEach((key) => {
        if (Object.hasOwnProperty.call(obj, key)) {
            const value = obj[key]
            if (Array.isArray(value)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                value.forEach((v) => params.append(key, v))
            } else if (value !== undefined && value !== null && (includeEmptyValues || !!value)) {
                params.append(key, value.toString())
            }
        }
    })

    return params.toString()
}

export function URLSearchParamsToObject(searchParams: ReadonlyURLSearchParams | null): ParsedUrlQueryInput {
    if (searchParams === null) return {}
    const obj: ParsedUrlQueryInput = {}

    searchParams.forEach((value, key) => {
        if (obj[key] === undefined) {
            obj[key] = value
        } else if (Array.isArray(obj[key])) {
            ;(obj[key] as string[]).push(value)
        } else {
            obj[key] = [obj[key] as string, value]
        }
    })

    return obj
}
