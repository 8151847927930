import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { RequestParams } from '../api'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

export type MeasurementParams = RequestParams & {
    playerId: string
}

const queryMeasurements = async (params: MeasurementParams): Promise<DTO.MeasurementsTable> =>
    (await get<DTO.MeasurementsTable>(`/profile/${params.playerId}/measurements`, params)).data

const useMeasurements = (
    params: MeasurementParams,
    options?: Omit<UseQueryOptions<DTO.MeasurementsTable>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.MeasurementsTable> =>
    useQuery({
        queryKey: ['measurements', params.playerId],
        queryFn: () => queryMeasurements(params),
        ...options
    })

export type MearsurementPercentilesParams = RequestParams & {
    leagues: Enum.League[]
    season: number
    secondaryPosition?: DTO.PlayerSecondaryPosition
}

export const queryMeasurementPercentiles = async (
    params: MearsurementPercentilesParams
): Promise<DTO.MeasurementPercentileMap> =>
    (await get<DTO.MeasurementPercentileMap>(`/profile/measurement-percentiles`, params)).data

export const useMeasurementPercentiles = (
    params: MearsurementPercentilesParams,
    options?: Omit<UseQueryOptions<DTO.MeasurementPercentileMap>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.MeasurementPercentileMap> =>
    useQuery({
        queryKey: ['scouting-measurement-percentiles', params.secondaryPosition, params.season, params.leagues],
        queryFn: () => queryMeasurementPercentiles(params),
        ...options
    })

export default useMeasurements
