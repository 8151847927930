/* eslint-disable import/order */
import type { UseQueryResult, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse, RequestParams } from '../api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, post, put } from '../api'

export const scoutingEventsQueryKey = 'scouting-events' as const
const apiPath = '/scouting-events' as const

export type ScoutingEventQueryParams = RequestParams & {
    level?: Enum.ReportLevel
    eventId?: string
}

const queryScoutingEvents = async (params?: ScoutingEventQueryParams): Promise<DTO.ScoutingEvent[]> =>
    (await get<DTO.ScoutingEvent[]>(apiPath, params)).data

export const useScoutingEvents = (params?: ScoutingEventQueryParams): UseQueryResult<DTO.ScoutingEvent[]> =>
    useQuery({
        queryKey: [scoutingEventsQueryKey, params],
        queryFn: () => queryScoutingEvents(params),
    })

export const queryScoutingEventsById = async (eventId: string): Promise<DTO.ScoutingEvent> =>
    (await get<DTO.ScoutingEvent>(`${apiPath}/${eventId})`)).data

export const useScoutingEventById = (eventId: string | undefined): UseQueryResult<DTO.ScoutingEvent> =>
    useQuery({
        queryKey: [scoutingEventsQueryKey, eventId],
        queryFn: () => queryScoutingEventsById(eventId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!eventId,
    })

export const useUpdateScoutingEvent = (): UseMutationResult<JSONResponse, Error, DTO.ScoutingEvent> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (event: DTO.ScoutingEvent) => put(`${apiPath}/${event.scoutingEventId}`, event),
        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: [scoutingEventsQueryKey],
            }),
    })
}

export const useCreateScoutingEvent = (): UseMutationResult<JSONResponse, Error, DTO.ScoutingEvent> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (event: DTO.ScoutingEvent) => post(apiPath, event),
        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: [scoutingEventsQueryKey],
            }),
    })
}
