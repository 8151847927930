import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { JSONResponse, RequestParams } from '../api'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { get, post, put } from '../api'

type UserGroupParams = {
    users: string[]
    displayName: string
    status: string
}

type UserGroupUpdateParams = UserGroupParams & { slug: string }

type QueryKey = ['userGroups'] | ['userGroupsWithUsers'] | ['userGroupsWithPermissions']

export const userGroupsQueryKey: QueryKey = ['userGroups']
export const userGroupsWithUsersQueryKey: QueryKey = ['userGroupsWithUsers']
export const userGroupsWithPermissionsQueryKey: QueryKey = ['userGroupsWithPermissions']
const apiPath = '/user-groups'

export type UserGroupQueryParams = RequestParams & {
    includeUsers?: string
    includePermissions?: string
}

export const queryUserGroups = async <T>(params?: UserGroupQueryParams): Promise<T[]> =>
    (await get<T[]>(apiPath, params)).data

export const useQueryUserGroups = <T>({
    queryKey,
    params,
    options,
}: {
    queryKey: QueryKey
    params?: UserGroupQueryParams
    options?: Omit<UseQueryOptions<T[]>, 'queryKey'>
}): UseQueryResult<T[]> => {
    const { data: session } = useSession()
    const enabled = !!session?.roles.featurePermissions['site-management'] && options?.enabled !== false
    return useQuery({
        queryKey,
        queryFn: () => queryUserGroups<T>(params),
        ...options,
        enabled,
    })
}

export const useCreateUserGroup = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<DTO.UserGroup>, Error, UserGroupParams> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (newUserGroup: UserGroupParams) => post<DTO.UserGroup, UserGroupParams>(apiPath, newUserGroup),
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    })
}

export const useUpdateUserGroup = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<DTO.UserGroup>, Error, UserGroupUpdateParams> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (updatedUserGroup: UserGroupUpdateParams) =>
            put<DTO.UserGroup, UserGroupUpdateParams>(`${apiPath}/${updatedUserGroup.slug}`, updatedUserGroup),

        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    })
}
