import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const getPositionVersatilityPlayerSeason = async (
    playerId: string,
    season: number
): Promise<DTO.PositionVersatilitySeason | ''> =>
    (await get<DTO.PositionVersatilitySeason | ''>(`/profile/${playerId}/${season}/position-versatility`)).data

export const useGetPositionVersatilityPlayerSeason = ({
    playerId,
    season,
    options,
}: {
    playerId: string | null | undefined
    season: number | null | undefined
    options?: Omit<
        UseQueryOptions<DTO.PositionVersatilitySeason | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.PositionVersatilitySeason | ''> =>
    useQuery({
        queryKey: ['position-versatility', playerId, season],
        queryFn: () => getPositionVersatilityPlayerSeason(playerId!, season!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!playerId && !!season && options?.enabled !== false,
    })

const getPositionVersatilityForPastNinetyDays = async (
    playerId: string,
    scoutEntityId: string
): Promise<DTO.PositionVersatility | ''> =>
    (await get<DTO.PositionVersatility>(`/position-versatility/${playerId}/${scoutEntityId}/past-ninety-days`)).data

const useGetPositionVersatilityForPastNinetyDays = ({
    playerId,
    scoutEntityId,
    options,
}: {
    playerId: string | null
    scoutEntityId: string
    options: Omit<
        UseQueryOptions<DTO.PositionVersatility | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.PositionVersatility | ''> =>
    useQuery({
        queryKey: ['position-versatility', playerId, scoutEntityId],
        queryFn: () => getPositionVersatilityForPastNinetyDays(playerId!, scoutEntityId), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!playerId,
        ...options,
    })
export default useGetPositionVersatilityForPastNinetyDays
