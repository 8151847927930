import type { UserRolesToken, UserRoles } from '../definitions/auth'
import { featurePermissions, contentPermissions } from '../constants/permissions'

const mapPermissionTokenToObj = <T extends string>(
    permissions: (T | number)[],
    slugLk: Map<number, T>
): Record<T, true> => {
    // handle legacy token structure
    if (!Array.isArray(permissions)) return permissions

    return permissions.reduce((acc, p) => {
        const slug = typeof p === 'number' ? slugLk.get(p) : p
        if (slug) acc[slug] = true // handle edge case where user logs in after seed but before code deployment
        return acc
    }, {} as Record<T, true>)
}

export const featurePermissionSlugLookup = new Map(featurePermissions.map((f) => [f.id, f.slug]))
export const contentPermissionSlugLookup = new Map(contentPermissions.map((c) => [c.id, c.slug]))
export const mapUserRolesTokenToUserRoles = (userRolesToken: UserRolesToken): UserRoles => ({
    ...userRolesToken,
    featurePermissions: mapPermissionTokenToObj(userRolesToken.featurePermissions, featurePermissionSlugLookup),
    contentPermissions: mapPermissionTokenToObj(userRolesToken.contentPermissions, contentPermissionSlugLookup),
})
