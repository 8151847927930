import type { UseQueryResult, QueryKey, UseQueryOptions, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, post } from '../api'

export const queryKey = (): QueryKey => ['players']
const apiPath = (): string => '/players'

const queryPlayers = async (): Promise<DTO.Player[]> => (await get<DTO.Player[]>(apiPath())).data

const usePlayers = (): UseQueryResult<DTO.Player[]> => useQuery({ queryKey: queryKey(), queryFn: queryPlayers })

export const useNBAPlayers = (): UseQueryResult<DTO.DepthChartResp> =>
    useQuery({
        queryKey: ['nba-players'],
        queryFn: async () => (await get<DTO.DepthChartResp[]>(`/players?active=true&nba=true`)).data,
    })

const getPlayersByTeamId = async (teamId: string): Promise<DTO.TeamRosterPlayer[]> =>
    (await get<DTO.TeamRosterPlayer[]>(`/teams/${teamId}/roster`)).data

export const useGetPlayersByTeamId = (
    teamId: string | null | undefined,
    options?: Omit<
        UseQueryOptions<DTO.TeamRosterPlayer[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.TeamRosterPlayer[]> =>
    useQuery({
        queryKey: ['team-roster', teamId],
        queryFn: () => getPlayersByTeamId(teamId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!teamId && options?.enabled !== false,
    })

const getMissingNBAPlayers = async (): Promise<DTO.MissingNBAPlayer[]> =>
    (await get<DTO.MissingNBAPlayer[]>(`/players/missing-nba-players`)).data

export const useGetMissingNBAPlayers = (): UseQueryResult<DTO.MissingNBAPlayer[]> =>
    useQuery({
        queryKey: ['missing-nba-players'],
        queryFn: () => getMissingNBAPlayers(),
    })

export const useAddDXToNBAMapping = (): UseMutationResult<
    JSONResponse<DTO.AddDXNBAMapping>,
    Error,
    DTO.AddDXNBAMapping
> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (player: DTO.AddDXNBAMapping) =>
            post<DTO.AddDXNBAMapping, DTO.AddDXNBAMapping>(`/players/dx-to-nba/${player.nbaId}`, player),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['missing-nba-players'],
            })
        },
    })
}
export default usePlayers
