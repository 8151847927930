import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet } from '../api'

export const getStandingByTeamId = async (teamId: string, season: number): Promise<DTO.Standing[]> =>
    (await get<DTO.Standing[]>(`/standings/${teamId}/${season}`)).data

export const useGetStandingByTeamId = (
    teamId: string | null | undefined,
    season: number,
    options?: Omit<
        UseQueryOptions<DTO.Standing[], Error, DTO.Standing[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.Standing[]> =>
    useQuery({
        queryKey: ['team-standings', teamId, season],
        queryFn: () => getStandingByTeamId(teamId!, season), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!teamId && !!season,
        ...options,
    })

const getSimulations = async (
    season: string,
    league: Enum.League,
    projectionType: Enum.ProjectionType,
    gameType?: Enum.GameType
): Promise<DTO.Simulations[]> =>
    (
        await get<DTO.Simulations[]>(`/standings/simulations`, {
            season: projectionType === 'CURRENT' ? season.substring(0, 4) : season,
            league,
            projectionType,
            gameType,
        })
    ).data

export const useGetSimulations = (
    season: string | undefined,
    league: Enum.League | undefined,
    projectionType: Enum.ProjectionType | undefined,
    gameType?: Enum.GameType,
    options?: Omit<
        UseQueryOptions<DTO.Simulations[], Error, DTO.Simulations[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.Simulations[]> =>
    useQuery({
        queryKey: ['simulations', season, league, projectionType, gameType],
        queryFn: () => getSimulations(season!, league!, projectionType!, gameType), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!season && !!league && !!projectionType,
        ...options,
    })

export const serverGetLeagueStandings = async (
    season: string | undefined,
    date: string | undefined,
    league: Enum.League,
    gameType?: Enum.GameType
): Promise<DTO.Standing[]> =>
    (
        await serverGet<DTO.Standing[]>(`/standings/${league}`, {
            apiCacheKey: '/standings/:league',
            cacheTag: `standings-${league}`,
            featurePermissions: ['league-standings'],
            params: { season, date, gameType },
        })
    ).data

const getLeagueStandings = async (
    season: string | undefined,
    date: string | undefined,
    league: Enum.League,
    gameType?: Enum.GameType
): Promise<DTO.Standing[]> => (await get<DTO.Standing[]>(`/standings/${league}`, { season, date, gameType })).data

export const useGetLeagueStandings = (
    season: string | undefined,
    date: string | undefined,
    league: Enum.League,
    type?: Enum.GameType,
    options?: Omit<
        UseQueryOptions<DTO.Standing[], Error, DTO.Standing[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.Standing[]> =>
    useQuery({
        queryKey: ['standings', season, date, league, type],
        queryFn: () => getLeagueStandings(season, date, league, type),
        enabled: !!season && !!league,
        ...options,
    })

const getPlayoffMatchups = async (
    date: string,
    season: string,
    league: Enum.DraftLeague
): Promise<DTO.PlayoffMatchup[]> =>
    (await get<DTO.PlayoffMatchup[]>(`/standings/playoff-matchups`, { date, season, league })).data

export const useGetPlayoffMatchups = (
    date: string | null,
    season: string | null,
    league: Enum.DraftLeague | null,
    options?: Omit<
        UseQueryOptions<DTO.PlayoffMatchup[], Error, DTO.PlayoffMatchup[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayoffMatchup[]> =>
    useQuery({
        queryKey: ['matchups', date, season, league],
        queryFn: () => getPlayoffMatchups(date!, season!, league!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!date && !!season && !!league,
        ...options,
    })

const getPlayoffSeedings = async (
    league: Enum.League,
    projectionType: Enum.ProjectionType,
    season: string,
    conference: Enum.Conference,
    seasonType: Enum.SeasonType,
    date: string | undefined
): Promise<DTO.PlayoffSeeding[]> =>
    (
        await get<DTO.PlayoffSeeding[]>(`/standings/playoff-seedings`, {
            league,
            projectionType,
            season,
            conference,
            seasonType,
            date,
        })
    ).data

export const useGetPlayoffSeedings = (
    league: Enum.League | undefined,
    projectionType: Enum.ProjectionType | undefined,
    season: string | undefined,
    conference: Enum.Conference | undefined,
    seasonType: Enum.SeasonType | undefined,
    date: string | undefined,
    options?: Omit<
        UseQueryOptions<DTO.PlayoffSeeding[], Error, DTO.PlayoffSeeding[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayoffSeeding[]> =>
    useQuery({
        queryKey: ['seedings', league, projectionType, season, conference, date, seasonType],
        queryFn: () => getPlayoffSeedings(league!, projectionType!, season!, conference!, seasonType!, date), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!league && !!projectionType && !!season && !!conference && !!seasonType,
        ...options,
    })

export const getPredictiveStandings = async (
    league: Enum.League,
    projectionType: Enum.ProjectionType,
    season: string,
    seasonType: Enum.SeasonType,
    date: string,
    simId?: string | null,
    teamId?: string
): Promise<DTO.PredictiveStanding[]> =>
    (
        await get<DTO.PredictiveStanding[]>(`/standings/predictive`, {
            league,
            projectionType,
            season,
            seasonType,
            date,
            simId,
            teamId,
        })
    ).data

export const usePredictiveStandings = (
    league: Enum.League,
    projectionType: Enum.ProjectionType,
    season: string,
    seasonType: Enum.SeasonType,
    date: string,
    simId?: string | null,
    teamId?: string,
    options?: Omit<
        UseQueryOptions<DTO.PredictiveStanding[], Error, DTO.PredictiveStanding[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PredictiveStanding[]> =>
    useQuery({
        queryKey: ['predictive', league, projectionType, season, seasonType, date, simId, teamId],
        queryFn: () => getPredictiveStandings(league, projectionType, season, seasonType, date, simId, teamId),
        enabled: !!season && !!date && !!seasonType,
        ...options,
    })
