import type { UseQueryResult, UseQueryOptions, QueryKey, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, post, remove } from '../api'

export type PracticeShotsResp = [DTO.PracticeShots[], DTO.PracticeShotAvgs, DTO.NoahShootingSummary | null]
export const getPracticeShotsForDraftWorkout = async (playerId: string, draft: number): Promise<PracticeShotsResp> =>
    (await get<PracticeShotsResp>(`/draft-profile/${playerId}/practice-shots`, { playerId, draft })).data

export const useGetPracticeShotsForDraftWorkout = (
    playerId?: string,
    draft?: number,
    options?: Omit<UseQueryOptions<PracticeShotsResp, Error, PracticeShotsResp, QueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<PracticeShotsResp> =>
    useQuery({
        queryKey: ['practice-shots', playerId, draft],
        queryFn: () => getPracticeShotsForDraftWorkout(playerId!, draft!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!playerId && !!draft && options?.enabled !== false,
    })

export const getDraftInterview = async (playerId: string, draft: number): Promise<DTO.DraftInterview[]> =>
    (await get<DTO.DraftInterview[]>(`/draft-profile/${playerId}/draft-interview`, { playerId, draft })).data

export const useGetDraftInterview = (
    playerId?: string,
    draft?: number,
    options?: Omit<UseQueryOptions<DTO.DraftInterview[], Error, DTO.DraftInterview[], QueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.DraftInterview[]> =>
    useQuery({
        queryKey: ['draft-interview', playerId, draft],
        queryFn: () => getDraftInterview(playerId!, draft!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!playerId && !!draft && options?.enabled !== false,
    })

export const getDraftWorkoutInfo = async (playerId: string, draft: number): Promise<DTO.DraftProfileWorkoutTracker[]> =>
    (await get<DTO.DraftProfileWorkoutTracker[]>(`/draft-profile/${playerId}/draft-workouts`, { playerId, draft })).data

export const useGetDraftWorkoutInfo = (
    playerId?: string,
    draft?: number,
    options?: Omit<
        UseQueryOptions<DTO.DraftProfileWorkoutTracker[], Error, DTO.DraftProfileWorkoutTracker[], QueryKey>,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.DraftProfileWorkoutTracker[]> =>
    useQuery({
        queryKey: ['draft-workouts', playerId, draft],
        queryFn: () => getDraftWorkoutInfo(playerId!, draft!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!playerId && !!draft && options?.enabled !== false,
    })

export const getDraftStaffNotes = async (
    playerId: string,
    draft: number,
    noteTypes: DTO.StaffNoteType[]
): Promise<DTO.DraftStaffNote[]> =>
    (await get<DTO.DraftStaffNote[]>(`/draft-profile/${playerId}/staff-notes`, { playerId, draft, noteTypes })).data

export const useGetDraftStaffNotes = (
    playerId: string | undefined,
    draft: number | undefined,
    noteTypes: DTO.StaffNoteType[] | undefined,
    options?: Omit<UseQueryOptions<DTO.DraftStaffNote[], Error, DTO.DraftStaffNote[], QueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.DraftStaffNote[]> =>
    useQuery({
        queryKey: ['staff-notes', playerId, draft, noteTypes],
        queryFn: () => getDraftStaffNotes(playerId!, draft!, noteTypes!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!playerId && !!draft && !!noteTypes?.length && options?.enabled !== false,
    })

export const useSaveDraftStaffNote = (): UseMutationResult<JSONResponse, Error, DTO.SaveDraftStaffNote> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (note: DTO.SaveDraftStaffNote) =>
            post<undefined, DTO.SaveDraftStaffNote>(`/draft-profile/${note.staffNoteId}/staff-notes`, note),

        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['staff-notes'],
            })
            void queryClient.invalidateQueries({
                queryKey: ['search'],
            })
        },
    })
}

export const useAIQTesting = (
    playerId: string | undefined,
    options?: Omit<UseQueryOptions<DTO.AIQTesting | '', Error, DTO.AIQTesting | ''>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AIQTesting | ''> =>
    useQuery({
        queryKey: ['aiq-testing', playerId],

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: async () => (await get<DTO.AIQTesting | ''>(`/draft-profile/${playerId!}/aiq`)).data,

        ...options,
        enabled: !!playerId && options?.enabled !== false,
    })
export const useDeleteDraftStaffNote = (): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (staffNoteId: string) => remove(`/draft-profile/${staffNoteId}/staff-notes`),

        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: ['staff-notes'],
            }),
    })
}

export const getDraftWorkoutShooting = async (
    playerId: string,
    draft: number
): Promise<DTO.DraftWorkoutShooting | ''> =>
    (await get<DTO.DraftWorkoutShooting | ''>(`/draft-profile/${playerId}/shooting`, { playerId, draft })).data

export const useGetDraftWorkoutShooting = (
    playerId?: string,
    draft?: number,
    options?: Omit<UseQueryOptions<DTO.DraftWorkoutShooting | ''>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.DraftWorkoutShooting | ''> =>
    useQuery({
        queryKey: ['draft-workout-shooting', playerId, draft],
        queryFn: () => getDraftWorkoutShooting(playerId!, draft!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!playerId && !!draft && options?.enabled !== false,
    })
