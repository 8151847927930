import type { JSONResponse } from '../api'
import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { BoardPlayersQueryParams } from './useGames'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import { post, get, put } from '../api'

export const useAddAssignment = ({
    params,
    boardParams,
}: {
    params?: ScoutAssignmentQueryParams
    boardParams?: BoardPlayersQueryParams
}): UseMutationResult<JSONResponse, Error, DTO.AddAssignment[]> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (assignments: DTO.AddAssignment[]) =>
            post<undefined, DTO.AddAssignment[]>('/scout-assignments', assignments),

        onSettled: async () => {
            void queryClient.invalidateQueries({
                queryKey: ['board-players', boardParams],
            })
            await queryClient.invalidateQueries({
                queryKey: ['assignment-widget'],
            })
            await queryClient.invalidateQueries({
                queryKey: ['scout-assignments-calendar', params],
            })
        },
    })
}

export const useAddEventGames = ({
    params,
    boardParams,
}: {
    params?: ScoutAssignmentQueryParams
    boardParams?: BoardPlayersQueryParams
}): UseMutationResult<JSONResponse, Error, DTO.AddAssignment[]> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (assignments: DTO.AddAssignment[]) =>
            post<undefined, DTO.AddAssignment[]>('/scout-assignments', assignments),

        onSettled: async () => {
            void queryClient.invalidateQueries({
                queryKey: ['board-players', boardParams],
            })
            await queryClient.invalidateQueries({
                queryKey: ['assignment-widget'],
            })
            await queryClient.invalidateQueries({
                queryKey: ['scout-assignments-calendar', params],
            })
        },
    })
}

export const useAddUnrequiredReport = ({
    incompleteReportParams,
    filterParams,
}: {
    incompleteReportParams: IncompleteReportsQueryParams
    filterParams: ScoutAssignmentQueryParams
}): UseMutationResult<JSONResponse, Error, DTO.UnrequiredReport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: DTO.UnrequiredReport) =>
            post<undefined, DTO.UnrequiredReport>('/scout-assignments/unrequired', report),

        onSettled: async () => {
            void queryClient.invalidateQueries({
                queryKey: ['scout-assignments-calendar', filterParams],
            })
            void queryClient.invalidateQueries({
                queryKey: ['incomplete-reports', { scoutId: incompleteReportParams.scoutId, excludeUnrequried: true }],
            })
            await queryClient.invalidateQueries({
                queryKey: ['incomplete-reports', incompleteReportParams],
            })
            await queryClient.invalidateQueries({
                queryKey: ['assignment-widget'],
            })
        },
    })
}

export type ScoutAssignmentQueryParams = {
    teamIds?: string[]
    gameDate?: string
    leagues?: Enum.League[]
    startDate?: string
    endDate?: string
    gameIds?: string[]
    scoutIds?: string[]
    regions?: Enum.Region[]
    boardId?: string
    assignmentIds?: string[]
    viewing?: Enum.AssignmentViewing
    level?: Enum.BoardLevels
    boardTier?: string | undefined
    includeDetailFields?: boolean
    stateProvinces?: string[]
}

type EditScoutsMutationType = { scouts: DTO.AssignedScout[]; boardId?: string }
export const useEditScouts = ({
    params,
}: {
    params?: ScoutAssignmentQueryParams
}): UseMutationResult<JSONResponse, Error, EditScoutsMutationType> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ scouts, boardId }: EditScoutsMutationType) =>
            put<undefined, DTO.AssignedScout[]>('/scout-assignments/status', scouts, { boardId }),

        onMutate: async (variables) => {
            const assignmentQueryKey = ['scout-assignments-calendar', params]
            await queryClient.cancelQueries({
                queryKey: assignmentQueryKey,
            })
            const previousAssignmentData = queryClient.getQueryData(assignmentQueryKey) as DTO.AssignmentCalendar[]
            const updatedAssignmentData = previousAssignmentData.map((a) => {
                if (
                    a.extendedProps.assignmentId === variables.scouts[0].assignmentId &&
                    a.extendedProps.scoutId === variables.scouts[0].entityId
                ) {
                    return {
                        ...a,
                        extendedProps: {
                            ...a.extendedProps,
                            boardId: variables.boardId || a.extendedProps.boardId,
                            assignmentStatus: variables.scouts[0].assignmentStatus,
                        },
                    }
                }
                return a
            })
            queryClient.setQueryData(assignmentQueryKey, updatedAssignmentData)

            return { previousAssignmentData }
        },

        onError: (_err, _updatedAssignmentData, context) => {
            const { previousAssignmentData } = context as {
                previousAssignmentData: DTO.AssignmentCalendar[]
            }
            queryClient.setQueryData(['scout-assignments', params], previousAssignmentData)
        },

        onSettled: async () => {
            void queryClient.invalidateQueries({
                queryKey: ['scout-assignments', params],
            })
            void queryClient.invalidateQueries({
                queryKey: ['assignment-widget'],
            })
            await queryClient.invalidateQueries({
                queryKey: ['scout-assignments-calendar'],
            })
        },
    })
}

export const useQueryScouts = (
    level?: Enum.BoardLevels,
    options?: Omit<UseQueryOptions<DTO.AssignedScout[]>, 'queryKey' | 'queryFn' | 'refetchInterval'>
): UseQueryResult<DTO.AssignedScout[]> =>
    useQuery({
        queryKey: ['scouts', level],
        queryFn: async () => (await get<DTO.AssignedScout[]>('/scout-assignments/scouts', { level })).data,
        ...options,
        enabled: options?.enabled !== false,
    })

export const getAssignmentById = async (assignmentId: string): Promise<DTO.AddAssignment> =>
    (await get<DTO.AddAssignment>(`/scout-assignments/${assignmentId}`)).data

export const useQueryAssignmentById = (assignmentId: string | undefined): UseQueryResult<DTO.AddAssignment> =>
    useQuery({
        queryKey: ['scout-assignments', assignmentId],
        queryFn: () => getAssignmentById(assignmentId as string),
        enabled: !!assignmentId,
    })

const queryScoutAssignments = async (params?: ScoutAssignmentQueryParams): Promise<DTO.AssignmentCalendar[]> =>
    (await get<DTO.AssignmentCalendar[]>(`/scout-assignments/assignments`, params)).data

export const useQueryScoutAssignments = (
    params?: ScoutAssignmentQueryParams,
    options?: Omit<UseQueryOptions<DTO.AssignmentCalendar[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AssignmentCalendar[]> =>
    useQuery({
        queryKey: ['scout-assignments', params],
        queryFn: () => queryScoutAssignments(params),
        ...options,
    })

const queryAssignmentWidget = async (): Promise<DTO.AssignmentWidget[]> =>
    (await get<DTO.AssignmentWidget[]>(`/scout-assignments/assignment-widget`)).data

export const useQueryAssignmentWidget = (
    options?: Omit<UseQueryOptions<DTO.AssignmentWidget[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AssignmentWidget[]> =>
    useQuery({
        queryKey: ['assignment-widget'],
        queryFn: () => queryAssignmentWidget(),
        ...options,
    })

export const useQueryScoutAssignmentsById = (
    params: { assignmentIds?: string[] },
    options?: Omit<UseQueryOptions<DTO.AssignmentCalendar[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AssignmentCalendar[]> =>
    useQuery({
        queryKey: ['scout-assignments', params],

        queryFn: async () =>
            (
                await post<DTO.AssignmentCalendar[], { assignmentIds?: string[] }>(
                    `/scout-assignments/assignments_by_id`,
                    params
                )
            ).data,

        ...options,
    })

const queryIncompleteAssignments = async (params?: {
    gameId?: string
    scoutId?: string
    assignmentId?: string
    startDate?: string
    endDate?: string
}): Promise<DTO.IncompleteReport[]> =>
    (await get<DTO.IncompleteReport[]>(`/scout-assignments/incomplete-reports`, params)).data

const queryIncompleteAssignmentsSummary = async (params?: {
    gameId?: string
    scoutId?: string
    assignmentId?: string
    startDate?: string
    endDate?: string
}): Promise<DTO.IncompleteReportSummary[]> =>
    (await get<DTO.IncompleteReportSummary[]>(`/scout-assignments/incomplete-reports-summary`, params)).data

export type IncompleteReportsQueryParams = Partial<{
    gameId: string
    scoutId: string
    assignmentId: string
    startDate: string
    endDate: string
    level: Enum.BoardLevels[]
}>
export const useQueryIncompleteReports = (
    params?: IncompleteReportsQueryParams,
    options?: Omit<UseQueryOptions<DTO.IncompleteReport[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.IncompleteReport[]> =>
    useQuery({
        queryKey: ['incomplete-reports', params],
        queryFn: () => queryIncompleteAssignments(params),
        ...options,
    })

export const useQueryIncompleteReportsSummary = (
    params?: IncompleteReportsQueryParams,
    options?: Omit<UseQueryOptions<DTO.IncompleteReportSummary[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.IncompleteReportSummary[]> =>
    useQuery({
        queryKey: ['incomplete-reports-summary', params],
        queryFn: () => queryIncompleteAssignmentsSummary(params),
        ...options,
    })

export const useQueryMappedAssignmentsForCalendar = (
    params?: ScoutAssignmentQueryParams,
    options?: Omit<UseQueryOptions<DTO.AssignmentCalendar[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AssignmentCalendar[]> =>
    useQuery({
        queryKey: ['scout-assignments-calendar', params],
        queryFn: async () => (await get<DTO.AssignmentCalendar[]>(`/scout-assignments/calendar`, params)).data,
        ...options,
    })
