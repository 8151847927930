/* eslint-disable @typescript-eslint/restrict-template-expressions */
import type { UseQueryResult, UseQueryOptions, QueryKey } from '@tanstack/react-query'
import { useQuery, useQueries } from '@tanstack/react-query'
import { get } from '../api'

const usePlayerStats = (
    playerId: string | null | undefined,
    season?: number,
    gameType?: Enum.GameType[],
    groupByType?: Enum.GroupByType,
    options?: Omit<
        UseQueryOptions<DTO.SeasonStats[], Error, DTO.SeasonStats[], QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.SeasonStats[]> =>
    useQuery({
        queryKey: ['season-boxscore', playerId, season, gameType, groupByType],
        queryFn: async () => (await get<DTO.SeasonStats[]>(`/stats/season/${playerId}`, { season, gameType, groupByType })).data,
        ...options,
        enabled: !!playerId && options?.enabled !== false
    })

export const usePositionStats = (
    secondaryPosition: DTO.PlayerSecondaryPosition | null | undefined,
    season?: number,
    options?: Omit<
        UseQueryOptions<DTO.SeasonStats[], Error, DTO.SeasonStats[], QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.SeasonStats[]> =>
    useQuery({
        queryKey: ['season-boxscore', secondaryPosition, season],
        queryFn: async () => (await get<DTO.SeasonStats[]>(`/stats/position/${secondaryPosition}`, { season })).data,
        enabled: !!secondaryPosition,
        ...options
    })

export const usePositionStatThresholds = (
    secondaryPosition: DTO.PlayerSecondaryPosition | undefined,
    season: number,
    options?: Omit<
        UseQueryOptions<DTO.StatThresholds, Error, DTO.StatThresholds, QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.StatThresholds> =>
    useQuery({
        queryKey: ['thresholds', secondaryPosition, season],
        queryFn: async () => (await get<DTO.StatThresholds>(`/stats/position/${secondaryPosition}/thresholds`, { season })).data,
        ...options,
        enabled: !!secondaryPosition && options?.enabled !== false
    })

type AggStatThresholdsQueryResults = Pick<UseQueryResult<Partial<DTO.StatThresholds>>, 'isLoading' | 'data'>
export const useAggPostionStatThresholds = (
    secondaryPosition: DTO.PlayerSecondaryPosition | undefined,
    leagueSeasons: [Enum.League, number, number][] | undefined,
    options?: Omit<UseQueryOptions<DTO.StatThresholds>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): AggStatThresholdsQueryResults => {
    const queries: UseQueryOptions<DTO.StatThresholds>[] | undefined = leagueSeasons?.map(
        ([league, minSeason, maxSeason]) => ({
            queryKey: ['agg-thresholds', secondaryPosition, league, minSeason, maxSeason],
            queryFn: async () =>
                (
                    await get<DTO.StatThresholds>(`/stats/position/${secondaryPosition}/agg-thresholds`, {
                        league,
                        minSeason,
                        maxSeason,
                    })
                ).data,
            options: { ...options, enabled: !!secondaryPosition && options?.enabled !== false },
        })
    )
    return useQueries({
        queries: queries || [],
    }).reduce<AggStatThresholdsQueryResults>(
        (acc, query) => {
            acc.isLoading = acc.isLoading || query.isLoading
            if (query.data) {
                if (!acc.data) acc.data = {}
                Object.entries(query.data).forEach(([key, value]) => {
                    const stat = key as keyof DTO.StatThresholds
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    acc.data![stat] = { ...acc.data![stat], ...value }
                })
            }
            return acc
        },
        { isLoading: false, data: undefined }
    )
}

export const usePlayerStatsFilterOptions = (playerId: string | undefined): UseQueryResult<DTO.StatsFilterOptions> =>
    useQuery({
        queryKey: ['player-stats-filter-options', playerId],
        queryFn: async () => (await get<DTO.StatsFilterOptions>(`/stats/${playerId}/filter-options`)).data,
        enabled: !!playerId
    })

export const useDetailPlayerStats = (
    playerId: string | null | undefined,
    statFormat: Enum.StatFormat | null | undefined,
    statType: 'BASIC' | 'SHOOTING' | null | undefined,
    filters: Partial<DTO.PlayerStatsFilters>,
    options?: Omit<
        UseQueryOptions<DTO.PlayerStats[], Error, DTO.PlayerStats[], QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerStats[]> =>
    useQuery({
        queryKey: ['stats', playerId, statFormat, filters, statType],
        queryFn: async () => (await get<DTO.PlayerStats[]>(`/stats/${playerId}/${statType}/${statFormat}`, filters)).data,
        enabled: !!playerId && !!statFormat && !!statType,
        ...options
    })

export const useLeagueStats = (
    secondaryPosition: DTO.PlayerSecondaryPosition | null | undefined,
    statType: Enum.StatType | null | undefined,
    options?: Omit<
        UseQueryOptions<DTO.GameStatThresholds, Error, DTO.GameStatThresholds, QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.GameStatThresholds> =>
    useQuery({
        queryKey: ['stats', secondaryPosition, statType],
        queryFn: async () => (await get<DTO.GameStatThresholds>(`/stats/${statType}/${secondaryPosition}`)).data,
        enabled: !!secondaryPosition && !!statType && options?.enabled,
        ...options
    })

export default usePlayerStats
