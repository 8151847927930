import type { JSONResponse } from '../api'
import type { UseQueryResult, UseSuspenseQueryResult, UseMutationResult, UseQueryOptions } from '@tanstack/react-query'
import type { InjuryFilterParams } from 'app/(nonEntity)/injuries/injuries'
import { useQueryClient, useQuery, useSuspenseQuery, useMutation } from '@tanstack/react-query'
import { post, get, serverGet } from '../api'

const injuryHistoryQueryKey = (playerId: string | undefined, season: number) =>
    ['injury-history', playerId, season] as const
const injuryHistoryQueryFn = async (playerId: string, season: number) =>
    (await get<DTO.InjuryHistory>(`/profile/${playerId}/${season}/injury-history`)).data

const useInjuryHistory = (playerId: string | undefined, season: number): UseQueryResult<DTO.InjuryHistory> =>
    useQuery({
        queryKey: injuryHistoryQueryKey(playerId, season),
        queryFn: () => injuryHistoryQueryFn(playerId!, season), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!playerId && !!season,
    })

export const useSuspenseInjuryHistory = (playerId: string, season: number): UseSuspenseQueryResult<DTO.InjuryHistory> =>
    useSuspenseQuery({
        queryKey: injuryHistoryQueryKey(playerId, season),
        queryFn: () => injuryHistoryQueryFn(playerId, season),
    })

export const serverGetCurrentInjuries = async (params: CurrentInjuryParams): Promise<DTO.CurrentInjuries[]> =>
    (
        await serverGet<DTO.CurrentInjuries[]>(`/injury/current-injuries`, {
            params,
            contentPermissions: ['injury-history'],
            apiCacheKey: '/injury/current-injuries',
            cacheTag: 'current-injuries',
        })
    ).data

export const useCompleteInjuryHistory = (
    playerId: string | undefined
): UseQueryResult<DTO.PlayerCompleteInjuryHistory> =>
    useQuery({
        queryKey: ['complete-injury-history', playerId],

        // playerId will be defined in hook is enabled
        queryFn:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            async () => (await get<DTO.PlayerCompleteInjuryHistory>(`/injury/player/${playerId}/history`)).data,

        enabled: !!playerId,
    })

export const useInjuryHistoryComp = (
    league: Enum.League | undefined,
    secondaryPosition: string | null,
    age: number | null
): UseQueryResult<DTO.InjuryHistorySeason> => {
    const roundedAge = age && Math.round(age)
    return useQuery({
        queryKey: ['injury-history-comp', league, secondaryPosition, roundedAge],

        queryFn: async () =>
            (
                await get<DTO.InjuryHistorySeason>(
                    `/profile/injury-comps/${league || ''}/${secondaryPosition || ''}/${roundedAge || ''}`
                )
            ).data,

        enabled: !!league && !!secondaryPosition && !!roundedAge,
    })
}

export const useUpdateInjuryNotes = (
    filterParams?: InjuryFilterParams
): UseMutationResult<JSONResponse<DTO.PlayerInjuryHistory>, Error, DTO.PlayerInjuryHistory> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (injuryUpdate: DTO.PlayerInjuryHistory) =>
            post<DTO.PlayerInjuryHistory, DTO.PlayerInjuryHistory>(
                `/injury/${injuryUpdate.injuryId}/notes`,
                injuryUpdate
            ),

        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: ['complete-injury-history', filterParams?.playerId],
            })
            void queryClient.invalidateQueries({
                queryKey: ['injury-dashboard', filterParams],
            })
        },
    })
}

export type CurrentInjuryParams = {
    teamId?: string
    league?: Enum.League
}

const currentInjuryQueryKey = (params: CurrentInjuryParams) => ['current-injuries', params] as const
const queryCurrentInjuries = async (params: CurrentInjuryParams): Promise<DTO.CurrentInjuries[]> =>
    (await get<DTO.CurrentInjuries[]>(`/injury/current-injuries`, params)).data

export const useCurrentInjuries = (
    params: CurrentInjuryParams,
    options?: Omit<
        UseQueryOptions<DTO.CurrentInjuries[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.CurrentInjuries[]> =>
    useQuery({
        queryKey: currentInjuryQueryKey(params),
        queryFn: () => queryCurrentInjuries(params),
        enabled: options?.enabled !== false,
    })

export const useSuspenseCurrentInjuries = (
    params: CurrentInjuryParams
): UseSuspenseQueryResult<DTO.CurrentInjuries[]> =>
    useSuspenseQuery({
        queryKey: currentInjuryQueryKey(params),
        queryFn: () => queryCurrentInjuries(params),
    })

const queryInjuryDashboard = async (params: InjuryFilterParams): Promise<DTO.PlayerInjuryHistory[]> =>
    (await get<DTO.PlayerInjuryHistory[]>(`/injury/injuries`, params)).data

export const useInjuryDashboard = (
    params: InjuryFilterParams,
    options?: Omit<
        UseQueryOptions<DTO.PlayerInjuryHistory[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerInjuryHistory[]> =>
    useQuery({
        queryKey: ['injury-dashboard', params],
        queryFn: () => queryInjuryDashboard(params),
        enabled: options?.enabled !== false,
    })

export type InjuryNewsParams = {
    playerId?: string | null
    injuryId?: string | null
}

const injuryNewsQueryKey = (params: InjuryNewsParams) => ['injury-news', params.playerId] as const
const queryInjuryNews = async (params: InjuryNewsParams): Promise<DTO.InjuryNewsResp[]> =>
    (await get<DTO.InjuryNewsResp[]>(`/injury/news`, params)).data

export const useInjuryNews = (
    params: InjuryNewsParams,
    options?: Omit<
        UseQueryOptions<DTO.InjuryNewsResp[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.InjuryNewsResp[]> =>
    useQuery({
        queryKey: injuryNewsQueryKey(params),
        queryFn: () => queryInjuryNews(params),
        enabled: (!!params.playerId || !!params.injuryId) && options?.enabled !== false,
    })

export const useSuspenseInjuryNews = (params: InjuryNewsParams): UseSuspenseQueryResult<DTO.InjuryNewsResp[]> =>
    useSuspenseQuery({
        queryKey: injuryNewsQueryKey(params),
        queryFn: () => queryInjuryNews(params),
    })

export default useInjuryHistory
