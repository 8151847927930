import type { UseQueryOptions, UseQueryResult, UseSuspenseQueryResult, QueryKey } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import { useQuery, useSuspenseQueries } from '@tanstack/react-query'
import { get, serverGet } from '../api'

export const queryKey = (playerId: string): QueryKey => ['players', playerId]
const apiPath = (playerSlug: string): string => `/players/${playerSlug}`

export const serverGetPlayer = async (playerSlug: string, session: Session | null): Promise<DTO.Player> =>
    (await serverGet<DTO.Player>(apiPath(playerSlug), { session, contentPermissions: ['player-salary-info'] })).data

export const getPlayer = async (playerSlug: string): Promise<DTO.Player> =>
    (await get<DTO.Player>(apiPath(playerSlug))).data

export const getPlayerById = async (playerId: string): Promise<DTO.Player> =>
    (await get<DTO.Player>(`/players/by_id/${playerId}`)).data
export const serverGetPlayerById = async (playerId: string, session?: Session | null): Promise<DTO.Player> =>
    (await serverGet<DTO.Player>(`/players/by_id/${playerId}`, { session })).data

export const staticGetPlayerName = async (playerSlug: string): Promise<DTO.PlayerNames> =>
    (
        await serverGet<DTO.PlayerNames>(`/players/${playerSlug}/name`, {
            apiCacheKey: '/players/:playerSlug/name',
            cacheTag: `player-name-${playerSlug}`,
        })
    ).data

const usePlayer = (
    playerSlug: string | null | undefined,
    options?: Omit<UseQueryOptions<DTO.Player>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Player> =>
    useQuery({
        queryKey: queryKey(playerSlug!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getPlayer(playerSlug!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!playerSlug && options?.enabled !== false,
    })

export const useGetPlayerById = (
    playerId: string | null | undefined,
    options?: Omit<UseQueryOptions<DTO.Player>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Player> =>
    useQuery({
        queryKey: queryKey(playerId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getPlayerById(playerId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!playerId && options?.enabled !== false,
    })
export const useSuspenseGetPlayersById = (playerIds: string[]): UseSuspenseQueryResult<DTO.Player[]> =>
    useSuspenseQueries({
        queries: playerIds.map((id) => ({ queryKey: queryKey(id), queryFn: () => getPlayerById(id) })),
    })

export const usePlayerIdLookupByPlayerId = (q: string): UseQueryResult<DTO.PlayerIdLookup[]> =>
    useQuery({
        queryKey: ['get-player-id-lookup', q],
        queryFn: async () => (await get<DTO.PlayerIdLookup[]>('/players/player-id-lookup', { q })).data,
        enabled: !!q && q.length >= 1,
    })

export default usePlayer
