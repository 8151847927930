import type { UseQueryResult, UseQueryOptions, QueryKey } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet } from '../api'

export const getLocProjections = async (
    playerId: string,
    season: number,
    level: Enum.PlayerLevel
): Promise<DTO.LOCProjections[]> =>
    (await get<DTO.LOCProjections[]>(`/profile/${playerId}/${season}/${level}/loc-projections`)).data

export const serverGetLocProjections = async (
    playerId: string,
    season: number,
    level: Enum.PlayerLevel
): Promise<DTO.LOCProjections[]> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (
        await serverGet<DTO.LOCProjections[]>(`/profile/${playerId}/${season}/${level}/loc-projections`, {
            featurePermissions: ['player-profile'],
            apiCacheKey: '/profile/:playerId/:season/:level/loc-projections',
            cacheTag: `loc-projections-${playerId}-${season}-${level}`,
        })
    ).data

const useLOCProjections = (
    playerId: string | null | undefined,
    season: number,
    level: Enum.PlayerLevel | null | undefined,
    options?: Omit<
        UseQueryOptions<DTO.LOCProjections[], Error, DTO.LOCProjections[], QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.LOCProjections[]> =>
    useQuery({
        queryKey: ['loc-projections', playerId, season, level],
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        queryFn: async () => getLocProjections(playerId as string, season, level as Enum.PlayerLevel),
        ...options,
        enabled: !!playerId && !!season && !!level && options?.enabled !== false,
    })

export const useLOCValues = (
    options?: Omit<UseQueryOptions<DTO.LOCValue[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.LOCValue[]> =>
    useQuery({
        queryKey: ['loc-values'],
        queryFn: async () => (await get<DTO.LOCValue[]>(`/profile/loc-values`)).data,
        ...options,
    })

export const serverGetLOCValues = async (): Promise<DTO.LOCValue[]> =>
    (
        await serverGet<DTO.LOCValue[]>(`/profile/loc-values`, {
            apiCacheKey: '/profile/loc-values',
            cacheTag: 'loc-values',
        })
    ).data

export default useLOCProjections
