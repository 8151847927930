import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

export const getSkillsForPastThirtyDays = async (
    playerId: string,
    scoutEntityId: string
): Promise<DTO.ScoutingSkills | ''> =>
    (await get<DTO.ScoutingSkills>(`/scouting-skills/${playerId}/${scoutEntityId}/past-thirty-days`)).data

export const useGetSkillsForPastThirtyDays = ({
    playerId,
    scoutEntityId,
    options,
}: {
    playerId: string | null
    scoutEntityId: string
    options: Omit<
        UseQueryOptions<DTO.ScoutingSkills | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.ScoutingSkills | ''> =>
    useQuery({
        queryKey: ['scouting-skills', playerId, scoutEntityId],
        queryFn: () => getSkillsForPastThirtyDays(playerId!, scoutEntityId), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!playerId,
        ...options,
    })

export const getCurrentSkills = async (
    playerId: string,
    secondaryPosition: DTO.PlayerSecondaryPosition
): Promise<DTO.CurrentScoutingSkills> =>
    (await get<DTO.CurrentScoutingSkills>(`/scouting-skills/${playerId}/${secondaryPosition}/current`)).data

export const useGetCurrentSkills = ({
    playerId,
    secondaryPosition,
}: {
    playerId: string | undefined
    secondaryPosition: DTO.PlayerSecondaryPosition | undefined
}): UseQueryResult<DTO.CurrentScoutingSkills> =>
    useQuery({
        queryKey: ['scouting-skills', playerId, secondaryPosition, 'current'],
        queryFn: () => getCurrentSkills(playerId!, secondaryPosition!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!secondaryPosition && !!playerId,
    })
