import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const getPlayerLeaderboard = async (filters: DTO.LeaderboardFilterParams): Promise<DTO.Leaderboard[]> =>
    (await get<DTO.Leaderboard[]>(`/leaderboard`, filters)).data

export const usePlayerLeaderboard = (filters: DTO.LeaderboardFilterParams): UseQueryResult<DTO.Leaderboard[]> =>
    useQuery({
        queryKey: ['player-leaderboard', filters],
        queryFn: () => getPlayerLeaderboard(filters),
    })
