import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const usePlayerSalary = (
    playerId: string | null | undefined,
    season: number,
    options?: Omit<
        UseQueryOptions<DTO.PlayerContract | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerContract | ''> =>
    useQuery({
        queryKey: ['player-salary', playerId, season],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: async () => (await get<DTO.PlayerContract | ''>(`/profile/${playerId!}/${season}/salary`)).data,
        ...options,
        enabled: !!playerId && !!season && options?.enabled !== false,
    })

export default usePlayerSalary
