import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import type { RequestParams } from '../api'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const apiPath = '/games'

export type GameQueryParams = RequestParams & {
    teamIds?: string[]
    gameDate?: string
    leagues?: Enum.League[]
    startDate?: string
    endDate?: string
    season?: string
    status?: Enum.GameStatus[]
}

export const queryGames = async (params?: GameQueryParams): Promise<DTO.Game[]> =>
    (await get<DTO.Game[]>(`${apiPath}`, params)).data

export const useQueryGames = ({
    params,
    options,
}: {
    params?: GameQueryParams
    options?: Omit<UseQueryOptions<DTO.Game[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
}): UseQueryResult<DTO.Game[]> =>
    useQuery({
        queryKey: ['games', params],
        queryFn: () => queryGames(params),
        enabled: options?.enabled,
    })

export const getGameByGameId = async (gameId: string): Promise<DTO.Game> =>
    (await get<DTO.Game>(`${apiPath}/${gameId})`)).data

export const useGetGameByGameId = (gameId: string | undefined): UseQueryResult<DTO.Game> =>
    useQuery({
        queryKey: ['games', gameId],
        queryFn: () => getGameByGameId(gameId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!gameId,
    })

export type BoardPlayersQueryParams = RequestParams & {
    startDate?: string
    endDate?: string
    gameId?: string
    assignmentId?: string
    getPrimary?: boolean
    scoutId?: string
    boardId?: string
    viewing?: string
}

export const queryBoardPlayers = async (params: BoardPlayersQueryParams): Promise<DTO.GameBoardPlayer[]> =>
    (await get<DTO.GameBoardPlayer[]>(`${apiPath}/board-players`, params)).data

export const useQueryBoardPlayers = ({
    params,
    options,
}: {
    params: BoardPlayersQueryParams
    options?: Omit<
        UseQueryOptions<DTO.GameBoardPlayer[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.GameBoardPlayer[]> =>
    useQuery({
        queryKey: ['board-players', params],
        queryFn: () => queryBoardPlayers(params),
        ...options,
    })
