import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { get } from '../api'

type StrategyLOCQueryParams = {
    playerId: string | undefined
    contractId: string | undefined
}

const getStrategyLOC = async ({
    playerId,
    contractId,
}: {
    playerId: string
    contractId: string
}): Promise<DTO.StrategyLOC | ''> => (await get<DTO.StrategyLOC | ''>(`/strategy-locs/${playerId}/${contractId}`)).data

export const useGetStrategyLOC = ({
    playerId,
    contractId,
    options,
}: StrategyLOCQueryParams & {
    options?: Omit<
        UseQueryOptions<DTO.IntensityGrade | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.StrategyLOC | ''> => {
    const { data: session } = useSession()
    return useQuery({
        queryKey: ['strategyLOCs', { playerId, contractId }],
        queryFn: () => getStrategyLOC({ playerId: playerId!, contractId: contractId! }), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled:
            !!playerId &&
            !!contractId &&
            !!session?.roles.contentPermissions['strategy-locs'] &&
            options?.enabled !== false,
    })
}
