import type { UseQueryResult, UseQueryOptions, QueryKey } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const apiPath = '/mock-model'

export const useGetMockDraftRange = (
    playerId: string | null | undefined,
    draftYear: number,
    options?: Omit<
        UseQueryOptions<DTO.MockDraftRange | null, Error, DTO.MockDraftRange | null, QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.MockDraftRange | null> => {
    const { data: session } = useSession()
    return useQuery({
        queryKey: ['mock-draft-range', playerId, draftYear],

        queryFn: // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        async () => (await get<DTO.MockDraftRange | null>(`${apiPath}/${playerId}/${draftYear}`)).data,

        ...options,

        enabled:
            !!playerId &&
            !!draftYear &&
            !!session?.roles.contentPermissions['mock-draft-model'] &&
            options?.enabled !== false
    });
}

export const queryCurrentAndFutureMockDraftSeasons = async (draftYear: number): Promise<string[]> =>
    (await get<string[]>(`${apiPath}/current-and-future-seasons/${draftYear}`)).data

export const useQueryCurrentAndFutureSeasons = (
    draftYear: number,
    options?: Omit<UseQueryOptions<string[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<string[]> =>
    useQuery({
        queryKey: ['currentAndFutureSeasons', draftYear],
        queryFn: () => queryCurrentAndFutureMockDraftSeasons(draftYear),
        ...options,
        enabled: !!draftYear && options?.enabled !== false
    })
