import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import type { DraftQueryParams } from './useDraftAssets'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const apiPath = '/draft'

const queryMockDraft = async (params: DraftQueryParams): Promise<DTO.MockDraft[]> =>
    (await get<DTO.MockDraft[]>(`${apiPath}/mock-draft/${params.league}/${params.draft}`, params)).data

export const useMockDraft = ({ params }: { params: DraftQueryParams }): UseQueryResult<DTO.MockDraft[]> =>
    useQuery({
        queryKey: ['mock-draft', params],
        queryFn: () => queryMockDraft(params)
    })

export const queryTeamOwnership = async (params: DraftQueryParams): Promise<DTO.TeamOwnership[]> =>
    (await get<DTO.TeamOwnership[]>(`${apiPath}/team-ownership`, params)).data

export const useTeamOwnership = ({
    params,
    options,
}: {
    params: DraftQueryParams
    options?: Omit<
        UseQueryOptions<DTO.TeamOwnership[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.TeamOwnership[]> =>
    useQuery({
        queryKey: ['team-ownership', params],
        queryFn: () => queryTeamOwnership(params),
        enabled: options?.enabled
    })

export const queryDraftAssets = async (params: DraftQueryParams): Promise<DTO.DraftAssetMap[]> =>
    (await get<DTO.DraftAssetMap[]>(`${apiPath}/assets`, params)).data

export const useDraftAssets = ({
    params,
    options,
}: {
    params: DraftQueryParams
    options?: Omit<
        UseQueryOptions<DTO.DraftAssetMap[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.DraftAssetMap[]> =>
    useQuery({
        queryKey: ['assets', params],
        queryFn: () => queryDraftAssets(params),
        enabled: options?.enabled
    })

export const queryPickOwnership = async (params: DraftQueryParams): Promise<DTO.ProjectedDraftAsset[]> =>
    (await get<DTO.ProjectedDraftAsset[]>(`${apiPath}/pick-ownership`, params)).data

export const usePickOwnership = (
    params: DraftQueryParams,
    options?: Omit<
        UseQueryOptions<DTO.ProjectedDraftAsset[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.ProjectedDraftAsset[]> =>
    useQuery({
        queryKey: ['pick-ownership', params],
        queryFn: () => queryPickOwnership(params),
        ...options
    })

export const queryMockModels = async (draft: number): Promise<DTO.MockModel[]> =>
    (await get<DTO.MockModel[]>(`${apiPath}/mock-models/${draft}`)).data

export const useMockModels = (
    draft: number,
    options?: Omit<UseQueryOptions<DTO.MockModel[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.MockModel[]> =>
    useQuery({
        queryKey: ['mock-models', draft],
        queryFn: () => queryMockModels(draft),
        ...options
    })

export default useMockDraft
