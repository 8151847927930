import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const useInsightsSkills = (playerId: string, season: number, league: Enum.League): UseQueryResult<DTO.ScoutingSkills> =>
    useQuery({
        queryKey: ['insights-skills', playerId, season, league],
        queryFn: async () => (await get<DTO.ScoutingSkills>(`/profile/${playerId}/${season}/${league}/insights-skills`)).data
    })

export default useInsightsSkills
