import type { QueryKey, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '@/lib/api'
import type { PaginatedResp, InfiniteQueryPaginatedResp } from './usePosts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'
import { useSession } from 'next-auth/react'
import slugify from 'slugify'
import { post, put, remove } from '../api'
import useToastContext from './useToastContext'

const apiPath = '/comments'

export const useCreateComment = (): UseMutationResult<JSONResponse, Error, Params.Comment> => {
    const queryClient = useQueryClient()
    const { data: session } = useSession()
    const toastContext = useToastContext()
    const name = session?.user?.name?.trim().split(',').reverse().join(' ').trim() || ''
    return useMutation({
        mutationFn: (comment: Params.Comment) => post<undefined, Params.Comment>(`${apiPath}`, comment),

        onMutate: async (newComment) => {
            await queryClient.cancelQueries({
                queryKey: ['posts'],
            })

            const queriesData = queryClient.getQueriesData<InfiniteQueryPaginatedResp>({
                queryKey: ['posts'],
            })
            if (!queriesData.length) return {}

            const [[queryKey, oldData]] = queriesData
            if (!oldData) return {}

            const comment: DTO.Comment = {
                ...newComment,
                commentId: uuidv4(),
                authorId: session?.entityId as string,
                editorId: session?.userId as number,
                createdAt: new Date().toJSON(),
                updatedAt: new Date().toJSON(),
                author: {
                    entityId: session?.entityId as string,
                    name,
                    nameAbbr: name,
                    type: 'user',
                    urlSlug: slugify(name, { lower: true, strict: true }),
                },
            }
            const newPages = oldData.pages.map((page: PaginatedResp) => ({
                ...page,
                results: page.results.map((oldPost) => {
                    if (oldPost.postID === newComment.postId) {
                        return {
                            ...oldPost,
                            comments: [...oldPost.comments, comment],
                        }
                    }
                    return oldPost
                }),
            }))

            queryClient.setQueryData<InfiniteQueryPaginatedResp>(queryKey, {
                ...oldData,
                pages: newPages,
            })

            return { queryKey, oldData }
        },

        onError: (error, data, context) => {
            toastContext?.addToast({
                severity: 'error',
                message: error.message,
            })

            if (context) {
                const { queryKey, oldData } = context as {
                    queryKey?: QueryKey
                    oldData?: InfiniteQueryPaginatedResp
                }
                if (queryKey && oldData) queryClient.setQueryData(queryKey, oldData)
            }
        },

        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['posts'],
            })
            await queryClient.invalidateQueries({
                queryKey: ['post'],
            })
        },
    })
}

export const useUpdateComment = (): UseMutationResult<JSONResponse, Error, Params.Comment> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation({
        mutationFn: (comment: Params.Comment) =>
            put<undefined, Params.Comment>(`${apiPath}/${comment.commentId || ''}`, comment),

        onMutate: async (updatedComment) => {
            await queryClient.cancelQueries({
                queryKey: ['posts'],
            })

            const queriesData = queryClient.getQueriesData<InfiniteQueryPaginatedResp>({
                queryKey: ['posts'],
            })
            const [[queryKey, oldData]] = queriesData
            if (!oldData) return {}

            const newPages = oldData.pages.map((page: PaginatedResp) => ({
                ...page,
                results: page.results.map((oldPost) => {
                    if (oldPost.postID === updatedComment.postId) {
                        return {
                            ...oldPost,
                            comments: oldPost.comments.map((c) =>
                                c.commentId === updatedComment.commentId ? { ...c, ...updatedComment } : c
                            ),
                        }
                    }
                    return oldPost
                }),
            }))

            queryClient.setQueryData<InfiniteQueryPaginatedResp>(queryKey, {
                ...oldData,
                pages: newPages,
            })

            // Return a context object with the snapshotted value
            return { queryKey, oldData }
        },

        onError: (error, data, context) => {
            toastContext?.addToast({
                severity: 'error',
                message: error.message,
            })

            if (context) {
                const { queryKey, oldData } = context as {
                    queryKey?: QueryKey
                    oldData?: InfiniteQueryPaginatedResp
                }
                if (queryKey && oldData) queryClient.setQueryData(queryKey, oldData)
            }
        },

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useDeleteComment = (): UseMutationResult<JSONResponse, Error, DTO.Comment> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()

    return useMutation({
        mutationFn: (comment: DTO.Comment) => remove(`${apiPath}/${comment.commentId}`),

        onMutate: async (deletedComment) => {
            await queryClient.cancelQueries({
                queryKey: ['posts'],
            })

            const queriesData = queryClient.getQueriesData<InfiniteQueryPaginatedResp>({
                queryKey: ['posts'],
            })
            const [[queryKey, oldData]] = queriesData
            if (!oldData) return {}

            const newPages = oldData.pages.map((page: PaginatedResp) => ({
                ...page,
                results: page.results.map((oldPost) => {
                    if (oldPost.postID === deletedComment.postId) {
                        return {
                            ...oldPost,
                            comments: oldPost.comments.filter((c) => c.commentId !== deletedComment.commentId),
                        }
                    }
                    return oldPost
                }),
            }))

            queryClient.setQueryData<InfiniteQueryPaginatedResp>(queryKey, {
                ...oldData,
                pages: newPages,
            })

            return { queryKey, oldData }
        },

        onError: (error, data, context) => {
            toastContext?.addToast({
                severity: 'error',
                message: error.message,
            })

            if (context) {
                const { queryKey, oldData } = context as {
                    queryKey?: QueryKey
                    oldData?: InfiniteQueryPaginatedResp
                }
                if (queryKey && oldData) queryClient.setQueryData(queryKey, oldData)
            }
        },

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),

        onSuccess: () => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Comment Deleted',
            })
        },
    })
}
