import type { Except, SetRequired, Merge } from 'type-fest'
import type {
    UseMutationResult,
    UseInfiniteQueryResult,
    InfiniteData,
    UseQueryResult,
    UseQueryOptions,
} from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import type dayjs from 'dayjs'
import { useInfiniteQuery, useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { get, post } from '../api'
import { useConstantsContext } from '../contexts/ConstantsContext'

export type IntelFormTextGrade = Except<DTO.TextGrade, 'type' | 'subCategory'> & {
    id: string
    type: DTO.TextGrade['type'] | ''
}
export type EditIntelReport = SetRequired<CreateIntelReport, 'postID'>
export type CreateIntelReport = Pick<
    DTO.CreateIntelReport,
    'type' | 'status' | 'level' | 'tags' | 'attachments' | 'textGrades' | 'postedAt'
> & {
    textGrades: IntelFormTextGrade[]
} & {
    [T in keyof Except<DTO.CreateIntelReport, 'type' | 'status' | 'level' | 'tags' | 'postedAt' | 'attachments'>]?:
        | DTO.CreateIntelReport[T]
        | ''
}

export type CreateScoutingSettings = Except<DTO.ScoutingSettings, 'type' | 'date'> & {
    type: DTO.ScoutingSettings['type'] | ''
    date: dayjs.Dayjs | null
}
export type EditScoutingReport = SetRequired<CreateScoutingReport, 'postID'>
export type CreateScoutingReport = Pick<
    DTO.CreateScoutingPost,
    'type' | 'status' | 'level' | 'tags' | 'attachments' | 'postedAt'
> & {
    settings: CreateScoutingSettings[]
    playerLists: (string | Merge<DTO.PlayerList, { name: Enum.PlayerListName | '' }>)[]
    isOnNBARoster: boolean
    isProScout: boolean
    skillsRecordedPastThirtyDays: boolean
    isAnyPositionFilledOut: boolean
} & {
    [T in keyof Except<
        DTO.CreateScoutingPost,
        'type' | 'status' | 'level' | 'tags' | 'postedAt' | 'settings' | 'playerLists' | 'attachments'
    >]?: DTO.CreateScoutingPost[T] | ''
}

export type CreatePlayerDevelopmentReport = Pick<
    DTO.PlayerDevelopmentReport,
    'type' | 'status' | 'tags' | 'attachments' | 'postedAt'
> & {
    [T in keyof Except<DTO.PlayerDevelopmentReport, 'type' | 'status' | 'tags' | 'attachments' | 'postedAt'>]?:
        | DTO.PlayerDevelopmentReport[T]
        | ''
}

export type CreateCommunityReport = Pick<
    DTO.CommunityReport,
    'type' | 'status' | 'tags' | 'attachments' | 'postedAt'
> & {
    [T in keyof Except<DTO.CommunityReport, 'type' | 'status' | 'tags' | 'attachments' | 'postedAt'>]?:
        | DTO.CommunityReport[T]
        | ''
}

export type CreateGameReport = Pick<DTO.GameReport, 'type' | 'status' | 'tags' | 'attachments' | 'postedAt'> & {
    gameStatus: Enum.GameStatus | null
} & {
    [T in keyof Except<DTO.GameReport, 'type' | 'status' | 'tags' | 'postedAt' | 'attachments'>]?:
        | DTO.GameReport[T]
        | ''
}

export type CreateWorkoutReport = Pick<
    DTO.CreateWorkoutReport,
    'type' | 'status' | 'tags' | 'attachments' | 'postedAt'
> & {
    [T in keyof Except<
        DTO.CreateWorkoutReport,
        'type' | 'status' | 'tags' | 'attachments' | 'postedAt' | 'participants'
    >]?: DTO.CreateWorkoutReport[T] | ''
} & {
    participants: (Pick<DTO.PlayerTag, 'name' | 'teamId'> & DTO.CreateWorkoutReport['participants'][number])[]
}

export type PaginatedResp<T = DTO.Post> = {
    results: T[]
    info: { nextPage: number; hasNextPage: boolean }
}

export type PaginatedIntelTextTypeReportResp = {
    results: DTO.IntelTextTypeReport[]
    info: { nextPage: number; hasNextPage: boolean }
}

export type InfiniteQueryPaginatedResp<T = DTO.Post> = InfiniteData<PaginatedResp<T>>

export const useCreateIntelReport = (): UseMutationResult<JSONResponse<DTO.Entity[]>, Error, CreateIntelReport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreateIntelReport) => post<DTO.Entity[], CreateIntelReport>('/posts/intel', report),

        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: ['posts'],
            })
            void queryClient.invalidateQueries({
                queryKey: ['search'],
            })
        },
    })
}
export const useCreateIntelReportDraft = (): UseMutationResult<
    JSONResponse<DTO.Entity[]>,
    Error,
    CreateIntelReport
> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreateIntelReport) => post<DTO.Entity[], CreateIntelReport>('/posts/intel/draft', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useCreateScoutingReport = (): UseMutationResult<JSONResponse, Error, CreateScoutingReport> => {
    const queryClient = useQueryClient()
    const { ncaaSeason } = useConstantsContext()
    return useMutation({
        mutationFn: (report: CreateScoutingReport) => post<undefined, CreateScoutingReport>('/posts/scouting', report),

        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['posts'],
            })
            await queryClient.invalidateQueries({
                queryKey: ['scout-player-tiers', ncaaSeason + 1],
            })
            await queryClient.invalidateQueries({
                queryKey: ['board-data'],
            })
        },
    })
}
export const useCreateScoutingReportDraft = (): UseMutationResult<JSONResponse, Error, CreateScoutingReport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreateScoutingReport) =>
            post<undefined, CreateScoutingReport>('/posts/scouting/draft', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useCreatePlayerDevelopmentReport = (): UseMutationResult<
    JSONResponse,
    Error,
    CreatePlayerDevelopmentReport
> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreatePlayerDevelopmentReport) =>
            post<undefined, CreatePlayerDevelopmentReport>('/posts/player-development', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useCreatePlayerDevelopmentReportDraft = (): UseMutationResult<
    JSONResponse,
    Error,
    CreatePlayerDevelopmentReport
> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreatePlayerDevelopmentReport) =>
            post<undefined, CreatePlayerDevelopmentReport>('/posts/player-development/draft', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useCreateCommunityReport = (): UseMutationResult<JSONResponse, Error, CreateCommunityReport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreateCommunityReport) =>
            post<undefined, CreateCommunityReport>('/posts/community', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useCreateCommunityReportDraft = (): UseMutationResult<JSONResponse, Error, CreateCommunityReport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreateCommunityReport) =>
            post<undefined, CreateCommunityReport>('/posts/community/draft', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useCreateGameReport = (): UseMutationResult<JSONResponse, Error, CreateGameReport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreateGameReport) => post<undefined, CreateGameReport>('/posts/game', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useCreateGameReportDraft = (): UseMutationResult<JSONResponse, Error, CreateGameReport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreateGameReport) => post<undefined, CreateGameReport>('/posts/game/draft', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useCreateWorkoutReport = (): UseMutationResult<JSONResponse, Error, CreateWorkoutReport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreateWorkoutReport) => post<undefined, CreateWorkoutReport>('/posts/workout', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

export const useCreateWorkoutReportDraft = (): UseMutationResult<JSONResponse, Error, CreateWorkoutReport> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (report: CreateWorkoutReport) =>
            post<undefined, CreateWorkoutReport>('/posts/workout/draft', report),

        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['posts'],
            }),
    })
}

const queryAllPosts = async (
    type: Enum.PostFilterWithFollowing | undefined,
    search: Except<DTO.AdvancedPostSearch, 'type'>
): Promise<PaginatedResp> => (await get<PaginatedResp>('/posts', { ...search, type })).data

export const useQueryAllPosts = (
    type: Enum.PostFilterWithFollowing | undefined | '',
    searchParams?: Except<DTO.AdvancedPostSearch, 'page'>,
    enabled?: boolean
): UseInfiniteQueryResult<InfiniteQueryPaginatedResp> =>
    useInfiniteQuery({
        queryKey: ['posts', { ...searchParams, type: type || undefined }],
        queryFn: ({ pageParam = 1 }) => queryAllPosts(type || undefined, { ...searchParams, page: pageParam }),
        initialPageParam: 1,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        getNextPageParam: (lastPage) => (lastPage?.info?.hasNextPage ? Number(lastPage.info.nextPage) : null),
        enabled,
    })

const queryPostsByEntity = async (
    entityType: DTO.SearchResultType,
    entityId: string | undefined,
    type: Enum.PostFilter | undefined,
    search: Except<DTO.AdvancedPostSearch, 'type'>
): Promise<PaginatedResp> =>
    (await get<PaginatedResp>(`/posts/${entityType}/${entityId || ''}`, { ...search, type })).data

export const useQueryPostsByEntity = (
    entityType: DTO.SearchResultType,
    entityId: string | undefined,
    type: Enum.PostFilter | undefined | '',
    searchParams?: Except<DTO.AdvancedPostSearch, 'page'>
): UseInfiniteQueryResult<InfiniteQueryPaginatedResp> =>
    useInfiniteQuery({
        queryKey: ['posts', entityType, entityId, { ...searchParams, type: type || undefined }],
        queryFn: ({ pageParam = 1 }) =>
            queryPostsByEntity(entityType, entityId, type || undefined, { ...searchParams, page: pageParam }),
        initialPageParam: 1,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        getNextPageParam: (lastPage) => (lastPage?.info?.hasNextPage ? Number(lastPage.info.nextPage) : null),
        enabled: !!entityId,
    })

export const useGetIntelTextTypes = (
    options?: Except<UseQueryOptions<DTO.IntelTextTypes[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.IntelTextTypes[]> =>
    useQuery({
        queryKey: ['intel-text-types'],
        queryFn: async () => (await get<DTO.IntelTextTypes[]>('/posts/intel/text-types')).data,
        ...options,
    })

const queryIntelTextTypeFeed = async (
    playerId: string,
    dateRange: Enum.DateRanges | undefined,
    intelTextTypes?: Enum.IntelTextTypes[]
): Promise<DTO.IntelTextTypeReport[]> =>
    (await get<DTO.IntelTextTypeReport[]>(`/posts/intel/player/${playerId}`, { dateRange, intelTextTypes })).data

export const useQueryIntelTextTypeFeed = (
    playerId: string | undefined,
    dateRange?: Enum.DateRanges,
    intelTextTypes?: Enum.IntelTextTypes[],
    options?: Omit<
        UseQueryOptions<DTO.IntelTextTypeReport[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.IntelTextTypeReport[]> =>
    useQuery({
        queryKey: ['posts', playerId, intelTextTypes, dateRange],
        queryFn: () => queryIntelTextTypeFeed(playerId!, dateRange, intelTextTypes), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!playerId,
        ...options,
    })

const queryScoutingLocBoxScores = async (
    playerId: string,
    minDate?: string | undefined
): Promise<DTO.ScoutingLocBoxScore[]> =>
    (await get<DTO.ScoutingLocBoxScore[]>(`/posts/scouting/locs/${playerId}`, { minDate })).data

export const useQueryScoutingLocBoxScores = (
    playerId: string | undefined,
    minDate?: string,
    options?: Omit<
        UseQueryOptions<DTO.ScoutingLocBoxScore[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.ScoutingLocBoxScore[]> =>
    useQuery({
        queryKey: ['locs-by-scout', playerId, minDate],
        queryFn: () => queryScoutingLocBoxScores(playerId!, minDate), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!playerId,
        ...options,
    })
