import {
    FeaturePermissionType,
    FeaturePermissionCategory,
    ContentPermissionType,
    ContentPermissionCategory,
} from '../definitions/enum/Permissions'

interface FeaturePermission {
    id: number
    slug: FeaturePermissionType
    display_name: string
    status: 'active' | 'inactive'
    category: FeaturePermissionCategory
}
export const featurePermissions: Readonly<FeaturePermission[]> = [
    { id: 1, slug: 'player-profile', display_name: 'Player Profile', status: 'active', category: 'Page Access' },
    {
        id: 2,
        slug: 'site-management',
        display_name: 'Site Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 3,
        slug: 'comment-management',
        display_name: 'Comment Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    { id: 4, slug: 'post-feed', display_name: 'Post Feed', status: 'active', category: 'Page Access' },
    {
        id: 5,
        slug: 'post-management',
        display_name: 'Post Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    { id: 6, slug: 'team-roster', display_name: 'Roster', status: 'active', category: 'Page Access' },
    {
        id: 7,
        slug: 'author-management',
        display_name: 'Author Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 8,
        slug: 'board-management',
        display_name: 'Board Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    { id: 9, slug: 'custom-boards', display_name: 'Custom Boards', status: 'active', category: 'Page Access' },
    {
        id: 10,
        slug: 'assignment-schedule',
        display_name: 'Assignment Schedule',
        status: 'active',
        category: 'Page Access',
    },
    {
        id: 11,
        slug: 'assignment-schedule-management',
        display_name: 'Assignment Schedule Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 12,
        slug: 'audience-management',
        display_name: 'Audience Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 13,
        slug: 'post-date-management',
        display_name: 'Post Date Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 14,
        slug: 'required-report-exemption',
        display_name: 'Required Report Exemption',
        status: 'active',
        category: 'Validation Exceptions',
    },
    {
        id: 15,
        slug: 'intensity-grade-management',
        display_name: 'Intensity Grade Management',
        status: 'active',
        category: 'Data Input',
    },
    { id: 16, slug: 'player-intel', display_name: 'Player Intel', status: 'active', category: 'Page Access' },
    { id: 17, slug: 'player-personal', display_name: 'Player Personal', status: 'active', category: 'Page Access' },
    {
        id: 18,
        slug: 'player-personal-management',
        display_name: 'Player Personal Management',
        status: 'active',
        category: 'Data Input',
    },
    {
        id: 19,
        slug: 'print-profiles',
        display_name: 'Print Profiles',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 20,
        slug: 'scouting-summary-management',
        display_name: 'Scouting Summary Management',
        status: 'active',
        category: 'Data Input',
    },
    { id: 21, slug: 'contracts', display_name: 'Contracts', status: 'active', category: 'Page Access' },
    {
        id: 22,
        slug: 'player-representation-info-management',
        display_name: 'Player Representation Info Management',
        status: 'active',
        category: 'Data Input',
    },
    { id: 23, slug: 'agent-personal', display_name: 'Agent Personal', status: 'active', category: 'Page Access' },
    {
        id: 24,
        slug: 'agent-personal-management',
        display_name: 'Agent Personal Management',
        status: 'active',
        category: 'Data Input',
    },
    {
        id: 25,
        slug: 'contract-grade-management',
        display_name: 'Contract Grade Input',
        status: 'active',
        category: 'Data Input',
    },
    {
        id: 26,
        slug: 'depth-chart-management',
        display_name: 'Depth Chart Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    { id: 27, slug: 'depth-charts', display_name: 'Depth Charts', status: 'active', category: 'Page Access' },
    {
        id: 28,
        slug: 'create-scouting-reports',
        display_name: 'Create Scouting Reports',
        status: 'active',
        category: 'Page Access',
    },
    { id: 29, slug: 'player-stats', display_name: 'Player Stats', status: 'active', category: 'Page Access' },
    {
        id: 30,
        slug: 'injury-history-notes-management',
        display_name: 'Injury History Notes Management',
        status: 'active',
        category: 'Data Input',
    },
    {
        id: 31,
        slug: 'auto-refresh',
        display_name: 'Auto Refresh',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    { id: 32, slug: 'league-standings', display_name: 'League Standings', status: 'active', category: 'Page Access' },
    {
        id: 33,
        slug: 'configure-draft-assets',
        display_name: 'Configure Draft Assets',
        status: 'active',
        category: 'Data Input',
    },
    {
        id: 34,
        slug: 'edit-draft-notes',
        display_name: 'Edit Draft Notes',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    { id: 35, slug: 'league-draft', display_name: 'NBA Draft', status: 'active', category: 'Page Access' },
    {
        id: 36,
        slug: 'print-draft-materials',
        display_name: 'Print Draft Materials',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 37,
        slug: 'create-game-reports',
        display_name: 'Create Game Reports',
        status: 'active',
        category: 'Page Access',
    },
    { id: 38, slug: 'staff-personal', display_name: 'Staff Personal', status: 'active', category: 'Page Access' },
    {
        id: 39,
        slug: 'staff-personal-management',
        display_name: 'Staff Personal Management',
        status: 'active',
        category: 'Data Input',
    },
    { id: 40, slug: 'agent-workbook', display_name: 'Agent Workbook', status: 'active', category: 'Page Access' },
    {
        id: 41,
        slug: 'print-agent-materials',
        display_name: 'Print Agent Materials',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 42,
        slug: 'print-depth-charts',
        display_name: 'Print Depth Charts',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 43,
        slug: 'data-management',
        display_name: 'Data Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    { id: 44, slug: 'compare-players', display_name: 'Compare Players', status: 'active', category: 'Page Access' },
    { id: 45, slug: 'draft-profile', display_name: 'Workout Profile', status: 'active', category: 'Page Access' },
    {
        id: 46,
        slug: 'draft-staff-note-management',
        display_name: 'Draft Staff Note Management',
        status: 'active',
        category: 'Data Input',
    },
    { id: 47, slug: 'nba-draft-night', display_name: 'NBA Draft Night', status: 'active', category: 'Page Access' },
    {
        id: 48,
        slug: 'nba-draft-night-control-center',
        display_name: 'NBA Draft Control Center',
        status: 'active',
        category: 'Page Access',
    },
    {
        id: 49,
        slug: 'print-draft-night',
        display_name: 'Print Draft Night',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 50,
        slug: 'nba-draft-night-team-center',
        display_name: 'NBA Draft Night Draft Profile',
        status: 'active',
        category: 'Page Access',
    },
    {
        id: 51,
        slug: 'nba-draft-night-team-center-management',
        display_name: 'NBA Draft Night Draft Profile Management',
        status: 'active',
        category: 'Data Input',
    },
    {
        id: 52,
        slug: 'create-workout-reports',
        display_name: 'Create Workout Reports',
        status: 'active',
        category: 'Page Access',
    },
    { id: 53, slug: 'player-signings', display_name: 'Player Signings', status: 'active', category: 'Page Access' },
    {
        id: 54,
        slug: 'player-signings-management',
        display_name: 'Player Signings Management',
        status: 'active',
        category: 'Data Input',
    },
    { id: 55, slug: 'team-schedule', display_name: 'Schedule', status: 'active', category: 'Page Access' },
    { id: 56, slug: 'glg-team-stats', display_name: 'GLG Team Stats', status: 'active', category: 'Page Access' },
    { id: 57, slug: 'nba-team-stats', display_name: 'NBA Team Stats', status: 'active', category: 'Page Access' },
    {
        id: 58,
        slug: 'site-performance',
        display_name: 'Site Performance',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 59,
        slug: 'configure-draft-assets-glg',
        display_name: 'Configure Draft Assets (G League)',
        status: 'active',
        category: 'Data Input',
    },
    {
        id: 60,
        slug: 'glg-draft-night',
        display_name: 'G-League Draft Night',
        status: 'active',
        category: 'Page Access',
    },
    {
        id: 61,
        slug: 'glg-draft-night-control-center',
        display_name: 'G-League Draft Control Center',
        status: 'active',
        category: 'Page Access',
    },
    { id: 62, slug: 'league-draft-glg', display_name: 'G-League Draft', status: 'active', category: 'Page Access' },
    {
        id: 63,
        slug: 'player-tiers-management',
        display_name: 'Player Tiers Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    { id: 64, slug: 'team-profile', display_name: 'Team Profile', status: 'active', category: 'Page Access' },
    {
        id: 65,
        slug: 'team-profile-management',
        display_name: 'Team Profile Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 66,
        slug: 'coverage-management',
        display_name: 'Coverage Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
    {
        id: 67,
        slug: 'bulk-skill-loc-management',
        display_name: 'Bulk Skill/LOC Management',
        status: 'active',
        category: 'Feature Admin/Management',
    },
]

interface ContentPermission {
    id: number
    slug: ContentPermissionType
    display_name: string
    status: 'active' | 'inactive'
    category: ContentPermissionCategory
}
export const contentPermissions: Readonly<ContentPermission[]> = [
    { id: 1, slug: 'locs', display_name: 'Locs', status: 'active', category: 'Player Ratings' },
    { id: 2, slug: 'agent-reports', display_name: 'Agent Reports', status: 'inactive', category: 'Front Office' },
    {
        id: 3,
        slug: 'amateur-intel-medical-physical',
        display_name: 'Am. Medical/Physical Intel',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 4,
        slug: 'amateur-intel-off-court',
        display_name: 'Am. Off-Court Intel',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 5,
        slug: 'amateur-intel-on-court',
        display_name: 'Am. On-Court Intel',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 6,
        slug: 'amateur-intel-strategy',
        display_name: 'Am. Strategy Intel',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 7,
        slug: 'amateur-scouting-reports',
        display_name: 'Am. Scouting Reports',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 8,
        slug: 'pro-intel-medical-physical',
        display_name: 'Pro Medical/Physical Intel',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 9,
        slug: 'pro-intel-off-court',
        display_name: 'Pro Off-Court Intel',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 10,
        slug: 'pro-intel-on-court',
        display_name: 'Pro On-Court Intel',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 11,
        slug: 'pro-intel-strategy',
        display_name: 'Pro Strategy Intel',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 12,
        slug: 'pro-scouting-reports',
        display_name: 'Pro Scouting Reports',
        status: 'active',
        category: 'Scout Reports',
    },
    { id: 13, slug: 'skills', display_name: 'Skills', status: 'active', category: 'Player Ratings' },
    { id: 14, slug: 'trade-reports', display_name: 'Trade Reports', status: 'inactive', category: 'Front Office' },
    { id: 15, slug: 'amateur-boards', display_name: 'Am. Boards', status: 'active', category: 'Front Office' },
    { id: 16, slug: 'pro-boards', display_name: 'Pro Boards', status: 'active', category: 'Front Office' },
    {
        id: 17,
        slug: 'amateur-assignments',
        display_name: 'Create Am. Assignments',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 18,
        slug: 'pro-assignments',
        display_name: 'Create Pro Assignments',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 19,
        slug: 'amateur-source-visible',
        display_name: 'Am. Intel Source Visible',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 20,
        slug: 'pro-source-visible',
        display_name: 'Pro Intel Source Visible',
        status: 'active',
        category: 'Scout Reports',
    },
    {
        id: 21,
        slug: 'intensity-grades',
        display_name: 'Intensity Grades',
        status: 'active',
        category: 'Player Ratings',
    },
    {
        id: 22,
        slug: 'player-representation-info',
        display_name: 'Player Representation Info.',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 23,
        slug: 'player-salary-info',
        display_name: 'Player Salary Info.',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 24,
        slug: 'amateur-player-and-team-search',
        display_name: 'Am. Player and Team Search',
        status: 'active',
        category: 'Front Office',
    },
    { id: 25, slug: 'knicks-community', display_name: 'Knicks Community', status: 'active', category: 'Coach Reports' },
    {
        id: 26,
        slug: 'knicks-player-development-coaching',
        display_name: 'Knicks Player Dev. Coaching',
        status: 'active',
        category: 'Coach Reports',
    },
    {
        id: 27,
        slug: 'knicks-player-development-physical',
        display_name: 'Knicks Player Dev. Physical',
        status: 'active',
        category: 'Coach Reports',
    },
    {
        id: 28,
        slug: 'westchester-community',
        display_name: 'Westchester Community',
        status: 'active',
        category: 'Coach Reports',
    },
    {
        id: 29,
        slug: 'westchester-player-development-coaching',
        display_name: 'Westchester Player Dev. Coaching',
        status: 'active',
        category: 'Coach Reports',
    },
    {
        id: 30,
        slug: 'westchester-player-development-physical',
        display_name: 'Westchester Player Dev. Physical',
        status: 'active',
        category: 'Coach Reports',
    },
    { id: 31, slug: 'measurements', display_name: 'Measurements', status: 'active', category: 'Player Ratings' },
    {
        id: 32,
        slug: 'player-draft-info',
        display_name: 'Player Draft Info',
        status: 'active',
        category: 'Player Ratings',
    },
    {
        id: 33,
        slug: 'mock-draft-model',
        display_name: 'Mock Draft Model',
        status: 'active',
        category: 'Player Ratings',
    },
    {
        id: 34,
        slug: 'scouting-summary',
        display_name: 'Scouting Summary',
        status: 'active',
        category: 'Player Ratings',
    },
    {
        id: 35,
        slug: 'player-contract-history',
        display_name: 'Player Contract History',
        status: 'active',
        category: 'Front Office',
    },
    { id: 36, slug: 'contract-grades', display_name: 'Contract Grades', status: 'active', category: 'Front Office' },
    {
        id: 38,
        slug: 'roster-likelihood',
        display_name: 'Roster Likelihood',
        status: 'active',
        category: 'Player Ratings',
    },
    { id: 39, slug: 'strategy-locs', display_name: 'Strategy Locs', status: 'active', category: 'Player Ratings' },
    {
        id: 40,
        slug: 'player-profile-glg',
        display_name: 'Player Profile (G League Only)',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 41,
        slug: 'post-feed-glg',
        display_name: 'Post Feed (G League Only)',
        status: 'active',
        category: 'Scout Reports',
    },
    { id: 42, slug: 'injury-history', display_name: 'Injury History', status: 'active', category: 'Front Office' },
    { id: 43, slug: 'positions', display_name: 'Positions', status: 'active', category: 'Front Office' },
    { id: 44, slug: 'glg-depth-charts', display_name: 'GLG Depth Charts', status: 'active', category: 'Front Office' },
    { id: 45, slug: 'nba-depth-charts', display_name: 'NBA Depth Charts', status: 'active', category: 'Front Office' },
    {
        id: 46,
        slug: 'injury-history-notes',
        display_name: 'Injury History Notes',
        status: 'active',
        category: 'Front Office',
    },
    { id: 47, slug: 'glg-boards', display_name: 'G-League Boards', status: 'active', category: 'Front Office' },
    {
        id: 48,
        slug: 'position-versatility',
        display_name: 'Position Versatility',
        status: 'active',
        category: 'Player Ratings',
    },
    {
        id: 49,
        slug: 'predictive-standings',
        display_name: 'Predictive Standings',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 50,
        slug: 'predictive-standings-projection-models',
        display_name: 'Predictive Standings Projection Models',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 51,
        slug: 'glg-game-reports',
        display_name: 'Westchester Game Reports',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 52,
        slug: 'playoff-seedings-matchups',
        display_name: 'Playoff Seedings/Matchups',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 53,
        slug: 'individual-loc-history-am',
        display_name: 'Individual Am LOC History',
        status: 'active',
        category: 'Player Ratings',
    },
    {
        id: 54,
        slug: 'individual-loc-history-pro',
        display_name: 'Individual Pro LOC History',
        status: 'active',
        category: 'Player Ratings',
    },
    {
        id: 55,
        slug: 'player-management',
        display_name: 'Player Management',
        status: 'active',
        category: 'Data Management',
    },
    {
        id: 56,
        slug: 'scouting-event-management',
        display_name: 'Scouting Event Management',
        status: 'active',
        category: 'Data Management',
    },
    {
        id: 57,
        slug: 'draft-staff-notes-logistics',
        display_name: 'Draft Staff Notes Logistics',
        status: 'active',
        category: 'Front Office',
    },
    { id: 58, slug: 'workout-reports', display_name: 'Workout Reports', status: 'active', category: 'Front Office' },
    {
        id: 59,
        slug: 'nba-game-reports',
        display_name: 'Knicks Game Reports',
        status: 'active',
        category: 'Front Office',
    },
    { id: 60, slug: 'player-tiers', display_name: 'Player Tiers', status: 'active', category: 'Player Ratings' },
    { id: 61, slug: 'practice-shots', display_name: 'Practice Shots', status: 'active', category: 'Front Office' },
    {
        id: 62,
        slug: 'team-profile-output',
        display_name: 'Team Profile Output',
        status: 'active',
        category: 'Front Office',
    },
    {
        id: 63,
        slug: 'individual-skills-history-am',
        display_name: 'Individual Am Skills History',
        status: 'active',
        category: 'Player Ratings',
    },
    {
        id: 64,
        slug: 'individual-skills-history-pro',
        display_name: 'Individual Pro Skills History',
        status: 'active',
        category: 'Player Ratings',
    },
    { id: 65, slug: 'am-coverage', display_name: 'Am Coverage', status: 'active', category: 'Front Office' },
    { id: 66, slug: 'pro-coverage', display_name: 'Pro Coverage', status: 'active', category: 'Front Office' },
    {
        id: 67,
        slug: 'bulk-skill-loc-input',
        display_name: 'Bulk Skill/LOC Input',
        status: 'active',
        category: 'Player Ratings',
    },
]
