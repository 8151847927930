import type { UseQueryResult } from '@tanstack/react-query'
import type { AgentInfoFilterParams } from '@/components/player-agents/AgentInfoFilters'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

type QueryKey = ['agents']

export const agentsQueryKey: QueryKey = ['agents']

const apiPath = '/agents'

const getAgentInfo = async (filters: AgentInfoFilterParams): Promise<DTO.AgentInfo[]> =>
    (await get<DTO.AgentInfo[]>(`${apiPath}/agent-info`, filters)).data

export const useGetAgentInfo = (filters: AgentInfoFilterParams): UseQueryResult<DTO.AgentInfo[]> =>
    useQuery({
        queryKey: [...agentsQueryKey, 'agent-info', filters],
        queryFn: () => getAgentInfo(filters),
    })

export const getAgentByPlayerId = async (playerId: string): Promise<DTO.Agent> =>
    (await get<DTO.Agent>(`${apiPath}/by-player-id/${playerId}`)).data

const useGetAgentByPlayerId = (playerId: string): UseQueryResult<DTO.Agent> =>
    useQuery({
        queryKey: [...agentsQueryKey],
        queryFn: () => getAgentByPlayerId(playerId),
    })

const getAgentById = async (agentId: string): Promise<DTO.Agent> => (await get<DTO.Agent>(`${apiPath}/${agentId}`)).data

export const useGetAgentById = (agentId: string | undefined): UseQueryResult<DTO.Agent> =>
    useQuery({
        queryKey: [...agentsQueryKey, agentId],
        queryFn: () => getAgentById(agentId!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled: !!agentId,
    })

export default useGetAgentByPlayerId
