import type { JSONResponse } from '../api'
import type { UseQueryOptions, UseQueryResult, UseMutationResult } from '@tanstack/react-query'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { get, post } from '../api'

export const useUserWatchListItems = (): UseQueryResult<{ boards: string[]; players: DTO.PostTag[] }> =>
    useQuery({
        queryKey: ['userWatchList'],
        queryFn: async () => (await get<{ boards: string[]; players: DTO.PostTag[] }>('/user-watch-list')).data,
    })

const getUserWatchListInfo = async (): Promise<DTO.UserWatchListInfo[] | ''> =>
    (await get<DTO.UserWatchListInfo[] | ''>('/user-watch-list/info')).data

const useUserWatchList = (
    options?: Omit<UseQueryOptions<DTO.UserWatchListInfo[] | ''>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.UserWatchListInfo[] | ''> =>
    useQuery({
        queryKey: ['userWatchListInfo'],
        queryFn: () => getUserWatchListInfo(),
        ...options,
    })

type AddItemInput = { players: string[]; boards: string[] }
type AddItemResp = { message?: string; warning?: string }

export const useAddItemToWatchList = (): UseMutationResult<JSONResponse<AddItemResp>, Error, AddItemInput> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (items: AddItemInput) => post<AddItemResp, AddItemInput>(`/user-watch-list/`, items),

        onSettled: async () => {
            void queryClient.invalidateQueries({
                queryKey: ['userWatchListInfo'],
            })
            await queryClient.invalidateQueries({
                queryKey: ['userWatchList'],
            })
        },
    })
}

const getWatchListPlayerInfo = async (playerId: string, season: number): Promise<DTO.WatchListPlayerInfo> =>
    (await get<DTO.WatchListPlayerInfo>(`/user-watch-list/info/${playerId}/${season}`)).data

export const useWatchListPlayerInfo = (
    playerId: string,
    season: number,
    options?: Omit<UseQueryOptions<DTO.WatchListPlayerInfo>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.WatchListPlayerInfo> =>
    useQuery({
        queryKey: ['userWatchList', playerId],
        queryFn: () => getWatchListPlayerInfo(playerId, season),
        ...options,
    })

export default useUserWatchList
