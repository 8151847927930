import type { Except } from 'type-fest'
import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { AttachFileFormValues } from '../../components/form/AttachFiles/AttachFilesForm'
import type { JSONResponse } from '../api'
import { useQuery, useMutation } from '@tanstack/react-query'
import { get, post } from '../api'
import useToastContext from './useToastContext'

export const useUploadAttachments = (): UseMutationResult<
    JSONResponse<DTO.Attachment[]>,
    Error,
    AttachFileFormValues
> => {
    const toastContext = useToastContext()
    return useMutation({
        mutationFn: async ({ info, files }) => {
            const formData = new FormData()
            for (let i = 0; i < files.length; i += 1) {
                formData.append('files', files[i])
            }
            const { data } = await post<DTO.AttachmentUpload[], FormData>('/attachment-upload', formData, info)
            return post<DTO.Attachment[], DTO.AttachmentUpload[]>('/attachments', data)
        },

        onSuccess: () => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Upload Successful',
                duration: 3000,
            })
        },

        onError: () => {
            toastContext?.addToast({
                severity: 'error',
                message: 'Upload Failed',
                duration: 3000,
            })
        },
    })
}

export const useQueryAttachments = (
    params: DTO.AttachmentQuery,
    options: Except<UseQueryOptions<DTO.Attachment[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Attachment[]> =>
    useQuery({
        queryKey: ['attachments', params],
        queryFn: async () => (await get<DTO.Attachment[]>('/attachments', params)).data,
        ...options,
    })

export const useGetAttachment = (
    attachmentId: string | null | undefined,
    options?: Except<UseQueryOptions<DTO.Attachment[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Attachment> =>
    useQuery({
        queryKey: ['attachment', attachmentId],

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        queryFn: async () => (await get<DTO.Attachment>(`/attachments/${attachmentId}`)).data,

        enabled: !!attachmentId && options?.enabled !== false,
    })
