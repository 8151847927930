import type { OverrideProperties, Except } from 'type-fest'
import type { UseInfiniteQueryResult, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import type { InfiniteQueryPaginatedResp, PaginatedResp } from './usePosts'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { useConstantsContext } from '../contexts/ConstantsContext'
import { get, post, put, remove } from '../api'
import { isNumber } from '../utils/math'
import useToastContext from './useToastContext'

const getPlayerEvaluation = async (
    teamId: string,
    season: number,
    league: Enum.League
): Promise<DTO.PlayerEvaluation[]> =>
    (await get<DTO.PlayerEvaluation[]>(`/team-profile/${teamId}/player-evaluation`, { season, league })).data

export const usePlayerEvaluation = (
    teamId: string | undefined,
    season: number | undefined,
    league: Enum.League | undefined,
    options?: Omit<
        UseQueryOptions<DTO.PlayerEvaluation[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerEvaluation[]> =>
    useQuery<DTO.PlayerEvaluation[]>({
        queryKey: ['team-profile', 'player-evaluation', teamId, season, league],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getPlayerEvaluation(teamId!, season!, league!),
        ...options,
        enabled: !!teamId && !!season && !!league && options?.enabled !== false,
    })

const getTeamSalaryCap = async (
    teamId: string,
    params: Partial<{ season: number; includeCapHolds: boolean }>
): Promise<DTO.TeamSalaryCapSeason[]> =>
    (await get<DTO.TeamSalaryCapSeason[]>(`/team-profile/${teamId}/salary-cap`, params)).data

export const useTeamSalaryCap = (
    teamId: string | undefined,
    season: number | undefined,
    params?: Partial<{ includeCapHolds: boolean }>,
    options?: Omit<
        UseQueryOptions<DTO.TeamSalaryCapSeason[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.TeamSalaryCapSeason[]> =>
    useQuery<DTO.TeamSalaryCapSeason[]>({
        queryKey: ['team-profile', 'salary-cap', teamId, season, !!params?.includeCapHolds],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getTeamSalaryCap(teamId!, { season, ...params }),
        ...options,
        enabled: !!teamId && !!season && options?.enabled !== false,
    })

const queryTeamProfileNotes = async (params: DTO.QueryTeamProfileNotes): Promise<PaginatedNotesResp> =>
    (await get<PaginatedNotesResp>('/team-profile/notes', params)).data

type PaginatedNotesResp = PaginatedResp<DTO.TeamProfileNote>
type InfiniteQueryPaginatedNotesResp = InfiniteQueryPaginatedResp<DTO.TeamProfileNote>
type UseTeamProfileNotes = OverrideProperties<Except<DTO.QueryTeamProfileNotes, 'page'>, { teamId?: string | null }>
export const useTeamProfileNotes = ({
    teamId,
    noteId,
    ...params
}: UseTeamProfileNotes): UseInfiniteQueryResult<InfiniteQueryPaginatedNotesResp> => {
    const queryParams: DTO.QueryTeamProfileNotes = { teamId: teamId || undefined, noteId, ...params }
    return useInfiniteQuery<PaginatedNotesResp>({
        queryKey: ['team-profile', 'notes', queryParams],
        queryFn: ({ pageParam = 1 }) => queryTeamProfileNotes({ ...queryParams, page: pageParam as number }),
        initialPageParam: 1,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        getNextPageParam: (lastPage) => (lastPage?.info?.hasNextPage ? Number(lastPage.info.nextPage) : null),
        enabled: teamId !== undefined || !!noteId,
    })
}
export const useTeamProfileNoteEntities = ({
    teamId,
    ...params
}: Pick<UseTeamProfileNotes, 'teamId'>): UseQueryResult<DTO.TeamProfileNoteObject[]> => {
    const queryParams: DTO.QueryTeamProfileNoteEntities = { teamId: teamId || undefined, ...params }
    return useQuery({
        queryKey: ['team-profile', 'notes', 'entities', queryParams],
        queryFn: async () => (await get<DTO.TeamProfileNoteObject[]>('/team-profile/notes/entities', queryParams)).data,
    })
}

const createTeamProfileNote = async (note: DTO.CreateTeamProfileNote): Promise<DTO.TeamProfileNote> =>
    (await post<DTO.TeamProfileNote, DTO.CreateTeamProfileNote>(`/team-profile/${note.teamId}/notes`, note)).data

export const useCreateTeamProfileNote = (): UseMutationResult<
    DTO.TeamProfileNote,
    unknown,
    DTO.CreateTeamProfileNote
> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (note: DTO.CreateTeamProfileNote) => createTeamProfileNote(note),
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['team-profile', 'notes'] })
            await queryClient.invalidateQueries({ queryKey: ['search'] })
        },
    })
}

const getTeamProfileHeader = async ({
    teamId,
    season,
    league,
    simDate,
}: {
    teamId: string
    season: number
    league: DTO.Team['leagueId']
    simDate?: string
}): Promise<DTO.TeamHeader> =>
    (await get<DTO.TeamHeader>(`/team-profile/${teamId}/team-header`, { teamId, season, league, simDate })).data

export const useTeamProfileHeader = ({
    teamId,
    season,
    league,
    options,
}: {
    teamId?: string
    season?: number
    league?: DTO.Team['leagueId']
    options?: Omit<UseQueryOptions<DTO.TeamHeader>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
}): UseQueryResult<DTO.TeamHeader> => {
    const { data: session } = useSession()
    const canViewTeamStats =
        (league === 'G-League' && session?.roles.featurePermissions['glg-team-stats']) ||
        (league === 'NBA' && session?.roles.featurePermissions['nba-team-stats'])
    return useQuery<DTO.TeamHeader>({
        queryKey: ['team-profile', 'header', teamId, season, league],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getTeamProfileHeader({ teamId: teamId!, season: season!, league: league! }),
        ...options,
        enabled: !!teamId && !!season && !!league && !!canViewTeamStats && options?.enabled !== false,
    })
}

const updateTeamProfileNote = async (note: DTO.CreateTeamProfileNote): Promise<DTO.TeamProfileNote> =>
    (
        await put<DTO.TeamProfileNote, DTO.CreateTeamProfileNote>(
            `/team-profile/${note.teamId}/notes/${note.noteId}`,
            note
        )
    ).data

export const useUpdateTeamProfileNote = (): UseMutationResult<
    DTO.TeamProfileNote,
    unknown,
    DTO.CreateTeamProfileNote
> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (note: DTO.CreateTeamProfileNote) => updateTeamProfileNote(note),
        onMutate: async (note) => {
            const { teamId } = note
            await queryClient.cancelQueries({ queryKey: ['team-profile', 'notes', teamId] })
            const previousNotes = queryClient.getQueryData<DTO.TeamProfileNote[]>(['team-profile', 'notes', teamId])
            queryClient.setQueryData<DTO.CreateTeamProfileNote[]>(
                ['team-profile', 'notes', teamId],
                (old) => old?.map((n) => (n.noteId === note.noteId ? note : n)) ?? previousNotes
            )
            return { previousNotes }
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['team-profile', 'notes'] })
            await queryClient.invalidateQueries({ queryKey: ['search'] })
        },
    })
}

export const useDeleteTeamProfileNote = (
    teamId: string | undefined
): UseMutationResult<JSONResponse, Error, { noteId: string }> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ noteId }: { noteId: string }) => remove(`/team-profile/${teamId as string}/notes/${noteId}`),
        onMutate: async ({ noteId }) => {
            await queryClient.cancelQueries({ queryKey: ['team-profile', 'notes', teamId] })
            const previousNotes = queryClient.getQueryData<DTO.TeamProfileNote[]>(['team-profile', 'notes', teamId])
            queryClient.setQueryData<DTO.TeamProfileNote[]>(
                ['team-profile', 'notes', teamId],
                (old) => old?.filter((n) => n.noteId !== noteId) ?? previousNotes
            )
            return { previousNotes }
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['team-profile', 'notes'] })
        },
    })
}

const queryTeamMinutesDistribution = async ({
    teamId,
    season,
}: {
    teamId: string
    season: number
}): Promise<DTO.MinutesDistribution[]> =>
    (await get<DTO.MinutesDistribution[]>(`/team-profile/${teamId}/minutes-distribution`, { teamId, season })).data

export const useTeamMinutesDistribution = (
    teamId: string | undefined,
    season: number | undefined,
    options?: Omit<
        UseQueryOptions<DTO.MinutesDistribution[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.MinutesDistribution[]> =>
    useQuery<DTO.MinutesDistribution[]>({
        queryKey: ['team-profile', 'minutes-distribution', teamId],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => queryTeamMinutesDistribution({ teamId: teamId!, season: season! }),
        ...options,
        enabled: !!teamId && !!season && options?.enabled !== false,
    })

export const useDeleteMinutesDistribution = (
    teamId: string | undefined
): UseMutationResult<JSONResponse, Error, DTO.MinutesDistribution> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()

    return useMutation({
        mutationFn: (player: DTO.MinutesDistribution) =>
            remove(`/team-profile/${player.id}/minutes-distribution/${player.id}`),
        onError: (_err, deletedPlayer, context) => {
            toastContext?.addToast({
                severity: 'error',
                message: 'Delete Failed',
            })
            queryClient.setQueryData(
                ['team-profile', 'minutes-distribution', deletedPlayer.teamId],
                context?.previousMinutesDistributions
            )
        },
        onSuccess: () => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Minutes Deleted',
            })
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['team-profile', 'minutes-distribution', teamId] })
        },
        onMutate: (deletedPlayer: DTO.MinutesDistribution) => {
            const previousMinutesDistributions: DTO.MinutesDistribution[] | undefined = queryClient.getQueryData([
                'team-profile',
                'minutes-distribution',
                deletedPlayer.teamId,
            ])

            if (previousMinutesDistributions) {
                const updatedMinutesDistribution: DTO.MinutesDistribution[] = previousMinutesDistributions.filter(
                    (d) => d.id !== deletedPlayer.id
                )
                queryClient.setQueryData(
                    ['team-profile', 'minutes-distribution', deletedPlayer.teamId],
                    updatedMinutesDistribution
                )
            }
            return { previousMinutesDistributions }
        },
    })
}

const updateMinutesDistribution = async (player: DTO.MinutesDistribution): Promise<DTO.MinutesDistribution> =>
    (
        await put<DTO.MinutesDistribution, DTO.MinutesDistribution>(
            `/team-profile/${player.teamId}/minutes-distribution/${player.id}`,
            player
        )
    ).data

export const useUpdateMinutesDistribution = (
    teamId: string | undefined,
    successMessage: string
): UseMutationResult<DTO.MinutesDistribution, unknown, DTO.MinutesDistribution> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation({
        mutationFn: (player: DTO.MinutesDistribution) => updateMinutesDistribution(player),
        onMutate: (player) => {
            const previousMinutesDistributions = queryClient.getQueryData<DTO.MinutesDistribution[]>([
                'team-profile',
                'minutes-distribution',
                teamId,
            ])
            queryClient.setQueryData<DTO.MinutesDistribution[]>(
                ['team-profile', 'minutes-distribution', player.teamId],
                (prev) => prev?.map((d) => (d.id === player.id ? player : d))
            )
            return { previousMinutesDistributions }
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['team-profile', 'minutes-distribution', teamId] })
        },
        onSuccess: () => {
            toastContext?.addToast({
                severity: 'success',
                message: successMessage,
            })
        },
        onError: (_err, player, context) => {
            toastContext?.addToast({
                severity: 'error',
                message: 'Update Failed',
            })
            queryClient.setQueryData(
                ['team-profile', 'minutes-distribution', player.teamId],
                context?.previousMinutesDistributions
            )
        },
    })
}

const addMinutesDistributions = async (
    players: Partial<DTO.AddMinutesDistribution>[],
    teamId: string
): Promise<Partial<DTO.AddMinutesDistribution>[]> =>
    (
        await post<Partial<DTO.AddMinutesDistribution>[], Partial<DTO.AddMinutesDistribution>[]>(
            `/team-profile/${teamId}/minutes-distribution`,
            players
        )
    ).data

export const useAddMinutesDistributions = (
    teamId: string
): UseMutationResult<Partial<DTO.AddMinutesDistribution>[], unknown, Partial<DTO.AddMinutesDistribution>[]> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation({
        mutationFn: (players: Partial<DTO.AddMinutesDistribution>[]) => addMinutesDistributions(players, teamId),
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['team-profile', 'minutes-distribution', teamId] })
        },
        onSuccess: () => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Player Added',
            })
        },
        onError: () => {
            toastContext?.addToast({
                severity: 'error',
                message: 'Failed to Add Player',
            })
        },
    })
}

export const createTeamProfileRoster = async (
    teamId: string,
    userId: number,
    season: number,
    contractId: string,
    roster: DTO.TeamProfileRoster
): Promise<DTO.TeamProfileRoster> =>
    (
        await post<DTO.TeamProfileRoster, DTO.TeamProfileRoster>(
            `/team-profile/${teamId}/roster/${season}/${contractId}/${userId}`,
            roster
        )
    ).data

export const useCreateTeamProfileRoster = (
    teamId: string | undefined,
    userId: number | undefined
): UseMutationResult<DTO.TeamProfileRoster, unknown, DTO.TeamProfileRoster> => {
    const queryClient = useQueryClient()
    const { salaryYear } = useConstantsContext()
    return useMutation({
        mutationFn: (roster: DTO.TeamProfileRoster) =>
            createTeamProfileRoster(teamId as string, userId as number, salaryYear, roster.contractId, roster),
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['team-profile', 'roster', teamId, userId] })
        },
    })
}

export const queryTeamProfileRoster = async (teamId: string, userId: number): Promise<DTO.TeamProfileRoster[]> =>
    (await get<DTO.TeamProfileRoster[]>(`/team-profile/${teamId}/roster/${userId}`)).data

export const useTeamProfileRoster = (
    teamId: string | undefined,
    userId: number | undefined,
    options?: Omit<UseQueryOptions<DTO.TeamProfileRoster[]>, 'queryKey' | 'queryFn' | 'refetchInterval'>
): UseQueryResult<DTO.TeamProfileRoster[]> =>
    useQuery<DTO.TeamProfileRoster[]>({
        queryKey: ['team-profile', 'roster', teamId, userId],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => queryTeamProfileRoster(teamId!, userId!),
        ...options,
        enabled: !!teamId && isNumber(userId) && options?.enabled !== false,
    })

export const updateTeamProfileRoster = async (
    teamId: string,
    userId: number,
    season: number,
    contractId: string,
    roster: DTO.TeamProfileRoster
): Promise<DTO.TeamProfileRoster> =>
    (
        await put<DTO.TeamProfileRoster, DTO.TeamProfileRoster>(
            `/team-profile/${teamId}/roster/${season}/${contractId}/${userId}`,
            roster
        )
    ).data

export const useUpdateTeamProfileRoster = (
    teamId: string | undefined,
    userId: number | undefined
): UseMutationResult<DTO.TeamProfileRoster, unknown, DTO.TeamProfileRoster> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (roster: DTO.TeamProfileRoster) =>
            updateTeamProfileRoster(teamId as string, userId as number, roster.season, roster.contractId, roster),
        onMutate: async (updatedRoster: DTO.TeamProfileRoster) => {
            await queryClient.cancelQueries({ queryKey: ['team-profile', 'roster', teamId, userId] })
            const previousRoster = queryClient.getQueryData<DTO.TeamProfileRoster[]>([
                'team-profile',
                'roster',
                teamId,
                userId,
            ])
            queryClient.setQueryData<DTO.TeamProfileRoster[]>(
                ['team-profile', 'roster', teamId, userId],
                (old) =>
                    old?.map((r) =>
                        r.contractId === updatedRoster.contractId &&
                        r.userId === userId &&
                        r.season === updatedRoster.season
                            ? updatedRoster
                            : r
                    ) ?? previousRoster
            )
            return { previousRoster }
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: ['team-profile', 'roster', teamId, userId] })
            await queryClient.invalidateQueries({ queryKey: ['team-profile', 'roster', teamId, 0] })
        },
    })
}

export const getTeamOwnerByTeamId = async (teamId: string): Promise<DTO.TeamOwner | null> =>
    (await get<DTO.TeamOwner | null>(`/team-profile/${teamId}/team-owner`)).data

export const useGetTeamOwnerByTeamId = (
    teamId: string | undefined,
    options?: Omit<UseQueryOptions<DTO.TeamOwner | null>, 'queryKey' | 'queryFn' | 'refetchInterval'>
): UseQueryResult<DTO.TeamOwner | null> =>
    useQuery<DTO.TeamOwner | null>({
        queryKey: ['team-profile', 'team-owner', teamId],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryFn: () => getTeamOwnerByTeamId(teamId!),
        ...options,
        enabled: !!teamId && options?.enabled !== false,
    })
