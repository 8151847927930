import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import type { Except } from 'type-fest'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { put, post, get } from '../api'

export type StaffAffiliationCreateUpdateValues = Except<
    DTO.StaffAffiliation,
    'updatedAt' | 'updatedBy' | 'team' | 'staffAffiliationId'
> & {
    team: { teamId: string; name: string } | null
    staffAffiliationId?: string
}

export const useCreateStaffAffiliation = (
    urlSlug: string
): UseMutationResult<JSONResponse<StaffAffiliationCreateUpdateValues>, Error, StaffAffiliationCreateUpdateValues> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (values: StaffAffiliationCreateUpdateValues) =>
            post<StaffAffiliationCreateUpdateValues, StaffAffiliationCreateUpdateValues>('/staff-affiliations', values),

        onSuccess: async () => queryClient.invalidateQueries({
            queryKey: ['staff-affiliations', urlSlug]
        })
    });
}

export const useUpdateStaffAffiliation = (
    urlSlug: string
): UseMutationResult<JSONResponse<StaffAffiliationCreateUpdateValues>, Error, StaffAffiliationCreateUpdateValues> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (updatedStaffAffiliation: StaffAffiliationCreateUpdateValues) =>
            put<StaffAffiliationCreateUpdateValues, StaffAffiliationCreateUpdateValues>(
                `/staff-affiliations/${updatedStaffAffiliation.staffAffiliationId as string}`,
                updatedStaffAffiliation
            ),

        onSuccess: async () => queryClient.invalidateQueries({
            queryKey: ['staff-affiliations', urlSlug]
        })
    });
}

export const queryStaffAffiliationsByUrlSlug = async (urlSlug: string | undefined): Promise<DTO.StaffAffiliation[]> =>
    (await get<DTO.StaffAffiliation[]>('staff-affiliations', { urlSlug })).data

export const useQueryStaffAffiliationsByUrlSlug = (
    urlSlug: string | undefined
): UseQueryResult<DTO.StaffAffiliation[]> =>
    useQuery({
        queryKey: ['staff-affiliations', urlSlug],
        queryFn: () => queryStaffAffiliationsByUrlSlug(urlSlug),
        enabled: !!urlSlug
    })
