import type { UseQueryResult, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '@/lib/api'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, post, remove } from '@/lib/api'

const queryKey = ['intel-contacts']
export const useIntelContacts = (): UseQueryResult<DTO.IntelContact[]> =>
    useQuery({
        queryKey,
        queryFn: async () => (await get<DTO.IntelContact[]>('/intel-contacts')).data,
    })

export const useUpsertIntelContact = (): UseMutationResult<JSONResponse, Error, DTO.UpsertIntelContact> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (data: DTO.UpsertIntelContact) => post('/intel-contacts', data),
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey })
            void queryClient.invalidateQueries({ queryKey: ['coverage', 'intel'] })
            void queryClient.invalidateQueries({ queryKey: ['search'] })
        },
    })
}

export const useDeleteIntelContact = (): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (id) => remove(`/intel-contacts/${id}`),
        onSuccess: (_, id) => {
            void queryClient.invalidateQueries({ queryKey: ['coverage', 'intel'] })

            const queryData = queryClient.getQueryData<DTO.IntelContact[]>(queryKey)
            if (queryData) {
                queryClient.setQueryData(
                    queryKey,
                    queryData.filter((c) => c.intelContactId !== id)
                )
            }
        },
    })
}
