import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { get } from '../api'

type RosterLikelihoodQueryParams = {
    playerId: string | undefined
    teamId: string | null | undefined
    contractId: string | undefined
}

const getRosterLikelihood = async ({
    playerId,
    teamId,
    contractId,
}: {
    playerId: string
    teamId: string
    contractId: string
}): Promise<DTO.RosterLikelihood | ''> =>
    (await get<DTO.RosterLikelihood | ''>(`/roster-likelihoods/${playerId}/${teamId}/${contractId}`)).data

export const useGetRosterLikelihood = ({
    playerId,
    teamId,
    contractId,
    options,
}: RosterLikelihoodQueryParams & {
    options?: Omit<
        UseQueryOptions<DTO.IntensityGrade | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.RosterLikelihood | ''> => {
    const { data: session } = useSession()
    return useQuery({
        queryKey: ['rosterLikelihoods', { playerId, teamId, contractId }],
        queryFn: () => getRosterLikelihood({ playerId: playerId!, teamId: teamId!, contractId: contractId! }), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enabled:
            !!playerId &&
            !!teamId &&
            !!contractId &&
            !!session?.roles.contentPermissions['roster-likelihood'] &&
            options?.enabled !== false,
    })
}
