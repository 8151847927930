import type { UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { post, remove } from '../api'

type UserGroupContentPermissionParams = {
    userGroup: string
    contentPermission: string
}

type QueryKey = ['userGroupsWithPermissions']

export const userGroupContentPermissionsQueryKey: QueryKey = ['userGroupsWithPermissions']

const apiPath = '/user-groups/content-permissions'

export const useCreateUserGroupContentPermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<DTO.UserGroupContentPermission>, Error, UserGroupContentPermissionParams> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (newUserGroupContentPermission: UserGroupContentPermissionParams) =>
            post<DTO.UserGroupContentPermission, UserGroupContentPermissionParams>(
                apiPath,
                newUserGroupContentPermission
            ),

        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    })
}

export const useDeleteUserGroupContentPermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<undefined>, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (userGroupContentPermissionSlug: string) => remove(`${apiPath}/${userGroupContentPermissionSlug}`),
        onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    })
}
