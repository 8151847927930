'use client'

import type { UseMutationResult, QueryKey } from '@tanstack/react-query'
import type { GraphQLResult } from 'aws-amplify/api'
import React, { useEffect } from 'react'
import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import createCtx from '../createCtx'
import {
    onCreateDraftNightEvent,
    onUpdateDraftNightEvent,
    onDeleteDraftNightEvent,
    onCreateDraftEntrant,
    onCreatePickOrder,
    onDeleteDraftEntrant,
    onUpdateDraftEntrant,
    onUpdatePickOrder,
} from './subscriptions'
import { getDraftNightEvent, listDraftEntrants, listPickOrders } from './queries'
import {
    createDraftNightEvent,
    updateDraftNightEvent,
    deleteDraftNightEvent,
    createDraftEntrant,
    createPickOrder,
    updateDraftEntrant,
    updatePickOrder,
} from './mutations'
import { asNumber } from '@/lib/utils/math'

Amplify.configure({
    API: {
        GraphQL: {
            endpoint: process.env.NEXT_PUBLIC_AWS_APP_SYNC_API_ENDPOINT as string,
            region: 'us-east-1',
            defaultAuthMode: 'apiKey',
            apiKey: process.env.NEXT_PUBLIC_AWS_APP_SYNC_API_KEY as string,
        },
    },
})
const client = generateClient()

export type DraftApiClient = ReturnType<typeof generateClient<never>>
const [useDraftApiClient, DraftApiContext] = createCtx<DraftApiClient>()

type DraftApiClientProviderProps = {
    children: React.ReactNode
}
export default ({ children }: DraftApiClientProviderProps): JSX.Element => (
    <DraftApiContext value={client}>{children}</DraftApiContext>
)

type DraftNightEvents =
    | 'draftPlayer'
    | 'makePick'
    | 'resetPick'
    | 'changePickOrder'
    | 'makeSigning'
    | 'createBanner'
    | 'draftEntrants'
    | 'editEntrant'

const getDraftApiTypeQueryKey = (draft: number, type: DraftNightEvents): QueryKey => ['draft-night', draft, type]
const getLeagueDraftID = (draftYear: number, league: Enum.DraftLeague) =>
    league === 'G-League' ? -draftYear : draftYear

export function useSubscribeToDraftEntrantChanges(
    draftYear: number,
    league: Enum.DraftLeague,
    subscribeToChanges = true
): DTO.DraftEntrant[] | null | undefined {
    const draft = getLeagueDraftID(draftYear, league)
    const queryClient = useQueryClient()
    const draftApiClient = useDraftApiClient()

    // query the initial data on load
    const { data: draftApiData, isLoading } = useQuery({
        queryKey: getDraftApiTypeQueryKey(draft, 'draftEntrants'),
        queryFn: () =>
            draftApiClient.graphql({ query: listDraftEntrants, variables: { filter: { draft: { eq: draft } } } }),
    })

    // subscribe to the events
    useEffect(() => {
        if (!subscribeToChanges) return () => {}

        const queryKey = getDraftApiTypeQueryKey(draft, 'draftEntrants')
        const createSub = draftApiClient.graphql({ query: onCreateDraftEntrant, variables: { draft } }).subscribe({
            next: ({ data: { onCreateDraftEntrant: item } }) => {
                const existingData = queryClient.getQueryData<typeof draftApiData>(queryKey)
                const items = existingData?.data.listDraftEntrants?.items
                if (!items?.some((i) => i?.playerId === item.playerId)) {
                    queryClient.setQueryData(queryKey, {
                        data: {
                            listDraftEntrants: {
                                items: [...(items || []), item],
                            },
                        },
                    })
                }
            },
        })
        const deleteSub = draftApiClient.graphql({ query: onDeleteDraftEntrant, variables: { draft } }).subscribe({
            next: ({ data: { onDeleteDraftEntrant: item } }) => {
                const existingData = queryClient.getQueryData<typeof draftApiData>(queryKey)
                const items = existingData?.data.listDraftEntrants?.items
                const newItems = items?.filter((i) => i?.playerId !== item.playerId)
                queryClient.setQueryData(queryKey, {
                    data: {
                        listDraftEntrants: {
                            items: newItems,
                        },
                    },
                })
            },
        })

        const editSub = draftApiClient.graphql({ query: onUpdateDraftEntrant, variables: { draft } }).subscribe({
            next: ({ data: { onUpdateDraftEntrant: item } }) => {
                const existingData = queryClient.getQueryData<typeof draftApiData>(queryKey)
                const items = existingData?.data.listDraftEntrants?.items
                const newItems = items?.map((i) => (i?.playerId === item.playerId ? { ...i, ...item } : i))
                queryClient.setQueryData(queryKey, {
                    data: {
                        listDraftEntrants: {
                            items: newItems,
                        },
                    },
                })
            },
        })

        return () => {
            createSub.unsubscribe()
            deleteSub.unsubscribe()
            editSub.unsubscribe()
        }
    }, [subscribeToChanges, draft, draftApiClient, queryClient])

    if (isLoading) return undefined
    if (!draftApiData?.data.listDraftEntrants) return null
    return draftApiData.data.listDraftEntrants.items as DTO.DraftEntrant[]
}

export function useSubscribeToPickOrderChanges(
    draftYear: number,
    league: Enum.DraftLeague,
    subscribeToChanges = true
): DTO.ChangePickOrder[] | null | undefined {
    const draft = getLeagueDraftID(draftYear, league)
    const queryClient = useQueryClient()
    const draftApiClient = useDraftApiClient()

    // query the initial data on load
    const { data: draftApiData, isLoading } = useQuery({
        queryKey: getDraftApiTypeQueryKey(draft, 'changePickOrder'),
        queryFn: () =>
            draftApiClient.graphql({ query: listPickOrders, variables: { filter: { draft: { eq: draft } } } }),
    })

    // subscribe to the events
    useEffect(() => {
        if (!subscribeToChanges) return () => {}

        const queryKey = getDraftApiTypeQueryKey(draft, 'changePickOrder')
        const createSub = draftApiClient.graphql({ query: onCreatePickOrder, variables: { draft } }).subscribe({
            next: ({ data: { onCreatePickOrder: item } }) => {
                const existingData = queryClient.getQueryData<typeof draftApiData>(queryKey)
                const items = existingData?.data.listPickOrders?.items
                if (!items?.some((i) => i?.pick === item.pick)) {
                    queryClient.setQueryData(queryKey, {
                        data: {
                            listPickOrders: {
                                items: [...(items || []), item],
                            },
                        },
                    })
                }
            },
        })
        const editSub = draftApiClient.graphql({ query: onUpdatePickOrder, variables: { draft } }).subscribe({
            next: ({ data: { onUpdatePickOrder: item } }) => {
                const existingData = queryClient.getQueryData<typeof draftApiData>(queryKey)
                const items = existingData?.data.listPickOrders?.items
                const newItems = items?.map((i) => (i?.pick === item.pick ? item : i))
                queryClient.setQueryData(queryKey, {
                    data: {
                        listPickOrders: {
                            items: newItems,
                        },
                    },
                })
            },
        })

        return () => {
            createSub.unsubscribe()
            editSub.unsubscribe()
        }
    }, [subscribeToChanges, draft, draftApiClient, queryClient])

    if (isLoading) return undefined
    if (!draftApiData?.data.listPickOrders) return null
    return draftApiData.data.listPickOrders.items as DTO.ChangePickOrder[]
}

export function useSubscribeToDraftApiChanges(
    type: 'draftPlayer',
    draftYear: number,
    league: Enum.DraftLeague
): DTO.DraftPlayer | null | undefined
export function useSubscribeToDraftApiChanges(
    type: 'makePick',
    draftYear: number,
    league: Enum.DraftLeague
): DTO.MakePick | null | undefined
export function useSubscribeToDraftApiChanges(
    type: 'resetPick',
    draftYear: number,
    league: Enum.DraftLeague
): DTO.ResetPick | null | undefined
export function useSubscribeToDraftApiChanges(
    type: 'changePickOrder',
    draftYear: number,
    league: Enum.DraftLeague
): DTO.ChangePickOrder | null | undefined
export function useSubscribeToDraftApiChanges(
    type: 'makeSigning',
    draftYear: number,
    league: Enum.DraftLeague
): DTO.MakeSigning | null | undefined
export function useSubscribeToDraftApiChanges(
    type: 'createBanner',
    draftYear: number,
    league: Enum.DraftLeague
): DTO.CreateBanner | null | undefined
export function useSubscribeToDraftApiChanges(
    type: 'editEntrant',
    draftYear: number,
    league: Enum.DraftLeague
): DTO.DraftEntrant | null | undefined
export function useSubscribeToDraftApiChanges<T>(
    type: DraftNightEvents,
    draftYear: number,
    league: Enum.DraftLeague
): T | null | undefined {
    const draft = getLeagueDraftID(draftYear, league)
    const queryClient = useQueryClient()
    const draftApiClient = useDraftApiClient()

    // query the initial data on load
    const { data: draftApiData, isLoading } = useQuery({
        queryKey: getDraftApiTypeQueryKey(draft, type),
        queryFn: () => draftApiClient.graphql({ query: getDraftNightEvent, variables: { draft, name: type } }),
    })

    // subscribe to the events
    useEffect(() => {
        const queryKey = getDraftApiTypeQueryKey(draft, type)
        const createSub = draftApiClient
            .graphql({ query: onCreateDraftNightEvent, variables: { draft, name: type } })
            .subscribe({
                next: ({ data }) =>
                    queryClient.setQueryData<typeof draftApiData>(queryKey, {
                        data: { getDraftNightEvent: data.onCreateDraftNightEvent },
                    }),
            })
        const updateSub = draftApiClient
            .graphql({ query: onUpdateDraftNightEvent, variables: { draft, name: type } })
            .subscribe({
                next: ({ data }) =>
                    queryClient.setQueryData<typeof draftApiData>(queryKey, {
                        data: { getDraftNightEvent: data.onUpdateDraftNightEvent },
                    }),
            })
        const deleteSub = draftApiClient
            .graphql({ query: onDeleteDraftNightEvent, variables: { draft, name: type } })
            .subscribe({
                next: () => queryClient.setQueryData<typeof draftApiData | null>(queryKey, null),
            })

        return () => {
            createSub.unsubscribe()
            updateSub.unsubscribe()
            deleteSub.unsubscribe()
        }
    }, [draft, type, draftApiClient, queryClient])

    if (isLoading) return undefined
    if (!draftApiData?.data.getDraftNightEvent) return null
    return JSON.parse(draftApiData.data.getDraftNightEvent.value) as T
}

const cleanDraftEntrantModel = ({ lastName, ...rest }: DTO.DraftEntrant): DTO.DraftEntrant => ({
    ageOnDraftDay: asNumber(rest.ageOnDraftDay),
    available: rest.available,
    collegeClass: rest.collegeClass,
    contractType: rest.contractType,
    contractYears: asNumber(rest.contractYears),
    draftAndStashGLG: asNumber(rest.draftAndStashGLG),
    draftAndStashIntl: asNumber(rest.draftAndStashIntl),
    draftOptionNotes: rest.draftOptionNotes,
    fullName: rest.fullName,
    greenRoomInvite: rest.greenRoomInvite,
    pick: asNumber(rest.pick),
    playerId: rest.playerId,
    position: rest.position,
    rank: asNumber(rest.rank),
    salaryAmount: asNumber(rest.salaryAmount),
    signingTeamAbbr: rest.signingTeamAbbr,
    signingTeamId: rest.signingTeamId,
    signingTeamLogo: rest.signingTeamLogo,
    summerLeague: asNumber(rest.summerLeague),
    teamAbbr: rest.teamAbbr,
    teamId: rest.teamId,
    teamLogo: rest.teamLogo,
    teamName: rest.teamName,
    teamUrlSlug: rest.teamUrlSlug,
    twoWay: asNumber(rest.twoWay),
    urlSlug: rest.urlSlug,
})
export function useCreateDraftEntrant(
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.DraftEntrant> {
    const draft = getLeagueDraftID(draftYear, league)
    const draftApiClient = useDraftApiClient()

    return useMutation({
        mutationFn: (value: DTO.DraftEntrant) =>
            draftApiClient.graphql({
                query: createDraftEntrant,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                variables: { input: { ...cleanDraftEntrantModel(value), draft, fullName: value.fullName! } },
            }),
    })
}
export function useEditDraftEntrant(
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.DraftEntrant> {
    const draft = getLeagueDraftID(draftYear, league)
    const draftApiClient = useDraftApiClient()

    return useMutation({
        mutationFn: (value: DTO.DraftEntrant) =>
            draftApiClient.graphql({
                query: updateDraftEntrant,
                variables: { input: { ...cleanDraftEntrantModel(value), draft } },
            }),
    })
}

export function useResetDraftPick(
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.ResetPick> {
    const draft = getLeagueDraftID(draftYear, league)
    const draftApiClient = useDraftApiClient()

    return useMutation({
        mutationFn: (value: DTO.ResetPick) =>
            draftApiClient.graphql({
                query: updateDraftEntrant,
                variables: { input: { ...value, draft, pick: null } },
            }),
    })
}

export function useCreatePickOrder(
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.ChangePickOrder> {
    const draft = getLeagueDraftID(draftYear, league)
    const draftApiClient = useDraftApiClient()

    return useMutation({
        mutationFn: (value: DTO.ChangePickOrder) =>
            draftApiClient.graphql({
                query: createPickOrder,
                variables: { input: { ...value, draft } },
            }),
    })
}

export function useUpdatePickOrder(
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.ChangePickOrder> {
    const draft = getLeagueDraftID(draftYear, league)
    const draftApiClient = useDraftApiClient()

    return useMutation({
        mutationFn: (value: DTO.ChangePickOrder) =>
            draftApiClient.graphql({
                query: updatePickOrder,
                variables: { input: { ...value, draft } },
            }),
    })
}

export function useSubmitDraftApiEvent(
    type: 'draftPlayer',
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.DraftPlayer | null>
export function useSubmitDraftApiEvent(
    type: 'makePick',
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.MakePick | null>
export function useSubmitDraftApiEvent(
    type: 'resetPick',
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.ResetPick | null>
export function useSubmitDraftApiEvent(
    type: 'changePickOrder',
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.ChangePickOrder | null>
export function useSubmitDraftApiEvent(
    type: 'makeSigning',
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.MakeSigning | null>
export function useSubmitDraftApiEvent(
    type: 'createBanner',
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.CreateBanner | null>
export function useSubmitDraftApiEvent(
    type: 'editEntrant',
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, DTO.DraftEntrant | null>
export function useSubmitDraftApiEvent<T>(
    type: DraftNightEvents,
    draftYear: number,
    league: Enum.DraftLeague
): UseMutationResult<GraphQLResult, unknown, T | null> {
    const draft = getLeagueDraftID(draftYear, league)
    const draftApiClient = useDraftApiClient()
    return useMutation({
        mutationFn: async (value: T | null) => {
            if (value === null)
                return draftApiClient.graphql({
                    query: deleteDraftNightEvent,
                    variables: { input: { draft, name: type } },
                })

            const variables = { input: { draft, name: type, value: JSON.stringify(value) } }
            // the dynamo API PUT/POST has no way to determine if it already exists
            // try to update the value, if it fails, create it
            let resp: GraphQLResult
            try {
                resp = await draftApiClient.graphql({
                    query: updateDraftNightEvent,
                    variables,
                })
            } catch {
                resp = await draftApiClient.graphql({
                    query: createDraftNightEvent,
                    variables,
                })
            }
            return resp
        },
    })
}
