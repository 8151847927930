import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

export const useGetWeeklySkillLOCPlayers = (scoutEntityId?: string): UseQueryResult<DTO.BulkSkillLOCPlayers[]> =>
    useQuery({
        queryKey: ['bulk-skill-loc', 'players', scoutEntityId],
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- scoutEntityID will be defined
        queryFn: async () => (await get<DTO.BulkSkillLOCPlayers[]>(`/bulk-skill-loc/players/${scoutEntityId}`)).data,
        enabled: !!scoutEntityId,
    })

export const useGetSkillLOCProgress = (scoutEntityId?: string): UseQueryResult<DTO.BulkSkillLOCProgress[]> =>
    useQuery({
        queryKey: ['bulk-skill-loc', 'progress', scoutEntityId],
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- scoutEntityID will be defined
        queryFn: async () =>
            (await get<DTO.BulkSkillLOCProgress[]>(`/bulk-skill-loc/progress`, { scoutEntityId })).data,
    })
