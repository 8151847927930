import type { UseQueryResult, UseMutationResult, UseQueryOptions } from '@tanstack/react-query'
import type { RequestParams, JSONResponse } from '../api'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, post, remove } from '../api'
import { getDefaultLeagueProjectionType } from '../utils/standings'

type DraftAssetQueryParams = RequestParams & {
    projectionType?: Enum.ProjectionType
    season?: number
    league?: Enum.League
    teamId?: string
}

export const useDraftAssets = (league: Enum.League): UseQueryResult<DTO.DraftAssetMap> =>
    useQuery({
        queryKey: ['draft-assets', league],
        queryFn: async () => (await get<DTO.DraftAssetMap>('/draft/assets', { league })).data,
    })

export const useCreateDraftAsset = (): UseMutationResult<JSONResponse, Error, DTO.CreateDraftAsset> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (asset: DTO.CreateDraftAsset) => post<undefined, DTO.CreateDraftAsset>('/draft/assets', asset),

        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: ['draft-assets'],
            }),
    })
}

export const useDeleteDraftAsset = (): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (assetId: string) => remove(`/draft/assets/${assetId}`),

        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: ['draft-assets'],
            }),
    })
}

export const useTradeDraftAssets = (): UseMutationResult<JSONResponse, Error, DTO.TradeDraftAssets> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (assets: DTO.TradeDraftAssets) =>
            post<undefined, DTO.TradeDraftAssets>('/draft/assets/trade', assets),

        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: ['draft-assets'],
            }),
    })
}

export const useSaveDraftAssetConditions = (
    assetId: string
): UseMutationResult<JSONResponse, Error, DTO.SaveDraftAssetConditions> => {
    const queryClient = useQueryClient()
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return useMutation({
        mutationFn: (conditions: DTO.SaveDraftAssetConditions) =>
            post<undefined, DTO.SaveDraftAssetConditions>(`/draft/assets/${assetId}/conditions`, conditions),

        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['draft-assets'],
            })
            // void queryClient.invalidateQueries(['draft-odds'])
        },
    })
}

export type DraftQueryParams = RequestParams & {
    projectionType: Enum.ProjectionType
    draft: number
    league: Enum.League
    boardId?: string
    fromDate?: string
}

export const queryDraftOdds = async (params: DraftQueryParams): Promise<DTO.DraftOdds[]> =>
    (
        await get<DTO.DraftOdds[]>(`/draft/draft-odds/${params.league}/${params.draft}`, {
            projectionType: params.projectionType,
        })
    ).data

export const useDraftOdds = ({
    params,
    options,
}: {
    params: DraftQueryParams
    options?: Omit<UseQueryOptions<DTO.DraftOdds[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
}): UseQueryResult<DTO.DraftOdds[]> =>
    useQuery({
        queryKey: ['draft-odds', params],
        queryFn: () => queryDraftOdds(params),
        ...options,
    })

const getTeamPicks = async (
    draft: number,
    projectionType: Enum.ProjectionType,
    league: Enum.DraftLeague
): Promise<DTO.TeamPicks[]> =>
    (await get<DTO.TeamPicks[]>(`/draft/team-picks/${league}`, { draft, projectionType })).data

export const useGetTeamPicks = (
    draft: number | null,
    projectionType: Enum.ProjectionType | null,
    league: Enum.DraftLeague | null,
    options?: Omit<
        UseQueryOptions<DTO.TeamPicks[], Error, DTO.TeamPicks[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.TeamPicks[]> => {
    const projType = projectionType || getDefaultLeagueProjectionType(league, 'draft')
    return useQuery({
        queryKey: ['team-picks', league, draft, projType],
        queryFn: () => getTeamPicks(draft!, projType, league!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!league && !!draft && options?.enabled !== false,
    })
}

export const getPickValues = async (
    draft: number,
    projectionType: Enum.ProjectionType,
    league: Enum.DraftLeague
): Promise<DTO.PickValuation[]> =>
    (await get<DTO.PickValuation[]>(`/draft/pick-valuation/${league}`, { draft, projectionType })).data

export const useGetPickValues = (
    draft: number | null,
    projectionType: Enum.ProjectionType | null,
    league: Enum.DraftLeague | null,
    options?: Omit<
        UseQueryOptions<DTO.PickValuation[], Error, DTO.PickValuation[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PickValuation[]> =>
    useQuery({
        queryKey: ['pick-values', league, draft, projectionType],
        queryFn: () => getPickValues(draft!, projectionType!, league!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!projectionType && !!league && !!draft && options?.enabled !== false,
    })

export const getDraftNotes = async (
    type: string,
    draft: number,
    league: Enum.League,
    assetId: string | null
): Promise<DTO.DraftNote[]> => (await get<DTO.DraftNote[]>(`/draft/draft-notes`, { type, draft, league, assetId })).data

export const useGetDraftNotes = (
    type: string | null,
    draft: number | null,
    league: Enum.League | null,
    assetId: string | null,
    options?: Omit<
        UseQueryOptions<DTO.DraftNote[], Error, DTO.DraftNote[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.DraftNote[]> =>
    useQuery({
        queryKey: ['draft-note', type, draft, league],
        queryFn: () => getDraftNotes(type!, draft!, league!, assetId), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: !!type && !!draft && !!league && options?.enabled !== false,
    })

export const useUpdateDraftNote = (
    type: string,
    draft: number,
    league: Enum.League
): UseMutationResult<JSONResponse, Error, DTO.SaveDraftNote> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (note: DTO.SaveDraftNote) => post<undefined, DTO.SaveDraftNote>('/draft/draft-notes', note),

        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['draft-note', type, draft, league],
            })
            await queryClient.invalidateQueries({
                queryKey: ['team-draft-assets'],
            })
        },
    })
}

const queryTeamDraftPicks = async (params: DraftAssetQueryParams): Promise<DTO.TeamDraftAsset[]> =>
    (await get<DTO.TeamDraftAsset[]>(`/draft/team-draft-assets`, params)).data

export const useTeamDraftPicks = (
    params: DraftAssetQueryParams,
    options?: Omit<
        UseQueryOptions<DTO.TeamDraftAsset[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.TeamDraftAsset[]> =>
    useQuery({
        queryKey: ['team-draft-assets', params],
        queryFn: () => queryTeamDraftPicks(params),
        ...options,
        enabled: !!params.teamId && options?.enabled !== false,
    })

const queryGLGReturningRightsPlayers = async (teamId: string): Promise<DTO.ReturningRightsPlayer[]> =>
    (await get<DTO.ReturningRightsPlayer[]>(`/draft/glg-returning-rights-players`, { teamId })).data

export const useGLGReturningRightsPlayers = (
    teamId: string,
    options?: Omit<
        UseQueryOptions<DTO.ReturningRightsPlayer[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.ReturningRightsPlayer[]> =>
    useQuery({
        queryKey: ['glg-returning-rights-players', teamId],
        queryFn: () => queryGLGReturningRightsPlayers(teamId),
        ...options,
    })

export const useGetTradeablePicks = (
    draft: number,
    league: Enum.DraftLeague,
    projectionType?: Enum.ProjectionType,
    options?: Omit<UseQueryOptions<DTO.TradeablePicks[], Error, DTO.TradeablePicks[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.TradeablePicks[]> =>
    useQuery({
        queryKey: ['tradeable-picks', draft, projectionType],
        queryFn: async () =>
            (await get<DTO.TradeablePicks[]>(`/draft/tradeable-picks/${draft}`, { projectionType })).data,
        ...options,
        enabled: league === 'NBA' && options?.enabled !== false,
    })
