'use client'

import type { Dispatch, SetStateAction } from 'react'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { useTeamGames } from './useTeamStats'
import { shotTypes } from './useShootingParams'
import { useConstantsContext } from '@/lib/contexts/ConstantsContext'
import { URLSearchParamsToObject } from '@/lib/routing/searchParams'

type UseTeamStatsParamsReturn = {
    defaultParams: DTO.TeamStatsFilterParams
    filterParams: DTO.TeamStatsFilterParams
    setFilterParams: Dispatch<SetStateAction<DTO.TeamStatsFilterParams>>
    statsYear: number
    teamGames: DTO.TeamGames[] | undefined
    showNumGames: boolean
    printOnly: boolean
    pageNumber: number
    totalPages: number
}

export const getInitFilterParams = (
    defaultParams: DTO.TeamStatsFilterParams,
    queryParams: Partial<DTO.TeamStatsFilterParams>
): DTO.TeamStatsFilterParams => ({
    season: queryParams.season || defaultParams.season,
    league: queryParams.league || defaultParams.league,
    timeFrame: queryParams.timeFrame || defaultParams.timeFrame,
    startDate: queryParams.startDate || defaultParams.startDate,
    endDate: queryParams.endDate || defaultParams.endDate,
    gameIds: (queryParams.gameIds ? JSON.parse(queryParams.gameIds as string) : defaultParams.gameIds) as string[],
    playersToExclude: (queryParams.playersToExclude
        ? JSON.parse(queryParams.playersToExclude as string)
        : defaultParams.playersToExclude) as string[],
    opponentTeamId: queryParams.opponentTeamId || defaultParams.opponentTeamId,
    shotTypes: (queryParams.shotTypes
        ? JSON.parse(queryParams.shotTypes as string)
        : defaultParams.shotTypes) as Enum.ShotType[],
})

export default (teamId: string | null | undefined, league: Enum.League | undefined): UseTeamStatsParamsReturn => {
    const { statsYear } = useConstantsContext()
    const searchParams = useSearchParams()
    const queryParams = URLSearchParamsToObject(searchParams)
    const defaultParams = useMemo(
        () => ({
            season: statsYear.toString(),
            league,
            timeFrame: (league === 'G-League' ? 'SEASON' : 'REGULAR') as DTO.TimeFrame,
            startDate: undefined,
            endDate: undefined,
            gameIds: [],
            playersToExclude: [],
            opponentTeamId: null,
            shotTypes: shotTypes.map((s) => s.value),
        }),
        [statsYear, league]
    )

    const [filterParams, setFilterParams] = useState<DTO.TeamStatsFilterParams>(() =>
        getInitFilterParams(defaultParams, queryParams)
    )

    // reset the filter if the defaults change
    const [prevDefaultParams, setPrevDefaultParams] = useState(defaultParams)
    if (prevDefaultParams !== defaultParams) {
        setPrevDefaultParams(defaultParams)
        setFilterParams(getInitFilterParams(defaultParams, queryParams))
    }

    const { data: teamGames } = useTeamGames(teamId, filterParams)
    const showNumGames =
        filterParams.timeFrame === 'DATE_RANGE' ||
        filterParams.timeFrame === 'LAST_5' ||
        filterParams.timeFrame === 'LAST_10' ||
        filterParams.timeFrame === 'LAST_20' ||
        filterParams.timeFrame === 'OPPONENT' ||
        !!filterParams.playersToExclude?.length

    return {
        defaultParams,
        filterParams,
        setFilterParams,
        statsYear,
        teamGames,
        showNumGames,
        pageNumber: Number(queryParams.pageNum) || 1,
        totalPages: Number(queryParams.totalPages) || 1,
        printOnly: !!searchParams.get('printOnly'),
    }
}
