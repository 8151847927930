import type { UseSuspenseInfiniteQueryResult, UseSuspenseQueryResult } from '@tanstack/react-query'
import type { InfiniteQueryPaginatedResp, PaginatedResp } from './usePosts'
import { useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query'
import { get } from '../api'

type PaginatedShotsResp = PaginatedResp<DTO.GameShot>

const queryShots = async (params: DTO.ShotFilters): Promise<PaginatedShotsResp> =>
    (await get<PaginatedShotsResp>(`/shots`, params)).data

export const useSuspenseShots = (
    filters: DTO.ShotFilters
): UseSuspenseInfiniteQueryResult<InfiniteQueryPaginatedResp<DTO.GameShot>> =>
    useSuspenseInfiniteQuery({
        queryKey: ['shots', filters],
        queryFn: ({ pageParam = 1 }) => queryShots({ ...filters, page: pageParam }),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => (lastPage.info.hasNextPage ? Number(lastPage.info.nextPage) : null),
    })

export const queryShotDetails = async (
    shotId: string | undefined,
    level: Enum.ShotLevel
): Promise<DTO.GameShotDetails | null> => {
    if (!shotId) return null
    return (await get<DTO.GameShotDetails>(`/shots/${shotId}`, { level })).data
}

export const useSuspenseShotDetails = ({
    shotId,
    level,
}: {
    shotId: string | undefined
    level: Enum.ShotLevel
}): UseSuspenseQueryResult<DTO.GameShotDetails | null> =>
    useSuspenseQuery({
        queryKey: ['shotDetails', shotId, level],
        queryFn: () => queryShotDetails(shotId, level),
    })
