import type { UseQueryResult } from '@tanstack/react-query'
import type { RequestParams } from '../api'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet } from '../api'

export type AgencyAgentsParams = RequestParams & {
    slug: string
    canViewContractGrades: boolean
}

export const serverGetAgencyBySlug = async (slug: string): Promise<DTO.Agency> =>
    (await serverGet<DTO.Agency>(`/agencies/${slug}`, { apiCacheKey: '/agencies/:slug', cacheTag: `agencies-${slug}` }))
        .data

export const getAgencyBySlug = async (slug: string): Promise<DTO.Agency> =>
    (await get<DTO.Agency>(`/agencies/${slug}`)).data

export const useGetAgencyBySlug = (slug: string): UseQueryResult<DTO.Agency> =>
    useQuery({
        queryKey: ['agencies', slug],
        queryFn: () => getAgencyBySlug(slug),
    })

export const getAgencyAgentsBySlug = async (params: AgencyAgentsParams): Promise<DTO.AgencySummaryAgent[]> =>
    (await get<DTO.AgencySummaryAgent[]>(`/agencies/${params.slug}/summary`, params)).data

export const useGetAgencyAgentsBySlug = (params: AgencyAgentsParams): UseQueryResult<DTO.AgencySummaryAgent[]> =>
    useQuery({
        queryKey: ['agencies-summary', params.slug],
        queryFn: () => getAgencyAgentsBySlug(params),
    })
