import type { UseQueryResult } from '@tanstack/react-query'
import type { RequestParams } from '../api'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const apiPath = '/player-lists'

export type PlayerListQueryParams = RequestParams & {
    level?: Enum.ReportLevel
}

export const queryPlayerLists = async (params?: PlayerListQueryParams): Promise<DTO.PlayerList[]> =>
    (await get<DTO.PlayerList[]>(apiPath, params)).data

const usePlayerLists = ({ params }: { params?: PlayerListQueryParams }): UseQueryResult<DTO.PlayerList[]> =>
    useQuery({
        queryKey: ['player-lists', params],
        queryFn: () => queryPlayerLists(params)
    })

export default usePlayerLists
