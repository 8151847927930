import type { JSONResponse } from '../api'
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, post } from '../api'

const queryOrgPlayers = async (): Promise<DTO.OrgPlayers> => (await get<DTO.OrgPlayers>(`/org-players`)).data

const useOrgPlayers = (): UseQueryResult<DTO.OrgPlayers> =>
    useQuery({
        queryKey: ['org-players'],
        queryFn: queryOrgPlayers,
    })

export const useTeamOrgPlayers = (
    levels?: Enum.OrgPlayerLevels[],
    active?: boolean
): UseQueryResult<DTO.TeamOrgPlayer[]> =>
    useQuery({
        queryKey: ['org-players', levels, active],
        queryFn: async () => (await get<DTO.TeamOrgPlayer[]>('/org-players/team-org-players', { levels, active })).data,
    })

export const useSaveOrgPlayer = (): UseMutationResult<JSONResponse<DTO.OrgPlayers>, Error, DTO.TeamOrgPlayer> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (player: DTO.TeamOrgPlayer) => post<DTO.OrgPlayers, DTO.TeamOrgPlayer>('/org-players', player),

        onSuccess: (resp) => {
            queryClient.setQueryData(['org-players'], resp.data)
        },
    })
}

export default useOrgPlayers
