import type { JSONResponse, RequestParams } from '../api'
import type { UseMutationResult, UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { get, post } from '../api'

export type IntensityGradeParams = RequestParams & {
    playerId: string
    categorySummaries?: string
    overallSummary?: string
    medicalOnly?: string
}
const queryIntensityGrades = async (params: IntensityGradeParams): Promise<DTO.IntensityGrade | ''> =>
    (await get<DTO.IntensityGrade | ''>(`/profile/${params.playerId}/intensity`, params)).data

const useIntensityGrade = (
    params: IntensityGradeParams,
    options?: Omit<
        UseQueryOptions<DTO.IntensityGrade | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.IntensityGrade | ''> =>
    useQuery({
        queryKey: ['intensity', params.playerId, params.categorySummaries, params.categorySummaries, params.medicalOnly],
        queryFn: () => queryIntensityGrades(params),
        enabled: !!params.playerId && options?.enabled !== false
    })

export const useCreateIntensityGrade = (
    playerId: string | undefined
): UseMutationResult<JSONResponse, Error, DTO.CreateIntensityGrade | ''> => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (grade: DTO.CreateIntensityGrade | '') =>
            post<undefined, DTO.CreateIntensityGrade | ''>('/profile/intensity/create', grade),

        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['intensity', playerId]
            })
            await queryClient.invalidateQueries({
                queryKey: ['player-summary', playerId]
            })
        }
    });
}

export default useIntensityGrade
