import type { Session } from 'next-auth'
import type { ParsedUrlQueryInput } from 'querystring'
import type { JsonValue, RequireAllOrNone } from 'type-fest'
import { sign as signJwt } from 'jsonwebtoken'
import { api as apiCache } from '../shared/constants/cache'
import { mapUserRolesTokenToUserRoles } from '../shared/utils/auth'
import { azureADSignoutUrl, riseSignoutUri, signOut } from '../components/wrappers/Auth'
import { objectToURLSearchParams } from './routing/searchParams'
import { isNumber } from './utils/math'

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'
export type RequestParams =
    | ({ [Key in string]: JsonValue | undefined } & { [Key in string]?: JsonValue | undefined })
    | URLSearchParams

export type JSONResponse<T = unknown> = {
    ok: boolean
    redirected: boolean
    status: number
    statusText: string
    type: ResponseType
    url: string
    headers: Map<string, string>
    data: T
}
interface ErrorResponse {
    error: string
}
interface ValidationErrorResponse {
    errors: { msg: string }[]
}

const apiTarget = process.env.NEXT_PUBLIC_API_TARGET

const fetchWrapper = (url: string, options: RequestInit, timeout = 30000): Promise<Response> =>
    new Promise((resolve, reject) => {
        fetch(url, options).then(resolve).catch(reject)

        if (timeout) {
            setTimeout(reject, timeout)
        }
    })

type RequestParamPrimitive = string | number | boolean | string[] | number[] | boolean[]

function requestParamAsPrimitive(param: unknown): RequestParamPrimitive | null {
    let value: RequestParamPrimitive | null = null

    if (Array.isArray(param))
        value = param.map((i) => requestParamAsPrimitive(i)).filter((x) => x !== null) as
            | string[]
            | number[]
            | boolean[]
    else if (isNumber(param)) value = Number(param)
    else if (typeof param === 'string') value = param.trim()
    else if (typeof param === 'boolean') value = param
    else if (typeof param === 'object' && param) value = JSON.stringify(param)

    return value
}

const makeRequest = async <T = undefined, D = undefined>(
    url: string,
    method: Method,
    params: RequestParams = {},
    data: D | undefined = undefined,
    headerAdditions: Record<string, string | undefined> = {},
    baseURL = '/api',
    noHeaders = false,
    fetchOptions: RequestInit = {}
): Promise<JSONResponse<T>> => {
    const trimmedParams = Object.entries(params).reduce<ParsedUrlQueryInput>((acc, [key, value]) => {
        const paramValue = requestParamAsPrimitive(value)
        if (paramValue !== null) {
            if (Array.isArray(value)) acc[`${key}[]`] = paramValue
            else acc[key] = paramValue
        }
        return acc
    }, {})

    const queryString = objectToURLSearchParams(trimmedParams)
    const fetchUrl = `${baseURL}${url}${queryString ? `?${queryString}` : ''}`
    const requestOptions: RequestInit = {
        method,
        headers: {
            ...headerAdditions,
        } as RequestInit['headers'],
        ...fetchOptions,
    }
    if (data instanceof FormData) {
        requestOptions.body = data
    } else if (!noHeaders) {
        ;(requestOptions.headers as Record<string, string>)['Content-Type'] = 'application/json'
        if (data) requestOptions.body = JSON.stringify(data)
    }
    const response = await fetchWrapper(fetchUrl, requestOptions)
    if (!response.ok) {
        if (
            response.status === 403 &&
            window.location.href !== riseSignoutUri &&
            window.location.href !== azureADSignoutUrl
        ) {
            signOut()
        }

        if (response.status === 409) throw new Error('A new version of the data is available. Please refresh the page.')

        let errorMessage = `Fetch error: ${response.status} ${response.statusText}`
        try {
            const resp = (await response.json()) as ErrorResponse | ValidationErrorResponse | unknown
            if (!!resp && typeof resp === 'object') {
                if ('errors' in resp)
                    errorMessage = (resp as ValidationErrorResponse).errors.map((e) => e.msg).join(', ')
                else if ('error' in resp) errorMessage = (resp as ErrorResponse).error
            }
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            throw new Error(errorMessage)
        }
    }

    let d = undefined as T
    if (response.status === 204) {
        d = '' as T
    } else if (response.headers.get('content-type')?.includes('application/json')) {
        try {
            d = (await response.json()) as T
        } catch {
            // swallow error on empty response
        }
    } else {
        d = (await response.text()) as T
    }

    return {
        ok: response.ok,
        redirected: response.redirected,
        status: response.status,
        statusText: response.statusText,
        type: response.type,
        url: response.url,
        headers: new Map(response.headers),
        data: d,
    }
}

const get = <T>(
    path: string,
    params?: RequestParams,
    headers?: Record<string, string | undefined>,
    baseUrl?: string,
    noHeaders?: boolean
): Promise<JSONResponse<T>> => makeRequest<T>(path, 'GET', params, undefined, headers, baseUrl, noHeaders)

type NextServerGetOptions = RequireAllOrNone<
    {
        apiCacheKey?: keyof typeof apiCache
        cacheTag?: string
        session?: Session | null
        params?: RequestParams
        headers?: Record<string, string | undefined>
        contentPermissions?: Enum.ContentPermissionType[]
        featurePermissions?: Enum.FeaturePermissionType[]
    },
    'apiCacheKey' | 'cacheTag'
>
const serverGet = <T>(
    path: string,
    {
        session,
        params,
        headers,
        apiCacheKey,
        cacheTag,
        contentPermissions = [],
        featurePermissions = [],
    }: NextServerGetOptions
): Promise<JSONResponse<T>> => {
    let queryParams = params
    let jwtRoles: Auth.UserRoles
    if (session && !apiCacheKey) {
        // query the API from the server as the logged in user if the response is not cached
        jwtRoles = session.roles
    } else {
        // otherwise, query the API as a the server with a specified set of permissions
        let serverReqContentPermissions = [...contentPermissions]
        let serverReqFeaturePermissions = [...featurePermissions]
        if (session) {
            serverReqContentPermissions = serverReqContentPermissions.filter((c) => session.roles.contentPermissions[c])
            serverReqFeaturePermissions = serverReqFeaturePermissions.filter((f) => session.roles.featurePermissions[f])
        }
        // use the requested permissions in the query parameters so that the cache is consistent for users with the same permissions
        // this will prevent users without certain permissions from getting cached values with the data they are unauthorized to view
        if (serverReqContentPermissions.length) queryParams = { ...queryParams, content: serverReqContentPermissions }
        if (serverReqFeaturePermissions.length) queryParams = { ...queryParams, feature: serverReqFeaturePermissions }

        jwtRoles = mapUserRolesTokenToUserRoles({
            contentPermissions: serverReqContentPermissions,
            featurePermissions: serverReqFeaturePermissions,
        })
    }

    const buildApiToken = signJwt(
        { roles: jwtRoles, name: 'system', userId: session?.userId || 0, entityId: session?.entityId },
        process.env.JWT_SECRET
    )

    let fetchOptions: RequestInit | undefined
    let buildHeaders: Record<string, string | undefined> = { ...headers, Authorization: `Bearer ${buildApiToken}` }
    if (apiCacheKey) {
        buildHeaders = { ...buildHeaders, cache: 'force-cache' }
        fetchOptions = { next: { revalidate: apiCache[apiCacheKey], tags: [cacheTag] } }
    }

    return makeRequest<T>(path, 'GET', queryParams, undefined, buildHeaders, apiTarget, undefined, fetchOptions)
}

const post = <T, D>(
    path: string,
    body: D,
    params?: RequestParams,
    headers?: Record<string, string | undefined>
): Promise<JSONResponse<T>> => makeRequest<T, D>(path, 'POST', params, body, headers)

const put = <T, D>(
    path: string,
    body: D,
    params?: RequestParams,
    headers?: Record<string, string | undefined>
): Promise<JSONResponse<T>> => makeRequest<T, D>(path, 'PUT', params, body, headers)

const remove = (
    path: string,
    params?: RequestParams,
    headers?: Record<string, string | undefined>
): Promise<JSONResponse<undefined>> => makeRequest(path, 'DELETE', params, undefined, headers)

export { makeRequest, get, post, put, remove, serverGet }
