'use client'

import type { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'
import createCtx from './createCtx'

const getConstants = async (): Promise<DTO.Constants> => (await get<DTO.Constants>(`/constants`)).data
const useGetConstants = (): UseQueryResult<DTO.Constants> =>
    useQuery({ queryKey: ['constants'], queryFn: () => getConstants(), gcTime: Infinity, staleTime: Infinity })

const [useConstantsContext, ConstantsContext] = createCtx<DTO.Constants>()

const ConstantsContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element | null => {
    const { data: constants, isLoading } = useGetConstants()
    if (isLoading || !constants) return null

    return <ConstantsContext value={constants}>{children}</ConstantsContext>
}

export { useConstantsContext, ConstantsContext, ConstantsContextProvider }
